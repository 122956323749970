import React, { useEffect } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { closeMessage } from '../../logic/actions/notification';

const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const Notification = () => {
    const [state] = [{
        vertical: 'top',
        horizontal: 'center',
    }];
    const { vertical, horizontal } = state;

    const dispatch = useDispatch();
    const { successMessage, warningMessage, errorMessage } = useSelector((state) => state.Notification);

    const handleClose = () => {
        dispatch(closeMessage());
    };

    useEffect(() => {
        if (successMessage || warningMessage || errorMessage) {
            setTimeout(() => dispatch(closeMessage()), 5000);
        }
    }, [successMessage, warningMessage, errorMessage, dispatch]);

    return (
        <>
            {successMessage && (
                <Snackbar open anchorOrigin={{ vertical, horizontal }} sx={{ maxWidth: 600 }} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            )}
            {warningMessage && (
                <Snackbar open anchorOrigin={{ vertical, horizontal }} sx={{ maxWidth: 600 }} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                        {warningMessage}
                    </Alert>
                </Snackbar>
            )}
            {errorMessage && (
                <Snackbar open anchorOrigin={{ vertical, horizontal }} sx={{ maxWidth: 600 }} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default Notification;
