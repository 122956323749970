/* eslint-disable no-restricted-syntax */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Table,
  Stack,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  InputAdornment,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { debounce } from 'lodash';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import CustomModal from '../../components/modal';
import TablePulse from '../../components/tablePulse';
import { errorMessage } from '../../logic/actions/notification';
import { getSettlements } from '../../logic/actions/admin';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    borderRadius: '0px 6px 6px 0px',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'bankAccountName', label: ' Bank Account Name', alignRight: false },
  { id: 'bankAccount', label: 'Bank Account', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'debitLedger', label: 'Debit Ledger', alignRight: false },
  { id: 'creditLedger', label: 'Credit Ledger', alignRight: false },
  // { id: 'purpose', label: 'Purpose', alignRight: false },

  { id: 'dateCreated', label: 'Date Created', alignRight: false },
];

export default function Index() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState();
  const [openAddModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  const { stateLoading, statePayload } = useSelector((state) => state.adminReducer);
  const [value, setValue] = useState(''); // Track the selected values

  const fieldNames = [
    'amount',
    'bankCode',
    'bankAccountName',
    'bankAccount',
    'debitLedger',
    'creditLedger',
    'purpose',
    'dateCreated',
    'paymentReference',
  ];

  const formatLabel = (label) => {
    // Capitalize the first letter
    label = label.charAt(0).toUpperCase() + label.slice(1);

    // Insert a space before each capital letter
    label = label.replace(/([A-Z])/g, ' $1');

    return label;
  };

  // Create an array of objects with 'value' and 'label'
  const fieldObjects = fieldNames.map((fieldName) => ({
    value: fieldName,
    label: formatLabel(fieldName), // Capitalize the first letter
  }));

  const formatDate = (date) => {
    const originalDate = new Date(date);
    return originalDate.toLocaleDateString('en-GB');
  };

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        bankAccountName: item?.bankAccountName || 'N/A',
        bankAccount: item?.bankAccount || 'N/A',
        dateCreated: formatDate(item?.dateCreated),
        purpose: item?.purpose,
        creditLedger: item?.creditLedger,
        amount: item?.amount,
        debitLedger: item?.debitLedger,
      })),
    [rows]
  );

  const fetchSettlement = useCallback(() => {
    dispatch(getSettlements(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 403) {
          dispatch(errorMessage('You do not have permission to perform this action.'));
        } else {
          dispatch(errorMessage(error?.response.data.responseMessage));
        }
      });
  }, [dispatch, page, rowsPerPage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(async ({ value, filterName }) => {
      try {
        // If value is an empty string, set it to null or remove it from the payload
        const payload = value !== '' ? { [value]: filterName } : { filterName };

        const responseData = await dispatch(getSettlements(page, rowsPerPage, payload));
        const data = responseData?.value.data;
        setRows(data);
      } catch (error) {
        console.error(error);
      }
    }, 1000),
    [dispatch, rowsPerPage]
  );

  useEffect(() => {
    if (filterName) {
      debounceSearch({ value, filterName });
    } else {
      fetchSettlement();
    }
  }, [debounceSearch, fetchSettlement, filterName, value]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const options = fieldObjects || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set the formatted value
    setValue(value);
  };

  return (
    <>
      <Helmet>
        <title> Musha | Settlement </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Settlements</Typography>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="start" gap="2" my={3} ml={3}>
            <FormControl sx={{ width: 230 }}>
              <Select
                displayEmpty
                sx={{
                  borderRadius: '6px 0px 0px 6px',
                }}
                value={value}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Select an option</span>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options.map((name, index) => (
                  <MenuItem key={index} value={name.value}>
                    {name.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder={'Search transactions...'}
              disabled={!value}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={format()?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {stateLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={6} />
                  </TableBody>
                ) : rows?.length > 0 ? (
                  <TableBody>
                    {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell align="left">{row.bankAccountName}</TableCell>
                          <TableCell align="left">{row.bankAccount}</TableCell>

                          <TableCell align="left">{row.amount}</TableCell>
                          <TableCell align="left">{row.debitLedger}</TableCell>
                          <TableCell align="left">{row.creditLedger}</TableCell>
                          {/* <TableCell align="left">{row.purpose}</TableCell> */}
                          <TableCell align="left">{row.dateCreated}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {statePayload?.totalCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={statePayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Rows:'}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <CustomModal
        isOpen={openAddModal}
        title="Register Admin"
        content={<></>}
        contentText={''}
        onClose={() => {
          setAddModal(false);
        }}
        creating={false}
        disableCreate={false}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={'Register Admin'}
        onCreate={() => {}}
        createBtnColor=""
      />
    </>
  );
}
