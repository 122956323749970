/* eslint-disable react/prop-types */
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
// import { debounce } from 'lodash';
import CustomModal from '../../components/modal';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
import { addNewMoratorium, deleteMoratorium, getMoratorium } from '../../logic/actions/asset';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { UserListHead } from '../../sections/@dashboard/user';
import AddMoratorium from './addMoratorium';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: '' },
];

const initialFormValues = {
  moratoriumName: '',
};

const Moratorium = ({ openmodal, setNewModal }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  // const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [formValues, setFormValues] = useState({ payload: { ...initialFormValues } });

  const { moratoriumPayload, moratoriumLoading, deleteMoratoriumLoading, addMoratoriumLoading } = useSelector(
    (state) => state.assetReducer
  );

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
        name: item?.name,
      })),
    [rows]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  // const handleFilterByName = (event) => {
  //   setPage(0);
  //   setFilterName(event.target.value);
  // };

  const getMoratoriumList = useCallback(() => {
    dispatch(getMoratorium())
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [dispatch]);

  useEffect(() => {
    getMoratoriumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
    }
  };

  const handleOpen = (id) => {
    setRemoveModalOpen(true);
    setSelectedId(id);
  };

  const removePropertyType = () => {
    dispatch(deleteMoratorium(selectedId))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Moratorium removed successfully'));
          setRemoveModalOpen(false);
          getMoratoriumList();
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response.data.responseMessage));
      });
  };

  const createNewMoratorium = () => {
    const name = formValues?.payload?.moratoriumName;
    let errorStatus = false;
    if (!name) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(addNewMoratorium(name))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Moratorium created successfully'));
            setNewModal(false);
            getMoratoriumList();
            setFormValues({ payload: { ...initialFormValues }, submitted: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
            setNewModal(false);
          } else {
            dispatch(errorMessage(error?.response.data.responseMessage));
          }
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Payouts | Asset</title>
      </Helmet>
      <Card>
        {/* <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder={'Search...'}
        /> */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table aria-labelledby="tableTitle" size={'medium'}>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={headCells}
                rowCount={format()?.length}
                numSelected={selected?.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <></>
              {moratoriumLoading ? (
                <TableBody>
                  <TablePulse arrayLength={10} rows={3} />
                </TableBody>
              ) : moratoriumPayload?.data.length > 0 ? (
                <TableBody>
                  {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.dateCreated}</TableCell>

                        <TableCell align="right">
                          <Typography
                            onClick={() => handleOpen(row.id)}
                            sx={{ fontSize: '13px', fontWeight: 'medium', color: 'red', cursor: 'pointer' }}
                          >
                            Remove
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <div>No record found in logs</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {moratoriumPayload?.totalCount ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Scrollbar>
      </Card>

      <CustomModal
        isOpen={isRemoveModalOpen}
        title="Remove Moratorium"
        // content={''}
        contentText={'Are you sure you want to Remove this Moratorium?'}
        onClose={() => {
          setRemoveModalOpen(false);
        }}
        creating={deleteMoratoriumLoading}
        disableCreate={deleteMoratoriumLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removePropertyType();
        }}
        createBtnColor="error"
      />

      <CustomModal
        isOpen={openmodal}
        title="Add New Moratorium"
        contentText=""
        content={
          <AddMoratorium
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            isClose={openmodal}
          />
        }
        onClose={() => {
          setNewModal(false);
          setFormValues({ payload: { ...initialFormValues }, submitted: false });
        }}
        creating={addMoratoriumLoading}
        disableCreate={addMoratoriumLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Create'}
        onCreate={() => {
          createNewMoratorium();
        }}
      />
    </>
  );
};

export default Moratorium;
