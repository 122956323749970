import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Container,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { getAdminPermissions } from '../../../logic/actions/admin';
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import TablePulse from '../../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

export default function Index() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  const {
    getAdminPermissionsLoading,
    // getAdminPermissionsPayload,
  } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    dispatch(getAdminPermissions())
      .then((res) => {
        setRows(res?.value?.data.permissions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  const format = useCallback(() => {
    const filteredData = rows.filter((item) => (item.name?.toLowerCase() || '').includes(filterName.toLowerCase()));

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = filteredData.slice(startIndex, endIndex);

    return slicedData.map((item) => ({
      name: item.name,
      id: item.id,
      deleted: item.isDeleted ? 'Yes' : 'No',
    }));
  }, [page, rows, rowsPerPage, filterName]);

  useEffect(() => {
    const filteredData = format().filter((item) => (item.name?.toLowerCase() || '').includes(filterName.toLowerCase()));
    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Musha | Permissions </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Permissions
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search Permissions...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={rows.length} // Update this line
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {getAdminPermissionsLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={2} />
                  </TableBody>
                ) : filteredRows.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell align="left">{row.deleted}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      {filteredRows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}
