import React, { useState, useEffect, useCallback } from 'react';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import {
  Card,
  Container,
  Stack,
  Typography,
  IconButton,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import useAuthToken from '../../../hooks/useAuthToken';
import { getUsersFromRoles, removeUserFromRole } from '../../../logic/actions/admin';
import CustomModal from '../../../components/modal';
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import { errorMessage, successMessage } from '../../../logic/actions/notification';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const ViewUserInRole = () => {
  const { id } = useParams();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const { token } = useAuthToken();
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState('');
  const [isRemoveUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [userId, setUserId] = useState('');

  const { getUserFromRoleLoading, getUserFromRolePayload, removeUserFromRoleLoading } = useSelector(
    (state) => state.adminReducer
  );

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        name: `${item.firstname || ''} ${item.lastname || ''}`.trim() || item.companyName,
        id: item?.id,
        email: item?.email,
        verified: item?.isPhoneNumberConfirmed && item?.isEmailConfirmed ? 'Yes' : 'No',
        phoneNumber: item?.phone,
        status: item?.accountStatus,
      })),
    [rows]
  );

  const removeUserFromRoleId = () => {
    const payload = {
      roleId: id,
      userId,
    };
    dispatch(removeUserFromRole(payload, token))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('User removed successfully'));
          setRemoveUserModalOpen(false);
          fetchData();
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response.data.responseMessage));
      });
  };

  useEffect(() => {
    const filteredData = format()?.filter(
      (item) =>
        (item.name?.toLowerCase() || '').includes(filterName.toLowerCase()) ||
        (item.email?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  const fetchData = useCallback(() => {
    const payload = {
      roleId: id,
      pendingRequest: true,
    };
    dispatch(getUsersFromRoles(page, rowsPerPage, payload, token))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
      })
      .catch((error) => console.error(error));
  }, [dispatch, id, page, rowsPerPage, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex > -1) {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleOpenModal = (userId) => {
    setUserId(userId);
    console.log(userId);
    setRemoveUserModalOpen(true);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <div>
      <Helmet>
        <title>Users in Role</title>
      </Helmet>

      <Container>
        <Stack>
          <Typography variant="h4" gutterBottom>
            Users in Role List
          </Typography>
          <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Link
              color="primary"
              href="/user-role"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none' }}
            >
              <IconButton size="large" color="inherit">
                <Iconify icon={'mingcute:back-fill'} />
              </IconButton>
              Go Back
            </Link>
          </Stack>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search Roles...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <></>
                {getUserFromRoleLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            <CircularProgress color="inherit" />
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : filteredRows?.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <img src={'/public/assets/icons/avatar.svg'} alt="" />
                              <Typography variant="subtitle2" noWrap>
                                {row.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{row.email}</TableCell>

                          <TableCell align="left">{row.phoneNumber}</TableCell>

                          <TableCell align="left">{row.verified}</TableCell>

                          <TableCell align="left">{row.status}</TableCell>

                          <TableCell align="right">
                            <Typography
                              onClick={() => handleOpenModal(row.id)}
                              sx={{
                                fontSize: '13px',
                                fontWeight: 'medium',
                                color: 'error.main',
                                cursor: 'pointer',
                              }}
                            >
                              Remove User
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {getUserFromRolePayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getUserFromRolePayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>
      <CustomModal
        isOpen={isRemoveUserModalOpen}
        title="Remove User"
        content={
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to Remove this User?
          </Typography>
        }
        contentText={''}
        onClose={() => {
          setRemoveUserModalOpen(false);
        }}
        creating={removeUserFromRoleLoading}
        disableCreate={removeUserFromRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removeUserFromRoleId();
        }}
        createBtnColor="error"
      />
    </div>
  );
};

export default ViewUserInRole;