import actionTypes from '../actions/types';
import { REQUEST, FAILURE, SUCCESS } from './action-type.util';

const INTIAL_STATE = {};

const dashboardReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.LOGIN):
      return {
        ...state,
        loginLoading: true,
        loginError: null,
        loginPayload: null,
      };
    case SUCCESS(actionTypes.LOGIN):
      return {
        ...state,
        loginLoading: false,
        loginError: null,
        loginPayload: actions.payload,
      };
    case FAILURE(actionTypes.LOGIN):
      return {
        ...state,
        loginLoading: false,
        loginError: actions.payload,
        loginPayload: null,
      };
    case REQUEST(actionTypes.GET_USERS):
      return {
        ...state,
        getUsersError: null,
        getUsersLoading: true,
        getUsersPayload: null,
      };
    case SUCCESS(actionTypes.GET_USERS):
      return {
        ...state,
        getUsersError: null,
        getUsersLoading: false,
        getUsersPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USERS):
      return {
        ...state,
        getUsersError: actions.payload,
        getUsersLoading: false,
        getUsersPayload: null,
      };
    case REQUEST(actionTypes.GET_SINGLE_USER):
      return {
        ...state,
        getSingleUserError: null,
        getSingleUserLoading: true,
        getSingleUserPayload: null,
      };
    case SUCCESS(actionTypes.GET_SINGLE_USER):
      return {
        ...state,
        getSingleUserError: null,
        getSingleUserLoading: false,
        getSingleUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SINGLE_USER):
      return {
        ...state,
        getSingleUserError: actions.payload,
        getSingleUserLoading: false,
        getSingleUserPayload: null,
      };
    case REQUEST(actionTypes.BAN_USER):
      return {
        ...state,
        banUserError: null,
        banUserLoading: true,
        banUserPayload: null,
      };
    case SUCCESS(actionTypes.BAN_USER):
      return {
        ...state,
        banUserError: null,
        banUserLoading: false,
        banUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.BAN_USER):
      return {
        ...state,
        banUserError: actions.payload,
        banUserLoading: false,
        banUserPayload: null,
      };
    case REQUEST(actionTypes.ACTIVATE_USER):
      return {
        ...state,
        activateUserError: null,
        activateUserLoading: true,
        activateUserPayload: null,
      };
    case SUCCESS(actionTypes.ACTIVATE_USER):
      return {
        ...state,
        activateUserError: null,
        activateUserLoading: false,
        activateUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.ACTIVATE_USER):
      return {
        ...state,
        activateUserError: actions.payload,
        activateUserLoading: false,
        activateUserPayload: null,
      };
    case REQUEST(actionTypes.DELETE_USER):
      return {
        ...state,
        deleteUserError: null,
        deleteUserLoading: true,
        deleteUserPayload: null,
      };
    case SUCCESS(actionTypes.DELETE_USER):
      return {
        ...state,
        deleteUserError: null,
        deleteUserLoading: false,
        deleteUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.DELETE_USER):
      return {
        ...state,
        deleteUserError: actions.payload,
        deleteUserLoading: false,
        deleteUserPayload: null,
      };
    case REQUEST(actionTypes.EDIT_USER):
      return {
        ...state,
        editUserError: null,
        editUserLoading: true,
        editUserPayload: null,
      };
    case SUCCESS(actionTypes.EDIT_USER):
      return {
        ...state,
        editUserError: null,
        editUserLoading: false,
        editUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.EDIT_USER):
      return {
        ...state,
        editUserError: actions.payload,
        editUserLoading: false,
        editUserPayload: null,
      };
    case REQUEST(actionTypes.GET_ONBOARDING_REQUESTS):
      return {
        ...state,
        getOnboardingRequestsError: null,
        getOnboardingRequestsLoading: true,
        getOnboardingRequestsPayload: null,
      };
    case SUCCESS(actionTypes.GET_ONBOARDING_REQUESTS):
      return {
        ...state,
        getOnboardingRequestsError: null,
        getOnboardingRequestsLoading: false,
        getOnboardingRequestsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ONBOARDING_REQUESTS):
      return {
        ...state,
        getOnboardingRequestsError: actions.payload,
        getOnboardingRequestsLoading: false,
        getOnboardingRequestsPayload: null,
      };
    case REQUEST(actionTypes.GET_VERIFICATION_DETAILS):
      return {
        ...state,
        getVerificationDetailsError: null,
        getVerificationDetailsLoading: true,
        getVerificationDetailsPayload: null,
      };
    case SUCCESS(actionTypes.GET_VERIFICATION_DETAILS):
      return {
        ...state,
        getVerificationDetailsError: null,
        getVerificationDetailsLoading: false,
        getVerificationDetailsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_VERIFICATION_DETAILS):
      return {
        ...state,
        getVerificationDetailsError: actions.payload,
        getVerificationDetailsLoading: false,
        getVerificationDetailsPayload: null,
      };
    case REQUEST(actionTypes.GET_DOWNLOAD_VERIFY_DOCUMENT):
      return {
        ...state,
        getDownloadVerifyDocumentError: null,
        getDownloadVerifyDocumentLoading: true,
        getDownloadVerifyDocumentPayload: null,
      };
    case SUCCESS(actionTypes.GET_DOWNLOAD_VERIFY_DOCUMENT):
      return {
        ...state,
        getDownloadVerifyDocumentError: null,
        getDownloadVerifyDocumentLoading: false,
        getDownloadVerifyDocumentPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_DOWNLOAD_VERIFY_DOCUMENT):
      return {
        ...state,
        getDownloadVerifyDocumentError: actions.payload,
        getDownloadVerifyDocumentLoading: false,
        getDownloadVerifyDocumentPayload: null,
      };
    case REQUEST(actionTypes.GET_ADMIN_DASHBOARD_INFO):
      return {
        ...state,
        getAdminDashboardInfoError: null,
        getAdminDashboardInfoLoading: true,
        getAdminDashboardInfoPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_DASHBOARD_INFO):
      return {
        ...state,
        getAdminDashboardInfoError: null,
        getAdminDashboardInfoLoading: false,
        getAdminDashboardInfoPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_DASHBOARD_INFO):
      return {
        ...state,
        getAdminDashboardInfoError: actions.payload,
        getAdminDashboardInfoLoading: false,
        getAdminDashboardInfoPayload: null,
      };
    case REQUEST(actionTypes.APPROVE_REJECT_USER):
      return {
        ...state,
        approveRejectUserError: null,
        approveRejectUserLoading: true,
        approveRejectUserPayload: null,
      };
    case SUCCESS(actionTypes.APPROVE_REJECT_USER):
      return {
        ...state,
        approveRejectUserError: null,
        approveRejectUserLoading: false,
        approveRejectUserPayload: actions.payload,
      };
    case FAILURE(actionTypes.APPROVE_REJECT_USER):
      return {
        ...state,
        approveRejectUserError: actions.payload,
        approveRejectUserLoading: false,
        approveRejectUserPayload: null,
      };
    case REQUEST(actionTypes.GET_USER_ASSETS):
      return {
        ...state,
        getUserAssetsError: null,
        getUserAssetsLoading: true,
        getUserAssetsPayload: null,
      };
    case SUCCESS(actionTypes.GET_USER_ASSETS):
      return {
        ...state,
        getUserAssetsError: null,
        getUserAssetsLoading: false,
        getUserAssetsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USER_ASSETS):
      return {
        ...state,
        getUserAssetsError: actions.payload,
        getUserAssetsLoading: false,
        getUserAssetsPayload: null,
      };
    case REQUEST(actionTypes.GET_USER_VERIFICATION_DETAILS):
      return {
        ...state,
        getUserVerificationDetailsError: null,
        getUserVerificationDetailsLoading: true,
        getUserVerificationDetailsPayload: null,
      };
    case SUCCESS(actionTypes.GET_USER_VERIFICATION_DETAILS):
      return {
        ...state,
        getUserVerificationDetailsError: null,
        getUserVerificationDetailsLoading: false,
        getUserVerificationDetailsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USER_VERIFICATION_DETAILS):
      return {
        ...state,
        getUserVerificationDetailsError: actions.payload,
        getUserVerificationDetailsLoading: false,
        getUserVerificationDetailsPayload: null,
      };
    case REQUEST(actionTypes.GET_USER_TRANSACTION_HISTORY):
      return {
        ...state,
        getUserTransactionHistoryError: null,
        getUserTransactionHistoryLoading: true,
        getUserTransactionHistoryPayload: null,
      };
    case SUCCESS(actionTypes.GET_USER_TRANSACTION_HISTORY):
      return {
        ...state,
        getUserTransactionHistoryError: null,
        getUserTransactionHistoryLoading: false,
        getUserTransactionHistoryPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USER_TRANSACTION_HISTORY):
      return {
        ...state,
        getUserTransactionHistoryError: actions.payload,
        getUserTransactionHistoryLoading: false,
        getUserTransactionHistoryPayload: null,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
