import { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Card,
  Container,
  MenuItem,
  Popover,
  // IconButton,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { getFeeConfig } from '../../logic/actions/admin';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
// import CustomModal from '../../components/modal';
// import Iconify from '../../components/iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'businessShare', label: 'Business Share', alignRight: false },
  { id: 'customerShare', label: 'Customer Share', alignRight: false },
  { id: 'costBearer', label: 'Cost Bearer', alignRight: false },
  { id: 'minAmount', label: 'Min Amount', alignRight: false },
  { id: 'maxAmount', label: 'Max Amount', alignRight: false },
  { id: 'paymentProcessor', label: 'Payment Processor', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  // { id: '' },
];

const FeeConfig = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  // const [, setSelectedId] = useState(false);
  const { getFeeConfigPayload, getFeeConfigLoading } = useSelector((state) => state.adminReducer);

  const [open, setOpen] = useState(null);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        costBearer: item?.costBearer,
        customerShare: item?.customerShare,
        businessShare: item?.businessShare,
        maxAmount: item?.maxAmount,
        minAmount: item?.minAmount,
        paymentProcessor: item?.paymentProcessor,
        paymentProcessorId: item?.paymentProcessorId,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter((item) => {
      const itemValues = Object.values(item);
      const hasMatchingColumn = itemValues.some((columnValue) =>
        columnValue?.toString().toLowerCase().includes(filterName.toLowerCase())
      );
      return hasMatchingColumn;
    });

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  const fetchFeeConfig = useCallback(() => {
    dispatch(getFeeConfig(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    fetchFeeConfig();
  }, [fetchFeeConfig, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const handleOpenMenu = (event, id) => {
  //   setSelectedId(id);
  //   setOpen(event.currentTarget);
  // };

  return (
    <>
      <Helmet>
        <title>Musha | Fee Config</title>
      </Helmet>
      <Container>
        <Stack>
          <Typography variant="h4" gutterBottom>
            Fee Config
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search config...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <></>
                {getFeeConfigLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={8} />
                  </TableBody>
                ) : filteredRows?.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell component="th" scope="row">
                            {row.businessShare || 'N/A'}
                          </TableCell>

                          <TableCell align="left">{row.customerShare || 'N/A'}</TableCell>

                          <TableCell align="left">{row.costBeare || 'N/A'}</TableCell>
                          <TableCell align="left">{row.minAmount || 'N/A'}</TableCell>

                          <TableCell align="left">{row.maxAmount || 'N/A'}</TableCell>
                          <TableCell align="left">{row.paymentProcessor || 'N/A'}</TableCell>

                          <TableCell align="left">{row.dateCreated || 'N/A'}</TableCell>
                          {/* <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, row.paymentProcessorId)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={() => setOpen(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: '180px',
                  zIndex: 1,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setOpen(null);
                }}
              >
                {/* <Iconify icon={'mingcute:add-fill'} sx={{ mr: 2 }} /> */}
                Delete
              </MenuItem>
            </Popover>

            {getFeeConfigPayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalResults}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
};

export default FeeConfig;
