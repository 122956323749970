import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../logic/actions/notification';

const useAuthToken = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [tokenExpiration, setTokenExpiration] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedTokenExpiration = localStorage.getItem('tokenExpiration');
    if (storedToken && storedTokenExpiration) {
      setToken(storedToken);
      setTokenExpiration(storedTokenExpiration);
    }
  }, []);

  const saveToken = (newToken, expiration) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('tokenExpiration', expiration);
    setToken(newToken);
    setTokenExpiration(expiration);
  };

  const clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setToken('');
    setTokenExpiration('');
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const currentTime = new Date().getTime();
      const tokenExpired = new Date(tokenExpiration).getTime();
      if (currentTime > tokenExpired) {
        dispatch(errorMessage('Token has expired please login again'));
        navigate('/login', { replace: true });
        clearToken();
      }
    };

    const expirationTimer = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(expirationTimer);
  }, [dispatch, navigate, tokenExpiration]);

  return { token, saveToken, clearToken };
};

export default useAuthToken;
