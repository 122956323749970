import { createAsyncAction } from 'redux-promise-middleware-actions';
import { Get, Post, Put } from '../../apiServices/apiHandler.service';
import actionTypes from './types';

const API_URL = `${process.env.REACT_APP_URL}Admin`;

export const getAdminRoles = createAsyncAction(actionTypes.GET_ADMIN_ROLES, async (pageNumber = 1, pageSize = 10) => {
  const apiUrl = `${API_URL}/GetRoles`; // Your API URL

  const response = await Get(`${apiUrl}?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`);
  return response.data;
});

export const addNewRole = createAsyncAction(actionTypes.ADD_NEW_ROLE, async (name) => {
  const apiUrl = `${API_URL}/AddNewRole`;

  const response = await Post(`${apiUrl}`, { name });
  return response.data;
});

export const deleteRole = createAsyncAction(actionTypes.DELETE_ROLE, async (payload) => {
  const apiUrl = `${API_URL}/DeleteRole`;
 
  const response = await Post(`${apiUrl}`,  payload);
  return response.data;
});

export const getAdminPermissions = createAsyncAction(actionTypes.GET_ADMIN_PERMISSIONS, async () => {
  const apiUrl = `${API_URL}/Permissions`;

  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const addPermissionToRole = createAsyncAction(actionTypes.ADD_PERMISSION_TO_ROLE, async (payload) => {
  const apiUrl = `${API_URL}/AddPermissionToRole`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const removePermissionFromRole = createAsyncAction(actionTypes.REMOVE_PERMISSION_FROM_ROLE, async (payload) => {
  const apiUrl = `${API_URL}/RemovePermissionFromRole`;

  const response = await Put(`${apiUrl}`, payload);
  return response.data;
});

export const getRolePermissions = createAsyncAction(actionTypes.GET_ROLE_PERMISSIONS, async (RoleId) => {
  const apiUrl = `${API_URL}/GetRolePermission`;

  const response = await Get(`${apiUrl}/${RoleId}`);
  return response.data;
});

export const getAdminUsers = createAsyncAction(actionTypes.GET_ADMIN_USERS, async (name) => {
  const API_URL = `${process.env.REACT_APP_URL}User`;

  const apiUrl = `${API_URL}/user-search`;

  const response = await Get(`${apiUrl}?name=${name}`);
  return response.data;
});

export const getUserRoles = createAsyncAction(actionTypes.GET_USER_ROLES, async (UserId) => {
  const apiUrl = `${API_URL}/GetUserRoles`;

  const response = await Get(`${apiUrl}/${UserId}`);
  return response.data;
});

export const getSearchvalue = (payload) => ({
  type: actionTypes.GET_SEARCH_VALUE,
  payload,
});

export const getUserId = (payload) => ({
  type: actionTypes.GET_USER_ID,
  payload,
});

export const setValue = (payload) => ({
  type: actionTypes.SET_VALUE,
  payload,
});

export const addUserToRole = createAsyncAction(actionTypes.ADD_USER_TO_ROLE, async (payload) => {
  const apiUrl = `${API_URL}/AddUserToRole`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const removeUserFromRole = createAsyncAction(actionTypes.REMOVE_USER_FROM_ROLE, async (payload) => {
  const apiUrl = `${API_URL}/RemoveUserFromRole`;

  const response = await Put(`${apiUrl}`, payload);
  return response.data;
});

export const getUsersFromRoles = createAsyncAction(
  actionTypes.GET_USERS_FROM_ROLE,
  async (pageNumber = 1, pageSize = 10, payload) => {
    const { roleId, pendingRequest } = payload;
    const apiUrl = `${API_URL}/GetUserInRole/${roleId}/Users`; // Your API URL

    const response = await Get(
      `${apiUrl}?PageNumber=${pageNumber + 1}&PageSize=${pageSize}&PendingRequest=${pendingRequest}`
    );
    return response.data;
  }
);

export const registerAdmin = createAsyncAction(actionTypes.REGISTER_ADMIN, async (payload) => {
  const apiUrl = `${API_URL}/RegisterAdmin`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const registerAdminPayload = (payload) => ({
  type: actionTypes.REGISTER_ADMIN_PAYLOAD,
  payload,
});

export const getAdmins = createAsyncAction(
  actionTypes.GET_ADMINS,
  async (pageNumber = 1, pageSize = 10, pendingRequest = true) => {
    const apiUrl = `${process.env.REACT_APP_URL}User/admin-users`;

    const response = await Get(
      `${apiUrl}?PageNumber=${pageNumber + 1}&PageSize=${pageSize}&PendingRequest=${pendingRequest}`
    );
    return response.data;
  }
);

export const getAdminCurrency = createAsyncAction(actionTypes.GET_ADMIN_CURRENCY, async () => {
  const API_URL = `${process.env.REACT_APP_URL}Lookup`;
  const apiUrl = `${API_URL}/currency`;

  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const getTimeZone = createAsyncAction(actionTypes.GET_ADMIN_TIMEZONE, async () => {
  const API_URL = `${process.env.REACT_APP_URL}User`;

  const apiUrl = `${API_URL}/timezone`;

  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const getAdminDashboardInfo = createAsyncAction(actionTypes.GET_ADMIN_DASHBOARD_INFO, async (payload) => {
  const apiUrl = `${API_URL}/GetAdminDashboardInfo`;
  const response = await Post(apiUrl, payload);
  return response.data;
});

export const getOrders = createAsyncAction(
  actionTypes.GET_ORDERS,
  async (pageNumber, pageSize = 10, filterName, value) => {
    // Check if filterName and value are defined before including them in the URL
    let filterParams = '';
    if (filterName !== undefined && value !== undefined) {
      filterParams = `${filterName}=${value}`;
    }

    const apiUrl = `${API_URL}/Getorders?${filterParams}&pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;
    const response = await Get(apiUrl);
    return response.data;
  }
);


export const cancelSingleOrder = createAsyncAction(actionTypes.CANCEL_ORDER_BY_ID, async (payload) => {
  const apiUrl = `${API_URL}/Cancelorder`;
  const id = {
    orderId: payload,
  };
  const response = await Put(`${apiUrl}?OrderId=${payload}`, id);
  return response.data;
});

export const getExchangeRate = createAsyncAction(actionTypes.GET_EXCHANGE_RATE, async (payload) => {
  const { pageNumber, pageSize } = payload;
  const apiUrl = `${API_URL}/GetExchangeRate?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;
  const response = await Get(apiUrl, payload);
  return response.data;
});

export const createExchangeRate = createAsyncAction(actionTypes.CREATE_EXCHANGE_RATE, async (payload) => {
  const apiUrl = `${API_URL}/CreateExchangeRate`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getFeeConfig = createAsyncAction(actionTypes.GET_FEE_CONFIG, async (payload) => {
  const apiUrl = `${API_URL}/GetFeeConfig?pageNumber=${payload.pageNumber + 1}&pageSize=${payload.pageSize}`;
  const response = await Get(apiUrl, payload);
  return response.data;
});

export const createFeeConfig = createAsyncAction(actionTypes.CREATE_FEE_CONFIG, async (payload) => {
  const apiUrl = `${API_URL}/CreateFeeConfig`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getPaymentProcessor = createAsyncAction(actionTypes.GET_PAYMENT_PROCESSOR, async (payload) => {
  const { pageNumber, pageSize } = payload;
  const apiUrl = `${API_URL}/GetPaymentProcessor?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;
  const response = await Get(apiUrl, payload);
  return response.data;
});

export const getWebHooks = createAsyncAction(actionTypes.GET_WEBHOOKS, async (pageNumber = 0, pageSize = 10) => {
  const apiUrl = `${API_URL}/GetWebhookEvents?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;
  const payload = {
    pageNumber,
    pageSize,
  };
  const response = await Post(apiUrl, payload);
  return response.data;
});

export const retriggerHook = createAsyncAction(actionTypes.RE_TRIGGER_WEBHOOK, async (id) => {
  const apiUrl = `${API_URL}/ReprocessWebhook/${id}`;

  const payload = { id };

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getPriceHistory = createAsyncAction(actionTypes.GET_PRICE_HISTRORY, async (id) => {
  const apiUrl = `${API_URL}/GetAssetPrices/${id}`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const modifyPrice = createAsyncAction(actionTypes.MODIFY_ASSET_HISTORY, async (payload) => {
  const apiUrl = `${API_URL}/AssetPriceModification`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getAssetOrderDetails = createAsyncAction(actionTypes.GET_ASSET_ORDER_DETAILS, async (id) => {
  const apiUrl = `${API_URL}/GetOrderTransactions?orderId=${id}`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const getSettlements = createAsyncAction(
  actionTypes.GET_SETTLEMENTS,
  async (pageNumber = 1, pageSize = 10, filterPayload) => {
    const apiUrl = `${API_URL}/GetSettlement`;
    const payload = {
      pageNumber: pageNumber + 1,
      pageSize,
      ...filterPayload, // Include additional filter parameters
    };

    const response = await Post(apiUrl, payload);
    return response.data;
  }
);
