import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Typography,
  Card,
  Container,
} from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar/Scrollbar';
import ReusableTabComponent from '../../components/Tabs';
import AllAssets from './allAsset';
import PendingRequest from './pendingRequest';

const tabs = [
  { label: 'All Assets', value: '1', content: <AllAssets /> },
  { label: 'Pending Request', value: '2', content: <PendingRequest /> },
];

export default function Index() {

  return (
    <>
      <Helmet>
        <title>Musha | Assets</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Asset
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <ReusableTabComponent tabs={tabs} />
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
