import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const Protected = ({ children }) => {
  const auth = localStorage.getItem('token');
  return auth ? children : <Navigate to="/login" />;
};

Protected.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Protected;
