import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Grid from '@mui/material/Grid';


const UserInformation = ({ user }) => (
    <ThemeProvider
        theme={{
            spacing: (factor) => `${8 * factor}px`,
            palette: {
                grey: {
                    300: "#ccc",
                },
            },
        }}
    >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    First Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.firstname || (user?.firstname === null ? user?.companyName : "")}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Address
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.address || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Last Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.lastname || (user?.lastname === null ? user?.companyName : "")}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Country
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.country || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Phone
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.phone || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    User ID
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.id || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Email
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {user?.email || "N/A"}
                </Typography>
            </Grid>
        </Grid>
    </ThemeProvider>
)

UserInformation.propTypes = {
  user: PropTypes.shape({
    companyName: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

export default UserInformation;
