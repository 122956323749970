import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Grid from '@mui/material/Grid';


const AccountInformation = ({ user }) => (
  <ThemeProvider
    theme={{
      spacing: (factor) => `${8 * factor}px`,
      palette: {
        grey: {
          300: '#ccc',
        },
      },
    }}
  >
    <Typography variant="h4" my={2} gutterBottom>
      Account Information
    </Typography>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Account Status
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.accountStatus.replace(/([A-Z])/g, ' $1').trim() || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Default Currency
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.defaultCurrency || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Account Type
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.accountType || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Date Created
        </Typography>
        <Typography variant="body1" gutterBottom>
          {new Date(user?.dateCreated).toLocaleString() || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          2FA Enabled?
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.is2faEnabled ? 'Yes' : 'No' || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Date Banned
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.dateBanned || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Email Confirmed
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.isEmailConfirmed ? 'Yes' : 'No' || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Date Blocked
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.dateBlocked || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Phone Confirmed
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.isPhoneNumberConfirmed ? 'Yes' : 'No' || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Date Deleted
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.dateDeleted || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  </ThemeProvider>
);

AccountInformation.propTypes = {
  user: PropTypes.shape({
    accountStatus: PropTypes.string,
    accountType: PropTypes.string,
    address: PropTypes.string,
    companyName: PropTypes.string,
    country: PropTypes.string,
    dateBanned: PropTypes.string,
    dateBlocked: PropTypes.string,
    dateCreated: PropTypes.string,
    dateDeleted: PropTypes.string,
    id: PropTypes.number,
    is2faEnabled: PropTypes.bool,
    isEmailConfirmed: PropTypes.bool,
    isPhoneNumberConfirmed: PropTypes.bool,
    defaultCurrency: PropTypes.string,
  }).isRequired,
};

export default AccountInformation;
