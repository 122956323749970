import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage/index';
import UserDetailsPage from './pages/UserPage/userDetails';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Admin from './pages/Admin';
import WebHooks from './pages/WebHooks';
import Settlements from './pages/Settlement';
import Dashboard from './pages/Dashboard';
import Asset from './pages/Asset';
import AssetDetails from './pages/Asset/assetDetails';
import Protected from './components/Protected';
import Permissions from './pages/RolesManagement/Permissions';
import Roles from './pages/RolesManagement/Roles';
import UserRole from './pages/RolesManagement/UserRoles';
import ViewUserInRole from './pages/RolesManagement/UserRoles/viewUserInRole';
// import Payout from './pages/payout';
// import PayoutDetails from './pages/payout/payoutDetails';
import AssetConfig from './pages/assetConfiguration';
import Orders from './pages/Orders/orders';
import FeeConfig from './pages/FeeConfig/feeConfig';
import ExchangeRate from './pages/ExchangeRate/exchangeRate';
import PaymentProcessor from './pages/PaymentProcessor';
import OnBoardingRequest from './pages/onboardingRequest';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          path: 'dashboard',
          element: (
            <Protected>
              <Dashboard />
            </Protected>
          ),
        },
        {
          path: 'user',
          element: (
            <Protected>
              <UserPage />
            </Protected>
          ),
        },
        {
          path: 'user/:id',
          element: (
            <Protected>
              <UserDetailsPage />
            </Protected>
          ),
        },
        {
          path: 'onboarding-request/:id',
          element: (
            <Protected>
              <OnBoardingRequest />
            </Protected>
          ),
        },
        {
          path: 'roles',
          element: (
            <Protected>
              <Roles />
            </Protected>
          ),
        },
        {
          path: 'permissions',
          element: (
            <Protected>
              <Permissions />
            </Protected>
          ),
        },
        {
          path: 'user-role',
          element: (
            <Protected>
              <UserRole />
            </Protected>
          ),
        },
        {
          path: 'user-role/:id',
          element: (
            <Protected>
              <ViewUserInRole />
            </Protected>
          ),
        },
        {
          path: 'admin',
          element: (
            <Protected>
              <Admin />
            </Protected>
          ),
        },
        {
          path: 'asset',
          element: (
            <Protected>
              <Asset />
            </Protected>
          ),
        },
        {
          path: 'asset/:id',
          element: (
            <Protected>
              <AssetDetails />
            </Protected>
          ),
        },
        {
          path: '/asset-configuration',
          element: (
            <Protected>
              <AssetConfig />
            </Protected>
          ),
        },
        {
          path: '/orders',
          element: (
            <Protected>
              <Orders />
            </Protected>
          ),
        },
        {
          path: '/fee-config',
          element: (
            <Protected>
              <FeeConfig />
            </Protected>
          ),
        },
        {
          path: '/exchange-rate',
          element: (
            <Protected>
              <ExchangeRate />
            </Protected>
          ),
        },
        {
          path: '/payment-processor-config',
          element: (
            <Protected>
              <PaymentProcessor />
            </Protected>
          ),
        },
        // {
        //   path: 'payout',
        //   element: (
        //     <Protected>
        //       <Payout />
        //     </Protected>
        //   ),
        // },
        // {
        //   path: 'payout/:id',
        //   element: (
        //     <Protected>
        //       <PayoutDetails />
        //     </Protected>
        //   ),
        // },
        {
          path: 'web-hooks',
          element: (
            <Protected>
              <WebHooks />
            </Protected>
          ),
        },
        {
          path: 'settlements',
          element: (
            <Protected>
              <Settlements />
            </Protected>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },

    { element: <Navigate to="/login" />, index: true },
    { path: '*', element: <Navigate to="/404" /> },
    { path: '404', element: <Page404 /> },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
