/* eslint-disable react/prop-types */
import React from 'react';
import {
  TableRow,
  TableCell,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const TablePulse = ({ arrayLength, rows }) => (
  <>
    {Array.from(Array(arrayLength).keys()).map((id) => (
      <TableRow key={id}>
        {Array.from(Array(rows).keys()).map((row, index) => (
          <TableCell key={index} align={row.align} sx={{ borderBottom: 'none' }}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={14} sx={{ borderRadius: 1, my: 1 }} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default TablePulse; 
