/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Typography, Button, Stack, Grid } from '@mui/material';
import CustomModal from '../modal';
import EditForm from './editForm';

const AssetInformation = ({ asset, fetchAssetDetails }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems={'center'} spacing={2} my={3}>
        <Typography variant="h4" gutterBottom>
          Asset Information
        </Typography>
        <Button
          onClick={() => setOpen(true)}
          size="large"
          variant="contained"
          style={{
            borderRadius: 10,
            fontWeight: '400',
            color: 'white',
            backgroundColor: 'green',
          }}
          size="large"
          color="inherit"
          variant="body1"
        >
          {' '}
          Modify Price
        </Button>
      </Stack>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Asset Title
          </Typography>
          <Typography variant="body1" gutterBottom>
            {' '}
            {asset?.title || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Asset Price
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.unitPrice || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Asset Category
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.propertyType || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Date created
          </Typography>
          <Typography variant="body1" gutterBottom>
            {new Date(asset?.dateCreated).toLocaleString() || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Asset ID
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.id || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Moratorium
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.moratorium || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Original Asset Valuation
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.companyname || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Date Re-sold
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.address || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Current Asset Valuation
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.companyname || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Asset Location
          </Typography>
          <>
            {asset?.assetAddresses.map((address) => (
              <Typography variant="body1" gutterBottom key={address}>
                {address.fullAddress}
              </Typography>
            ))}
          </>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Total Units
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.units || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            No. of Owners
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.owners || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Available Units
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.availableUnits || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Transaction count
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.transactionCount || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Location
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.assetAddresses[0]?.country || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Full Address
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.assetAddresses[0]?.fullAddress || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            State
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.assetAddresses[0]?.state || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom>
            Local Government
          </Typography>
          <Typography variant="body1" gutterBottom>
            {asset?.assetAddresses[0]?.lga || 'N/A'}
          </Typography>
        </Grid>
      </Grid>

      <CustomModal
        isOpen={isOpen}
        title="Edit Asset"
        content={
          <EditForm
            modalState={isOpen}
            asset={asset}
            setModalState={setOpen}
            id={asset.id}
            fetchAssetDetails={fetchAssetDetails}
          />
        }
        contentText={'Are you sure you want to Modify this Asset?'}
        onClose={() => {
          setOpen(false);
        }}
        creating={false}
        disableCreate={false}
        hideCloseBtn
        hideCreateBtn
        closeText={'Close'}
        createText={'Edit'}
        onCreate={() => {}}
        createBtnColor=""
      />
    </>
  );
};
AssetInformation.propTypes = {
  asset: PropTypes.shape({}).isRequired,
  fetchAssetDetails: PropTypes.func.isRequired,
};

export default AssetInformation;
