import * as React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

export default function SelectAPermission({
    loadingPermissions,
    permissions,
    payload,
    onChange,
    submitStatus,
    clearOnModalClose
}) {
    const options = permissions || [];
    const [value, setValue] = React.useState([]); // Track the selected values

    const handleOnChange = (event, newValue) => {
        setValue(newValue);
        onChange(event, newValue);
    };

    // const handleClearSelection = () => {
    //     setValue([]); // Clear the selected values
    //     onChange(null, []); // Call the onChange with an empty array
    // };

    React.useEffect(() => {
        if (clearOnModalClose === false) {
            setValue([]); // Clear the selected values
            onChange(null, []); // Call the onChange with an empty array
        }
    }, [clearOnModalClose, onChange]);

    return (
        <>
            <Autocomplete
                multiple
                limitTags={3}
                labelid="permissions"
                id="permissions"
                name="permissionId"
                label="Permissions"
                options={options}
                value={value} // Set the value prop to the selected values
                autoHighlight
                fullWidth
                loading={loadingPermissions}
                onChange={handleOnChange}
                clearOnEscape
                onClose={(event, reason) => {
                    if (reason === 'reset') {
                        setValue([]); // Clear the selected values
                        onChange(event, null);
                    }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={submitStatus && payload?.permissionId.length === 0}
                        label="Permissions"
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            endadornment: (
                                <>
                                    {loadingPermissions ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
        </>
    );
}

SelectAPermission.propTypes = {
    permissions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    submitStatus: PropTypes.bool.isRequired,
    payload: PropTypes.object.isRequired,
    loadingPermissions: PropTypes.bool,
    clearOnModalClose: PropTypes.bool
};
