import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Table,
  Stack,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { errorMessage, successMessage } from '../../logic/actions/notification';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { getAdmins } from '../../logic/actions/admin';
import CustomModal from '../../components/modal';
import RegisterAdminForm from './registerAdminForm';
import TablePulse from '../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'accountType', label: 'Account Type', alignRight: false },
  { id: 'timeZone', label: 'Time Zone', alignRight: false },
  { id: 'Currency', label: 'Currency', alignRight: false },
];

export default function Index() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState();
  const [filteredRows, setFilteredRows] = useState('');
  const [openAddModal, setAddModal] = useState(false);
  const dispatch = useDispatch();

  const { registerAdminLoading, getAdminsLoading, getAdminsPayload } = useSelector((state) => state.adminReducer);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        name: `${item.firstname || ''} ${item.lastname || ''}`.trim() || item.companyName,
        id: item?.id,
        email: item?.email,
        verified: item?.isPhoneNumberConfirmed && item?.isEmailConfirmed ? 'Yes' : 'No',
        phoneNumber: item?.phone,
        status: item?.accountStatus,
        accountType: item?.accountType,
        timeZone: item?.timeZone,
        currency: item?.defaultCurrency,
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter(
      (item) =>
        (item.name?.toLowerCase() || '').includes(filterName.toLowerCase()) ||
        (item.email?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    const pendingRequest = true;
    dispatch(getAdmins(page, rowsPerPage, pendingRequest))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Musha | Admin </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Admin</Typography>
          <Button onClick={() => setAddModal(true)} variant="outlined" color="primary" size="large">
            <Iconify icon={'subway:admin-1'} sx={{ mr: 1 }} />
            Add Admin
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search Admin...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {getAdminsLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={8} />
                  </TableBody>
                ) : filteredRows?.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.name}</TableCell>

                          <TableCell align="left">{row.email}</TableCell>

                          <TableCell align="left">{row.phoneNumber}</TableCell>

                          <TableCell align="left">{row.verified}</TableCell>

                          <TableCell align="left">{row.status}</TableCell>

                          <TableCell align="left">{row.accountType}</TableCell>

                          <TableCell align="left">{row.timeZone}</TableCell>

                          <TableCell align="left">{row.currency}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {getAdminsPayload?.totalCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getAdminsPayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Rows:'}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <CustomModal
        isOpen={openAddModal}
        title="Register Admin"
        content={
          <RegisterAdminForm
            isLoading={registerAdminLoading}
            setAddModal={setAddModal}
            modalState={openAddModal}
            closeModal={() => setAddModal(false)}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        }
        contentText={''}
        onClose={() => {
          setAddModal(false);
        }}
        creating={registerAdminLoading}
        disableCreate={registerAdminLoading}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={'Register Admin'}
        onCreate={() => {}}
        createBtnColor=""
      />
    </>
  );
}
