import React, { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { getAssets, getSearchAssets } from '../../logic/actions/asset';
import TablePulse from '../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'unitPrice', label: 'Unit Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const PendingAsset = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();
  const PendingRequest = true;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      if (srhTxt) {
        dispatch(getSearchAssets(srhTxt))
          .then((responseData) => {
            const data = responseData?.value.data;
            setRows(data);
            setTotalResults(responseData?.value?.totalCount || 0);
          })
          .catch((error) => console.error(error));
      }
    }, 1000),
    [dispatch, page, rowsPerPage]
  );

  useEffect(() => {
    debounceText(filterName);
  }, [debounceText, filterName]);

  const { assetsLoading, searchAssetsLoading } = useSelector((state) => state.assetReducer);

  const format = useCallback(
    () =>
      rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => ({
        title: item?.title,
        id: item?.id,
        dateCreated: new Date(item.dateCreated).toLocaleString(),
        unitPrice: item?.unitPrice,
        units: item?.units,
        state: item?.state,
        status: item?.status,
      })),
    [rows, page, rowsPerPage]
  );

  const memoizedFetchPendingAssets = useCallback(async () => {
    try {
      const responseData = await dispatch(getAssets(page, rowsPerPage, PendingRequest));
      const data = responseData?.value.data;
      setRows(data);
      setTotalResults(responseData?.value?.totalCount || 0);
    } catch (error) {
      console.error(error);
    }
  }, [PendingRequest, dispatch, page, rowsPerPage]);

  useEffect(() => {
    memoizedFetchPendingAssets();
  }, [memoizedFetchPendingAssets]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <div className="all-asset">
      <UserListToolbar
        numSelected={selected.length}
        placeholder="Search Asset"
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <TableContainer sx={{ minWidth: 800 }}>
        <Table aria-labelledby="tableTitle" size={'medium'}>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={headCells}
            rowCount={format()?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          {assetsLoading || searchAssetsLoading ? (
            <TableBody>
              <TablePulse arrayLength={10} rows={5} />
            </TableBody>
          ) : format()?.length > 0 ? (
            <TableBody>
              {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell align="left">{row.dateCreated}</TableCell>
                    <TableCell align="left">{row.unitPrice}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" alignItems={'center'}>
                        <Typography
                          onClick={() => navigate(`/asset/${row.id}`)}
                          sx={{ fontSize: '13px', fontWeight: 'medium', color: 'blue', cursor: 'pointer' }}
                        >
                          View more
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3, width: '100%' }} fullWidth>
                  <div>No record found in logs</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {totalResults ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 170]}
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default PendingAsset