import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Box, Stack, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewPropertyTypeConfig, getCountry, getState } from '../../logic/actions/asset';
import { errorMessage, successMessage } from '../../logic/actions/notification';

const AddPropertyConfigurationForm = ({ isSubmitting, setAddModal, modalState, propertyTypeId }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      minimumUnit: '',
      stateId: '',
      countryId: '',
    },
  });

  const dispatch = useDispatch();
  const [countryId, setCountryId] = React.useState('');
  const [isCheck, setChecked] = useState(false);

  const { statePayload, stateLoading, countryLoading, countryPayload } = useSelector((state) => state.assetReducer);
  const options = countryPayload?.data || [];

  useEffect(() => {
    if (modalState) dispatch(getCountry());
  }, [dispatch, modalState]);

  useEffect(() => {
    if (countryId) {
      dispatch(getState(countryId));
    }
  }, [dispatch, countryId]);

  const onSubmit = (data) => {
    const { countryId, stateId, ...rest } = data;
    const correctedStateId = stateId === '' ? null : stateId;
    const convertedFormData = {
      ...rest,
      countryId: countryId.id,
      stateId: correctedStateId,
      propertyTypeId,
      isPropertyConversionAllowed: isCheck,
    };

    if (data) {
      dispatch(addNewPropertyTypeConfig(convertedFormData))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Configuration set successfully'));
            setAddModal(false);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
            setAddModal(false);
          } else {
            const errorResponse = error?.data?.responseMessage;
            const isErrors = error?.data;
            const errorMessages = isErrors?.errors ? Object.values(isErrors.errors).flat() : [];
            const errorMessageString = errorMessages?.join(', ');
            const fullErrorMessage = isErrors?.errors ? errorMessageString : errorResponse;
            dispatch(errorMessage(fullErrorMessage));
          }
        });
    }
  };

  useEffect(() => {
    if (!modalState) {
      reset();
    }
  }, [modalState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
          gap: 1.5,
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <TextField
            {...register('minimumPriceUnit', { required: true })}
            fullWidth
            label="Enter Minimum Price Unit"
            variant="outlined"
            error={!!errors.minimumPriceUnit}
            helperText={errors.minimumPriceUnit && 'Minimum Price Unit is required'}
          />
          <TextField
            {...register('maximumPriceUnit', { required: true })}
            fullWidth
            label="Enter Maximum Price Unit"
            variant="outlined"
            error={!!errors.maximumPriceUnit}
            helperText={errors.maximumPriceUnit && 'Maximum Price Unit is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <TextField
            {...register('maximumRoi', { required: true })}
            fullWidth
            label="Enter Maximum Roi"
            variant="outlined"
            type="number"
            error={!!errors.maximumRoi}
            helperText={errors.maximumRoi && 'Maximum Roi is required'}
          />
          <TextField
            {...register('maximumUnit', { required: true })}
            fullWidth
            label="Enter Maximum Unit"
            variant="outlined"
            type="number"
            error={!!errors.maximumUnit}
            helperText={errors.maximumUnit && 'Maximum Unit is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <TextField
            {...register('minimumUnit', { required: true })}
            fullWidth
            label="Enter Minimum Unit"
            variant="outlined"
            type="number"
            error={!!errors.minimumUnit}
            helperText={errors.minimumUnit && 'Minimum Unit is required'}
          />
          <TextField
            {...register('roiMultiple', { required: true })}
            fullWidth
            label="Enter Roi Multiple"
            variant="outlined"
            type="text"
            error={!!errors.roiMultiple}
            helperText={errors.roiMultiple && 'Roi Multiple is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <TextField
            {...register('unitsCounter', { required: true })}
            fullWidth
            label="Enter Units Counter"
            variant="outlined"
            type="number"
            error={!!errors.unitsCounter}
            helperText={errors.unitsCounter && 'Units Counter is required'}
          />
          <TextField
            {...register('minimumAllowablePropertyConvertibleUnit', { required: true })}
            fullWidth
            label="Enter Minimum Allowable Property Convertible Unit"
            variant="outlined"
            type="number"
            error={!!errors.minimumAllowablePropertyConvertibleUnit}
            helperText={
              errors.minimumAllowablePropertyConvertibleUnit &&
              'Minimum Allowable Property Convertible Unit is required'
            }
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="countryId"
                options={options}
                autoHighlight
                value={field.value || null} // Use value prop instead of defaultValue
                loading={countryLoading}
                fullWidth
                onChange={(event, value) => {
                  setValue('countryId', value);
                  setCountryId(value?.id);
                  clearErrors('countryId');
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name || ''}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a Country"
                    error={!!errors.countryId}
                    helperText={errors.countryId && 'Country is required'}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
            name="countryId"
            control={control}
            rules={{ required: true }}
            defaultValue={null}
          />
          <Controller
            name="stateId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.defaultCurrencyId}>
                <InputLabel id="select">Select state</InputLabel>
                <Select
                  {...field}
                  labelId="select"
                  disabled={stateLoading || !countryId}
                  id="stateId"
                  label="Select state"
                  {...register('stateId')}
                  onChange={(event) => {
                    setValue('stateId', event?.target.value);
                  }}
                >
                  {statePayload?.data.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                  {stateLoading && <Typography sx={{ textAlign: 'center' }}>Loading</Typography>}
                </Select>
                <FormHelperText>{errors.stateId && 'State is required'}</FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Checkbox checked={isCheck} onChange={(event) => setChecked(event.target.checked)} />
          <Typography sx={{ fontSize: '13px', fontWeight: 'medium', color: 'black', cursor: 'pointer' }}>
            Property conversion allowed
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          width={'100%'}
          marginBottom={3}
        >
          <Button
            onClick={() => setAddModal(false)}
            variant="outlined"
            size="large"
            sx={{
              width: '100%',
              color: '#000',
              bg: '#000',
              border: 'black 1px solid',
              '&:hover': { border: 'none' },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            sx={{ width: '100%' }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Box>
    </form>
  );
};

export default AddPropertyConfigurationForm;

AddPropertyConfigurationForm.propTypes = {
  isSubmitting: PropTypes.bool,
  setAddModal: PropTypes.func,
  token: PropTypes.string,
  modalState: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  propertyTypeId: PropTypes.number.isRequired,
};
