import { Helmet } from 'react-helmet-async';
import React from 'react';
import {
    Stack,
    Typography,
    Card,
    Container,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import ReusableTabComponent from '../../components/Tabs';
import AllUsers from './AllUsers';
import OnBoardingRequest from './OnBoardingRequest';

const tabs = [
  { label: 'All Users', value: '1', content: <AllUsers /> },
  { label: 'Onboarding Request', value: '2', content: <OnBoardingRequest /> },
];

export default function UserPage() {


    return (
      <>
        <Helmet>
          <title>Musha | User </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              User
            </Typography>
          </Stack>

          <Card>
            <Scrollbar>
              <ReusableTabComponent tabs={tabs} />
            </Scrollbar>
          </Card>
        </Container>
      </>
    );
}
