import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import {
  registerAdminPayload,
  registerAdmin,
  getAdminCurrency,
  getTimeZone,
  getAdmins,
} from '../../logic/actions/admin';
import { errorMessage, successMessage } from '../../logic/actions/notification';

const RegisterAdminForm = ({ isLoading, setAddModal, modalState, rowsPerPage, page }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  const { getAdminCurrencyPayload, getAdminCurrencyLoading, getAdminTimeZonePayload } = useSelector(
    (state) => state.adminReducer
  );
  const options = getAdminTimeZonePayload?.data || [];

  useEffect(() => {
    dispatch(getAdminCurrency());
    dispatch(getTimeZone());
  }, [dispatch]);

  const onSubmit = (data) => {
    const { timeZone, ...rest } = data;
    const convertedFormData = {
      ...rest,
      timeZone: timeZone.displayName,
    };
    dispatch(registerAdminPayload(convertedFormData));
    const pendingRequest = true;
    if (data) {
      dispatch(registerAdmin(convertedFormData))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Admin registered successfully'));
            setAddModal(false);
            dispatch(getAdmins(page, rowsPerPage, pendingRequest));
          }
        })
        .catch((error) => {
          const errorResponse = error?.data?.responseMessage;
          const isErrors = error?.data;
          const errorMessages = isErrors?.errors ? Object.values(isErrors.errors).flat() : [];
          const errorMessageString = errorMessages?.join(', ');
          const fullErrorMessage = isErrors?.errors ? errorMessageString : errorResponse;
          dispatch(errorMessage(fullErrorMessage));
        });
    }
  };

  useEffect(() => {
    if (!modalState) {
      reset();
    }
  }, [modalState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: 3 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} width={'100%'}>
          <TextField
            {...register('firstname', { required: true })}
            fullWidth
            label="Enter First Name"
            variant="outlined"
            error={!!errors.firstname}
            helperText={errors.firstname && 'First Name is required'}
          />
          <TextField
            {...register('lastname', { required: true })}
            fullWidth
            label="Enter Last Name"
            variant="outlined"
            error={!!errors.lastname}
            helperText={errors.lastname && 'Last Name is required'}
          />
        </Stack>
        <TextField
          {...register('email', { required: true })}
          fullWidth
          label="Enter Email"
          variant="outlined"
          type="email"
          error={!!errors.email}
          helperText={errors.email && 'Email is required'}
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              id="timeZone"
              options={options}
              autoHighlight
              value={field.value || null} // Use value prop instead of defaultValue
              fullWidth
              onChange={(event, value) => {
                setValue('timeZone', value);
              }}
              isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              getOptionLabel={(option) => option.displayName || ''}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.displayName} {option.id}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a Timezone"
                  error={!!errors.timeZone}
                  helperText={errors.timeZone && 'Time Zone is required'}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
          name="timeZone"
          control={control}
          rules={{ required: true }}
          defaultValue={null}
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} width={'100%'}>
          <TextField
            {...register('phone', { required: true })}
            fullWidth
            label="Enter Phone Number"
            variant="outlined"
            type="number"
            maxLength={10}
            error={!!errors.phone}
            placeholder="234"
            helperText={errors.phone && 'Phone Number is required'}
          />
          <Controller
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.defaultCurrencyId}>
                <InputLabel id="select">Select Default Currency</InputLabel>
                <Select
                  {...field}
                  labelId="select"
                  id="select"
                  label="Select Default Currency"
                  {...register('defaultCurrencyId', { required: true })}
                  onChange={(event) => {
                    setValue('defaultCurrencyId', event?.target.value);
                  }}
                >
                  {getAdminCurrencyPayload?.data.map((currency) => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.code}
                    </MenuItem>
                  ))}
                  {getAdminCurrencyLoading && <Typography sx={{ textAlign: 'center' }}>Loading</Typography>}
                </Select>
                <FormHelperText>{errors.defaultCurrencyId && 'Default Currency is required'}</FormHelperText>
              </FormControl>
            )}
            name="defaultCurrencyId"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} width={'100%'}>
          <Button
            onClick={() => setAddModal(false)}
            variant="outlined"
            size="large"
            sx={{ width: '100%', color: '#000', bg: '#000', border: 'black 1px solid', '&:hover': { border: 'none' } }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            sx={{ width: '100%' }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default RegisterAdminForm;

RegisterAdminForm.propTypes = {
  isLoading: PropTypes.bool,
  setAddModal: PropTypes.func,
  token: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
