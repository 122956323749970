import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box,
  Stack,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Popover,
  MenuItem,
  Button,
  Card,
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getAdminRoles,
  addNewRole,
  deleteRole,
  getAdminPermissions,
  addPermissionToRole,
  getRolePermissions,
  getAdminUsers,
  getUserRoles,
  removePermissionFromRole,
  getUserId,
  getSearchvalue,
  setValue,
} from '../../../logic/actions/admin';
import Iconify from '../../../components/iconify';
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import CustomModal from '../../../components/modal';
import AddNewRole from './addNewRole';
import SelectPermission from './selectPermission';
import { errorMessage, successMessage } from '../../../logic/actions/notification';
import FilterUsers from './filterUsers';
import TablePulse from '../../../components/tablePulse';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#cccdd9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(0.5, 0),
  textAlign: 'center',
  color: theme.palette.text.white,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: '' },
];

const initialFormValues = {
  roleName: '',
  permissionId: [],
  roleId: '',
  userId: '',
};

const Index = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [formValues, setFormValues] = useState({ payload: { ...initialFormValues } });
  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState('');
  const [isNewRoleModalOpen, setNewRoleModalOpen] = useState(false);
  const [isDeleteRoleModalOpen, setDeleteRoleModalOpen] = useState(false);
  const [isAddPermissionModalOpen, setAddPermissionModalOpen] = useState(false);
  const [isRemovePermissionModalOpen, setRemovePermissionModalOpen] = useState(false);
  const [isViewUserRoleOpen, setViewUserRoleOpen] = useState(false);
  const [isViewRolePermissionModalOpen, setViewRolePermissionsModalOpen] = useState(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const {
    getUserRolesPayload,
    getUserRolesLoading,
    getUserRolesError,
    userIdPayload,
    searchValue,
    getAdminUsersLoading,
    getAdminUsersPayload,
    getAdminRolesPayload,
    getAdminRolesLoading,
    addNewRoleLoading,
    deleteRoleLoading,
    getAdminPermissionsPayload,
    getAdminPermissionsLoading,
    addPermissionToRoleLoading,
    getRolePermissionsLoading,
    getRolePermissionsPayload,
    getRolePermissionsError,
    removePermissionFromRoleLoading,
  } = useSelector((state) => state.adminReducer);

  const closeFilterModal = () => {
    dispatch(setValue(''));
    dispatch(getSearchvalue(''));
    dispatch(getUserId(''));
    setFilterModalOpen(false);
  };

  const removePermissionFromRoleId = () => {
    const payload = {
      roleId: formValues?.payload?.roleId,
      permissionId: formValues?.payload?.permissionId,
    };
    let errorStatus = false;
    if (!payload.permissionId || payload.permissionId.length === 0) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(removePermissionFromRole(payload))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Permission removed successfully'));
            setRemovePermissionModalOpen(false);
            // fetchRolePermissions();
          }
        })
        .catch((error) => {
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
  };
  const fetchUserRole = useCallback(() => {
    let errorStatus = false;
    const isValidated = userIdPayload && searchValue;
    if (!userIdPayload) {
      dispatch(errorMessage('please select user'));
    }
    if (!isValidated) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(getUserRoles(userIdPayload))
        .then(() => {
          dispatch(successMessage('User roles fetched successfully'));
          setFilterModalOpen(false);
          setViewUserRoleOpen(true);
          dispatch(setValue(''));
          dispatch(getSearchvalue(''));
          dispatch(getUserId(''));
        })
        .catch((error) => {
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
  }, [dispatch, searchValue, userIdPayload]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getAdminUsers(srhTxt));
    }, 500),
    []
  );

  useEffect(() => {
    debounceText(searchValue);
  }, [searchValue, debounceText]);
  // when i clear the select field i want the permissions id select to be clear to initial value

  const fetchRoles = useCallback(() => {
    dispatch(getAdminRoles(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data?.roles);
      })
      .catch((error) => {
        dispatch(errorMessage(error?.data.responseMessage));
      });
  }, [dispatch, page, rowsPerPage]);

  const addPermissionToRoleId = () => {
    const payloads = {
      roleId: formValues?.payload?.roleId,
      permissionId: formValues?.payload?.permissionId,
    };

    let errorStatus = false;
    if (!payloads.permissionId || payloads.permissionId.length === 0) {
      errorStatus = true;
      setSubmitStatus(true);
    }

    if (!errorStatus) {
      dispatch(addPermissionToRole(payloads))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            console.log(responseData?.value);
            dispatch(successMessage('Permission added successfully'));
            setAddPermissionModalOpen(false);
            setFormValues({ payload: { ...initialFormValues }, submitted: false });
            fetchRoles();
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
  };

  // Reset submit status when formValues change
  useEffect(() => {
    setSubmitStatus(false);
  }, [formValues]);

  const getAllPermissions = () => {
    dispatch(getAdminPermissions());
  };

  const getRoleIdPermissions = () => {
    const roleId = formValues?.payload?.roleId;
    dispatch(getRolePermissions(roleId))
      .then(() => {})
      .catch((error) => console.error(error));
  };

  const handleDeleteRole = () => {
    const payload = {
      id: selectedId,
      delete: true,
    };
    dispatch(deleteRole(payload))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Role deleted successfully'));
          setDeleteRoleModalOpen(false);
          fetchRoles();
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(errorMessage('You do not have permission to perform this action.'));
        } else {
          dispatch(errorMessage(error?.data.responseMessage));
        }
      });
  };

  const createNewRole = () => {
    const name = formValues?.payload?.roleName;
    let errorStatus = false;
    if (!name) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(addNewRole(name))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Role created successfully'));
            setNewRoleModalOpen(false);
            setFormValues({ payload: { ...initialFormValues }, submitted: false });
            fetchRoles();
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
          } else {
            dispatch(errorMessage(error?.data.responseMessage));
          }
        });
    }
  };

  const handleFormChange = (e, newValue) => {
    if (e) {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
      if (newValue) {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          payload: {
            permissionId: newValue.map((item) => item.id),
            roleId: selectedId,
            userId: newValue ? newValue.id : '',
          },
        }));
      }
    }
  };

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item.id,
        name: item.name,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter((item) =>
      (item.name?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    dispatch(getAdminRoles(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data?.roles);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleOpenMenu = (event, id) => {
    setSelectedId(id);
    setOpen(event.currentTarget);
    setFormValues({
      payload: {
        ...initialFormValues,
        roleId: id,
      },
      submitted: false,
    });
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Musha | Roles </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Roles
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewRoleModalOpen(true);
              }}
            >
              Create Role
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setFilterModalOpen(true);
              }}
            >
              Get User Role
            </Button>
          </Stack>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search Roles...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <></>
                {getAdminRolesLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={3} />
                  </TableBody>
                ) : filteredRows.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell align="left">{row.dateCreated}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row.id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {getAdminRolesPayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getAdminRolesPayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: '220px',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAddPermissionModalOpen(true);
            getAllPermissions();
            handleCloseMenu();
          }}
        >
          <Iconify icon={'mingcute:add-fill'} sx={{ mr: 2 }} />
          Add Permission
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRemovePermissionModalOpen(true);
            getRoleIdPermissions();
            handleCloseMenu();
          }}
        >
          <Iconify icon={'line-md:remove'} sx={{ mr: 2 }} />
          Remove Permission
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            setDeleteRoleModalOpen(true);
            setOpen(false);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete Role
        </MenuItem>
        <MenuItem
          onClick={() => {
            setViewRolePermissionsModalOpen(true);
            getRoleIdPermissions();
            handleCloseMenu();
          }}
        >
          <Iconify icon={'grommet-icons:view'} sx={{ mr: 2 }} />
          View Role Permissions
        </MenuItem>
      </Popover>

      <CustomModal
        isOpen={isNewRoleModalOpen}
        title="Add New Role"
        contentText=""
        content={
          <AddNewRole
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            isClose={isNewRoleModalOpen}
          />
        }
        onClose={() => {
          setNewRoleModalOpen(false);
          setFormValues({ payload: { ...initialFormValues }, submitted: false });
        }}
        creating={addNewRoleLoading}
        disableCreate={addNewRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Create'}
        onCreate={() => {
          createNewRole();
        }}
      />
      <CustomModal
        isOpen={isDeleteRoleModalOpen}
        title="Delete Role"
        content={
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to delete this role?
          </Typography>
        }
        contentText={''}
        onClose={() => {
          setDeleteRoleModalOpen(false);
        }}
        creating={deleteRoleLoading}
        disableCreate={deleteRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Delete'}
        onCreate={() => {
          handleDeleteRole();
        }}
        createBtnColor="error"
      />

      <CustomModal
        isOpen={isAddPermissionModalOpen}
        title="View Available Permissions"
        content={
          <SelectPermission
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            permissions={getAdminPermissionsPayload?.data.permissions || []}
            loadingPermissions={getAdminPermissionsLoading}
            clearOnModalClose={isAddPermissionModalOpen}
          />
        }
        contentText={'Permissions'}
        onClose={() => {
          setAddPermissionModalOpen(false);
        }}
        creating={addPermissionToRoleLoading}
        disableCreate={addPermissionToRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Add'}
        onCreate={() => {
          addPermissionToRoleId();
        }}
        createBtnColor=""
      />
      {/* Remove role from permission */}
      <CustomModal
        isOpen={isRemovePermissionModalOpen}
        title="Remove Role"
        content={
          <SelectPermission
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            permissions={getRolePermissionsPayload?.data.permissions || []}
            loadingPermissions={getRolePermissionsLoading}
            clearOnModalClose={isRemovePermissionModalOpen}
          />
        }
        contentText={'Are you sure you want to Remove this permission?'}
        onClose={() => {
          setRemovePermissionModalOpen(false);
        }}
        creating={removePermissionFromRoleLoading}
        disableCreate={removePermissionFromRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removePermissionFromRoleId();
        }}
        createBtnColor="error"
      />

      <CustomModal
        isOpen={isFilterModalOpen}
        title="Search Users Role"
        content={
          <FilterUsers
            payload={getAdminUsersPayload?.data}
            submitStatus={submitStatus}
            loadingUsers={getAdminUsersLoading}
            formValues={formValues?.payload}
          />
        }
        contentText={''}
        onClose={() => {
          closeFilterModal();
        }}
        creating={getUserRolesLoading}
        disableCreate={getUserRolesLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Get Role'}
        onCreate={() => {
          fetchUserRole();
        }}
        createBtnColor=""
      />

      <CustomModal
        isOpen={isViewRolePermissionModalOpen}
        title="View Role Permissions"
        height="500px"
        content={
          <Box variant="body1" sx={{ textAlign: 'center' }}>
            {getRolePermissionsLoading ? <CircularProgress /> : null}
            {getRolePermissionsPayload?.data.permissions.map((permission) => (
              <Stack variant="body1" key={permission.id} spacing={2}>
                <Item>{permission.name}</Item>
              </Stack>
            ))}
            {getRolePermissionsError ? (
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {getRolePermissionsError?.response?.data.responseMessage}
              </Typography>
            ) : null}
          </Box>
        }
        contentText={'Permissions Available'}
        onClose={() => {
          setViewRolePermissionsModalOpen(false);
        }}
        creating={getUserRolesLoading}
        disableCreate={getUserRolesLoading}
        hideCloseBtn={false}
        hideCreateBtn
        closeText={'Close'}
        createText={'View'}
        onCreate={() => {
          fetchUserRole();
        }}
        createBtnColor=""
      />

      <CustomModal
        isOpen={isViewUserRoleOpen}
        onCreate={() => {}}
        title="View User Role"
        height="500px"
        content={
          <Box variant="body1" sx={{ textAlign: 'center' }}>
            {getUserRolesLoading ? <CircularProgress /> : null}
            {getUserRolesPayload?.data.roles.map((role) => (
              <Stack variant="body1" key={role.id} spacing={2}>
                <Item>{role.name}</Item>
              </Stack>
            ))}
            {getUserRolesError ? (
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {getUserRolesError?.response?.data.responseMessage}
              </Typography>
            ) : null}
          </Box>
        }
        contentText={'User Roles'}
        onClose={() => {
          setViewUserRoleOpen(false);
        }}
        creating={getUserRolesLoading}
        disableCreate={getUserRolesLoading}
        hideCloseBtn={false}
        hideCreateBtn
        closeText={'Close'}
        createText={'View'}
        createBtnColor=""
      />
    </>
  );
};
export default Index;