import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../components/modal';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
import { deletePropertyTypeConfig, getPropertyTypeConfig } from '../../logic/actions/asset';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { UserListHead } from '../../sections/@dashboard/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'User Name', alignRight: false },
  { id: 'propertyTypeName', label: 'Property Type Name', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'maximumPriceUnit', label: 'Maximum Price Unit', alignRight: false },
  { id: 'minimumPriceUnit', label: 'Minimum Price Unit', alignRight: false },
  { id: '' },
];

const PropertyTypeConfig = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  // const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const { propertyTypesConfigPayload, propertyTypesConfigLoading, deletePropertyTypeConfigLoading } = useSelector(
    (state) => state.assetReducer
  );

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        propertyTypeId: item?.propertyTypeId,
        maximumPriceUnit: item?.maximumPriceUnit,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
        minimumPriceUnit: item?.minimumPriceUnit,
        name: item?.userFullName,
        propertyTypeName: item?.propertyTypeName,
      })),
    [rows]
  );

  const getPropertyTypesConfigList = useCallback(() => {
    dispatch(getPropertyTypeConfig())
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [dispatch]);

  useEffect(() => {
    getPropertyTypesConfigList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleOpen = (id) => {
    setRemoveModalOpen(true);
    setSelectedId(id);
  };

  const removePropertyType = () => {
    dispatch(deletePropertyTypeConfig(selectedId))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Property Type removed successfully'));
          setRemoveModalOpen(false);
          getPropertyTypesConfigList();
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response.data.responseMessage));
      });
  };

  return (
    <>
      <Helmet>
        <title>Payouts | Asset</title>
      </Helmet>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table aria-labelledby="tableTitle" size={'medium'}>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={headCells}
                rowCount={format()?.length}
                numSelected={selected?.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <></>
              {propertyTypesConfigLoading ? (
                <TableBody>
                  <TablePulse arrayLength={10} rows={5} />
                </TableBody>
              ) : propertyTypesConfigPayload?.data.length > 0 ? (
                <TableBody>
                  {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                        <TableCell align="left">{row.name}</TableCell>

                        <TableCell align="left">{row.propertyTypeName}</TableCell>

                        <TableCell align="left">{row.dateCreated}</TableCell>

                        <TableCell align="left">{row.maximumPriceUnit}</TableCell>

                        <TableCell align="left">{row.minimumPriceUnit}</TableCell>

                        <TableCell align="right">
                          <Typography
                            onClick={() => handleOpen(row.id)}
                            sx={{ fontSize: '13px', fontWeight: 'medium', color: 'red', cursor: 'pointer' }}
                          >
                            Remove
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <div>No record found in logs</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {propertyTypesConfigPayload?.totalCount ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Scrollbar>
      </Card>

      <CustomModal
        isOpen={isRemoveModalOpen}
        title="Remove Property Type Config"
        content={<div />}
        contentText={'Are you sure you want to Remove this Property type config?'}
        onClose={() => {
          setRemoveModalOpen(false);
        }}
        creating={deletePropertyTypeConfigLoading}
        disableCreate={deletePropertyTypeConfigLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removePropertyType();
        }}
        createBtnColor="error"
      />
    </>
  );
};

export default PropertyTypeConfig;
