/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { approveRejectUser, getUserVerificationDetails } from '../../logic/actions';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import CustomModal from '../../components/modal';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import UserInformation from '../../components/userDetails/userInformation';
import AccountInformation from '../../components/userDetails/accountInformation';
import VerificationInformation from '../../components/userDetails/verificationInformation';

const OnboardingUserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isDocumentModal, setDocumentModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isApprove, setIsApprove] = useState(false);
  const { getUserVerificationDetailsLoading, getUserVerificationDetailsError, getUserVerificationDetailsPayload } =
    useSelector((state) => state.dashboardReducer);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const lastItem = getUserVerificationDetailsPayload?.data[getUserVerificationDetailsPayload?.data.length - 1];
  // Get all data except the last item
  const allButLast = getUserVerificationDetailsPayload?.data ? getUserVerificationDetailsPayload.data.slice(0, -1) : [];

  // Calculate the data for the current page
  const paginatedData = allButLast.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const { approveRejectUserLoading } = useSelector((state) => state.dashboardReducer);

  const getRequest = () => {
    const userId = id;
    dispatch(getUserVerificationDetails(userId))
      .then(() => {})
      .catch((error) => {
        dispatch(errorMessage(error.message));
      });
  };

  const handleConfirm = (formData) => {
    const payload = {
      comment: formData.comment,
      id: lastItem?.id,
      isApproved: isApprove,
    };
    dispatch(approveRejectUser(payload))
      .then(() => {
        dispatch(successMessage('Success'));
        navigate('/user');
        setConfirmModal(false);
        reset();
      })
      .catch((error) => {
        dispatch(errorMessage(error?.data?.responseMessage || 'Something went wrong'));
      });
  };

  useEffect(() => {
    // Fetch data from the server and update the 'user' state
    getRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOpenModal = (isApprove) => {
    setIsApprove(isApprove);
    setConfirmModal(true);
  };

  const handleOpenDocumentModal = (documents) => {
    setDocuments(documents);
    setDocumentModal(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Onboarding Details</title>
      </Helmet>

      <Container>
        {' '}
        <Typography
          color="primary"
          onClick={goBack}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'start',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Iconify icon="ep:arrow-left-bold" />
          Go Back
        </Typography>
        <Stack direction="row" spacing={2} paddingY={2} justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="h4" gutterBottom>
              User Request
            </Typography>
          </Stack>
          {lastItem?.verificationStatus.toLowerCase() !== 'approved' && (
            <Stack direction="row" spacing={2} paddingY={2} justifyContent="end" alignItems="center">
              {getUserVerificationDetailsLoading ? (
                <Skeleton animation="wave" variant="rectangular" width={100} height={40} sx={{ borderRadius: 2 }} />
              ) : (
                <Button
                  size="large"
                  variant="text"
                  style={{
                    borderRadius: 10,
                    color: 'red',
                    fontWeight: '400',
                  }}
                  onClick={() => handleOpenModal(false)}
                >
                  Reject
                </Button>
              )}
              {getUserVerificationDetailsLoading ? (
                <Skeleton animation="wave" variant="rectangular" width={100} height={40} sx={{ borderRadius: 2 }} />
              ) : (
                <Button
                  style={{
                    borderRadius: 10,
                    color: 'white',
                    backgroundColor: 'green',
                    fontWeight: '400',
                  }}
                  size="large"
                  color="inherit"
                  variant="body1"
                  onClick={() => handleOpenModal(true)}
                >
                  Approve
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <Paper sx={{ textAlign: 'center' }}>
          {id || getUserVerificationDetailsError ? (
            getUserVerificationDetailsLoading ? (
              <Typography variant="h6" paragraph padding={5}>
                <CircularProgress color="inherit" />
              </Typography>
            ) : lastItem ? (
              <Stack sx={{ textAlign: 'start', p: 3 }}>
                <UserInformation user={lastItem} />
                <AccountInformation user={lastItem} />
                <VerificationInformation user={lastItem} />

                <Scrollbar>
                  <Typography variant="h4" my={2} gutterBottom>
                    Previous requests
                  </Typography>
                  <Box>
                    <Table sx={{ width: '100%', whiteSpace: 'nowrap' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>User ID</TableCell>
                          <TableCell>Account Type</TableCell>
                          <TableCell>Comment</TableCell>
                          <TableCell>Date Submitted</TableCell>
                          <TableCell>Date Treated</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>{''}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedData && paginatedData?.length > 0 ? (
                          paginatedData?.map((item, index) => (
                            <TableRow hover key={index}>
                              <TableCell>{item?.userId || 'N/A'}</TableCell>
                              <TableCell>{item?.accountType || 'N/A'}</TableCell>
                              <TableCell sx={{ wordBreak: 'break-word' }}>{item?.comment || 'N/A'}</TableCell>
                              <TableCell> {new Date(item?.dateCreated).toLocaleString() || 'N/A'}</TableCell>
                              <TableCell> {new Date(item?.dateTreated).toLocaleString() || 'N/A'}</TableCell>
                              <TableCell>{item?.verificationStatus || 'N/A'}</TableCell>
                              <TableCell
                                onClick={() => {
                                  handleOpenDocumentModal(item.documents);
                                }}
                                sx={{ color: 'blue', cursor: 'pointer' }}
                              >
                                View
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>No previous request yet</TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={allButLast?.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </Scrollbar>
              </Stack>
            ) : (
              <Stack sx={{ textAlign: 'center', padding: 5 }}>{'Error loading user previous requests.'}</Stack>
            )
          ) : (
            <Stack sx={{ textAlign: 'center', padding: 5 }}>No ID provided</Stack>
          )}
        </Paper>
      </Container>
      <CustomModal
        isOpen={isConfirmModal}
        title={isApprove ? 'Approve User' : 'Reject User'}
        content={
          <>
            <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
              Are you sure you want to {isApprove ? 'approve' : 'reject'} this user?
            </Typography>
            <form onSubmit={handleSubmit(handleConfirm)}>
              <Controller
                name="comment"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Comment"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.comment}
                    helperText={errors.comment ? errors.comment.message : ''}
                  />
                )}
              />
              <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                <Button
                  onClick={() => {
                    setConfirmModal(false);
                    reset();
                  }}
                  size="large"
                  fullWidth
                  disabled={approveRejectUserLoading}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'black',
                    fontWeight: '400',
                    padding: 2,
                    border: '1px solid #EAF0F4',
                    borderRadius: 2,
                  }}
                >
                  Close
                </Button>
                <LoadingButton
                  loading={approveRejectUserLoading}
                  variant="contained"
                  size="large"
                  type="submit"
                  color={!isApprove ? 'error' : 'primary'}
                  fullWidth
                  disabled={approveRejectUserLoading}
                  sx={{ textTransform: 'capitalize', fontWeight: '400', padding: 2, borderRadius: 2 }}
                >
                  {isApprove ? 'Approve' : 'Reject'}
                </LoadingButton>
              </Stack>
            </form>
          </>
        }
        onClose={() => {
          setConfirmModal(false);
          reset();
        }}
        creating={false}
        onCreate={() => {}}
        disableCreate={approveRejectUserLoading}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={''}
        onCreate={() => {}}
        createBtnColor=""
      />
      <CustomModal
        isOpen={isDocumentModal}
        title={'Documents'}
        content={
          <Stack sx={{ display: 'flex', textAlign: 'center', gap: 2, maxHeight: '110px', width: '100%' }}>
            {documents.map((item, index) => (
              <Box
                key={index}
                width={'100%'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
                gap={4}
                p={2}
                borderRadius={2}
                sx={{ border: '1px solid #EAF0F4' }}
              >
                <Typography variant="subtitle1">{item?.name}</Typography>
                <a href={item?.path} target="_blank" rel="noreferrer">
                  View
                </a>
              </Box>
            ))}
          </Stack>
        }
        onClose={() => {
          setDocumentModal(false);
        }}
        creating={false}
        onCreate={() => {}}
        disableCreate={false}
        hideCreateBtn
        closeText={'Close'}
        createText={''}
        onCreate={() => {}}
        createBtnColor=""
      />
    </>
  );
};

export default OnboardingUserDetails;
