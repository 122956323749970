import { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Card,
  Container,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  Popover,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { debounce } from 'lodash';
import { cancelSingleOrder, getOrders } from '../../logic/actions/admin';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { UserListHead } from '../../sections/@dashboard/user';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
import CustomModal from '../../components/modal';
import Iconify from '../../components/iconify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    borderRadius: '0px 6px 6px 0px',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'paymentReference', label: 'Payment Reference', alignRight: false },
  { id: 'assetId', label: 'Asset Id', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const Index = () => {
  const router = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const [id, setId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(''); // Track the selected values
  const [open, setOpen] = useState(null);
  const [selectedRow] = useState(null);
  const { getOrdersLoading, getOrdersPayload, cancelOrderLoading } = useSelector((state) => state.adminReducer);

  const fieldNames = ['amount', 'paymentReference', 'units', 'Email', 'status', 'dateCreated'];

  const formatLabel = (label) => {
    // Capitalize the first letter
    label = label.charAt(0).toUpperCase() + label.slice(1);

    // Insert a space before each capital letter
    label = label.replace(/([A-Z])/g, ' $1');

    return label;
  };

  // Create an array of objects with 'value' and 'label'
  const fieldObjects = fieldNames.map((fieldName) => ({
    value: fieldName,
    label: formatLabel(fieldName), // Capitalize the first letter
  }));

  const options = fieldObjects || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set the formatted value
    setValue(value);
  };

  const format = useCallback(
    () =>
      rows.map((item) => ({
        id: item?.id,
        userId: item?.userId,
        assetId: item?.assetId,
        paymentReference: item?.paymentReference,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
        amount: item?.amount,
        name: item?.name,
        asset: item?.assetTitle,
        status: item?.status,
      })),
    [rows]
  );

  const fetchOrders = useCallback(() => {
    dispatch(getOrders(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data.orders);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 403) {
          dispatch(errorMessage('You do not have permission to perform this action.'));
        } else {
          dispatch(errorMessage(error?.response.data.responseMessage));
        }
      });
  }, [dispatch, page, rowsPerPage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(async ({ value, filterName }) => {
      try {
        // If value is an empty string, set it to null or remove it from the payload
        // const payload = value !== '' ? { [value]: filterName } : { filterName };

        const responseData = await dispatch(getOrders(page, rowsPerPage, value, filterName));
        const data = responseData?.value.data;
        setRows(data.orders);
        setTotalResults(responseData?.value?.totalCount || 0);
      } catch (error) {
        console.error(error);
      }
    }, 1000),
    [dispatch, rowsPerPage]
  );

  useEffect(() => {
    if (filterName !== '' && value) {
      debounceSearch({ value, filterName });
    } else {
      fetchOrders();
    }
  }, [debounceSearch, fetchOrders, filterName, value]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleViewMore = (id) => {
    setId(id);
    setModalOpen(true);
  };

  const onCancel = () => {
    dispatch(cancelSingleOrder(id))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Order cancel successfully'));
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.data.responseMessage));
      });
  };

  return (
    <>
      <Helmet>
        <title>Musha | Orders</title>
      </Helmet>
      <Container>
        <Stack>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="start" gap="2" my={3} ml={3}>
            <FormControl sx={{ width: 230 }}>
              <Select
                displayEmpty
                sx={{
                  borderRadius: '6px 0px 0px 6px',
                }}
                value={value}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Select an option</span>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options.map((name, index) => (
                  <MenuItem key={index} value={name.value}>
                    {name.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder={'Search transactions...'}
              disabled={!value}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={format()?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <></>
                {getOrdersLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={5} />
                  </TableBody>
                ) : rows?.length > 0 ? (
                  <TableBody>
                    {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.paymentReference ?? 'N/A'}</TableCell>
                          <TableCell>{row.assetId ?? 'N/A'}</TableCell>
                          <TableCell align="left">{row.dateCreated}</TableCell>

                          <TableCell align="left">₦{row.amount || 0}</TableCell>

                          <TableCell align="left">{row.status}</TableCell>

                          <TableCell align="right">
                            <Typography
                              onClick={() => router(`/asset/${row?.assetId}`)}
                              sx={{ fontSize: '13px', fontWeight: 'medium', color: 'blue', cursor: 'pointer' }}
                            >
                              View more
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={() => setOpen(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: '180px',
                  zIndex: 1,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  router(`/asset/${selectedRow?.assetId}`);
                  setOpen(null);
                }}
              >
                View asset
              </MenuItem>
              {(selectedRow?.status === 'AwaitingPayment' || selectedRow?.status === 'Checkout') && (
                <MenuItem>
                  <Typography
                    onClick={() => handleViewMore(selectedRow.id)}
                    sx={{ fontSize: '13px', fontWeight: 'medium', color: 'red', cursor: 'pointer' }}
                  >
                    Cancel order
                  </Typography>
                </MenuItem>
              )}
            </Popover>

            {getOrdersPayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalResults}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>

      <CustomModal
        isOpen={modalOpen}
        title="Cancel Order"
        contentText={'Are you sure you want to Cancel this Order?'}
        onClose={() => {
          setModalOpen(false);
        }}
        creating={cancelOrderLoading}
        disableCreate={cancelOrderLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Cancel'}
        onCreate={() => {
          onCancel();
        }}
        createBtnColor="error"
      />
    </>
  );
};

export default Index;
