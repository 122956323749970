import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const AddNewRole = ({ onChange, submitStatus, payload, isClose }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        onChange(e);
    };

    useEffect(() => {
        if (isClose) {
            setInputValue('');
        }
    }, [isClose]);

    return (
        <Box>
            <TextField
                onChange={handleInputChange}
                id="typeName"
                name="typeName"
                fullWidth
                label="Enter Property type Name"
                variant="outlined"
                value={inputValue} // Bind the input value to the state
                error={submitStatus && !payload?.typeName}
                helperText={submitStatus && !payload?.typeName ? 'Property type Name is required' : ''}
            />
        </Box>
    );
};

export default AddNewRole;

AddNewRole.propTypes = {
    onChange: PropTypes.func.isRequired,
    submitStatus: PropTypes.bool.isRequired,
    payload: PropTypes.object.isRequired,
    isClose: PropTypes.bool.isRequired,
};
