import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify/Iconify';
import { getSearchvalue, getUserId, setValue } from '../../../logic/actions/admin';

export default function FilterUsers({ payload, submitStatus, loadingUsers }) {
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const { searchValue, value } = useSelector((state) => state.adminReducer);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(value || ''); // Initialize the inputValue state with value or an empty string
    }, [value]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        dispatch(getSearchvalue(newValue));
        dispatch(setValue(newValue));
    };

    const handleClear = useCallback(() => {
        dispatch(setValue(''));
        dispatch(getSearchvalue(''));
        dispatch(getUserId(''));
    }, [dispatch]);

    const handleUserClick = (user) => {
        setDropdownOpen(false);
        dispatch(getUserId(user.id));
        if (user.firstname && user.lastname) {
            const name = `${user.firstname} ${user.lastname}`;
            dispatch(setValue(name)); // Update the input value with the user's name
            dispatch(getSearchvalue(user.firstname));
        } else {
            dispatch(setValue(user.companyName)); // Update the input value with the company name
            dispatch(getSearchvalue(user.companyName));
        }
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Box component="form" noValidate autoComplete="on">
                <TextField
                    error={submitStatus && !searchValue}
                    sx={{
                        width: '100%'
                    }}
                    id="searchValue"
                    value={inputValue}
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end"
                                ref={dropdownRef}
                            >
                                <Iconify
                                    icon={'line-md:remove'}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handleClear();
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    name="search"
                    onChange={(newValue) => {
                        handleChange(newValue);
                    }}
                    onFocus={() => setDropdownOpen(true)}
                    inputRef={inputRef}
                    helperText={submitStatus && !searchValue ? 'Please enter a search value' : ''}
                />
            </Box>
            {dropdownOpen && (
                <Box
                    ref={dropdownRef}
                    sx={{
                        mt: 2,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                        bgcolor: 'white',
                        border: '1px solid #E0E0E0',
                        width: '100%',
                        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                        borderRadius: '10px',
                    }}
                >
                    {loadingUsers ? (
                        <Box sx={{ p: 3 }}>
                            <CircularProgress color="inherit" size={45} />
                        </Box>
                    ) : payload?.length > 0 ? (
                        <>
                            <List sx={{
                                width: '100%', height: 200, cursor: 'pointer', overflow: 'auto',
                            }}>
                                {payload?.map((user) => (
                                    <ListItem
                                        key={user.id}
                                        disableGutters
                                        onClick={() => handleUserClick(user)} // Pass the user to the click handler
                                        sx={{
                                            "&:hover": {
                                                background: '#c0c2c0',
                                                color: '#141414'
                                            },
                                            pl: 2,
                                        }}
                                        secondaryAction={
                                            <Iconify icon={'octicon:search-16'} sx={{ cursor: 'pointer', mr: 2 }} />
                                        }
                                    >
                                        <ListItemText primary={user.email} />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <Box sx={{ color: 'text.secondary', p: 4 }}>{payload?.length} user(s) found</Box>
                    )}
                </Box>
            )}
        </>
    );
}

FilterUsers.propTypes = {
    payload: PropTypes.array,
    submitStatus: PropTypes.bool.isRequired,
    loadingUsers: PropTypes.bool,
};
