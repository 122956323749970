const actionTypes = {
  LOGIN: 'LOGIN',
  GET_USERS: 'GET_USERS',
  GET_SINGLE_USER: 'GET_SINGLE_USER',
  BAN_USER: 'BAN_USER',
  ACTIVATE_USER: 'ACTIVATE_USER',
  DELETE_USER: 'DELETE_USER',
  EDIT_USER: 'EDIT_USER',
  GET_ONBOARDING_REQUESTS: 'GET_ONBOARDING_REQUESTS',
  GET_VERIFICATION_DETAILS: 'GET_VERIFICATION_DETAILS',
  GET_DOWNLOAD_VERIFY_DOCUMENT: 'GET_DOWNLOAD_VERIFY_DOCUMENT',
  APPROVE_REJECT_USER: 'APPROVE_REJECT_USER',
  GET_USER_ASSETS: 'GET_USER_ASSETS',
  GET_USER_VERIFICATION_DETAILS: 'GET_USER_VERIFICATION_DETAILS',
  GET_USER_TRANSACTION_HISTORY: 'GET_USER_TRANSACTION_HISTORY',
  // ROLES
  GET_ADMIN_ROLES: 'GET_ADMIN_ROLES',
  GET_ADMIN_PERMISSIONS: 'GET_ADMIN_PERMISSIONS',
  ADD_PERMISSION_TO_ROLE: 'ADD_PERMISSION_TO_ROLE',
  REMOVE_PERMISSION_FROM_ROLE: 'REMOVE_PERMISSION_FROM_ROLE',
  GET_ROLE_PERMISSIONS: 'GET_ROLE_PERMISSIONS',
  ADD_NEW_ROLE: 'ADD_NEW_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  GET_ADMIN_USERS: 'GET_ADMIN_USERS',
  GET_USER_ROLES: 'GET_USER_ROLES',
  GET_ROLE_PERMISSIONS_BY_ID: 'GET_ROLE_PERMISSIONS_BY_ID',
  GET_SEARCH_VALUE: 'GET_SEARCH_VALUE',
  GET_USER_ID: 'GET_USER_ID',
  SET_VALUE: 'SET_VALUE',
  ADD_USER_TO_ROLE: 'ADD_USER_TO_ROLE',
  REMOVE_USER_FROM_ROLE: 'REMOVE_USER_FROM_ROLE',
  GET_USERS_FROM_ROLE: 'GET_USERS_FROM_ROLE',

  // Admin
  GET_ADMINS: 'GET_ADMINS',
  REGISTER_ADMIN: 'REGISTER_ADMIN',
  REGISTER_ADMIN_PAYLOAD: 'REGISTER_ADMIN_PAYLOAD',
  GET_ADMIN_CURRENCY: 'GET_ADMIN_CURRENCY',
  GET_ADMIN_TIMEZONE: 'GET_ADMIN_TIMEZONE',
  GET_ADMIN_DASHBOARD_INFO: 'GET_ADMIN_DASHBOARD_INFO',
  GET_ASSET_ORDER_DETAILS: 'GET_ASSET_ORDER_DETAILS',
  GET_SETTLEMENTS: 'GET_SETTLEMENTS',

  // Asset
  GET_ASSETS: 'GET_ASSETS',
  SEARCH_ASSETS: 'SEARCH_ASSETS',
  GET_ASSET_DETAILS: 'GET_ASSET_DETAILS',
  TOGGLE_ASSET_STATUS: 'TOGGLE_ASSET_STATUS',
  INITIATE_PAYOUT: 'INITIATE_PAYOUT',
  TAB_CURRENT_INDEX: 'TAB_CURRENT_INDEX',
  GET_PROPERTY_TYPES: 'GET_PROPERTY_TYPES',
  ADD_PROPERTY_TYPES: 'ADD_PROPERTY_TYPES',
  DELETE_PROPERTY_TYPE: 'DELETE_PROPERTY_TYPE',
  GET_PROPERTY_TYPES_CONFIG: 'GET_PROPERTY_TYPES_CONFIG',
  ADD_PROPERTY_TYPE_CONFIG: 'ADD_PROPERTY_TYPE_CONFIG',
  ATTACH_MORATORIUM_TO_PROPERTY_TYPES: 'ATTACH_MORATORIUM_TO_PROPERTY_TYPES',
  GET_PROPERTY_TYPE_MORATORIUM: 'GET_PROPERTY_TYPE_MORATORIUM',
  DELETE_PROPERTY_TYPE_MORATORIUM: 'DELETE_PROPERTY_TYPE_MORATORIUM',
  DELETE_PROPERTY_TYPE_CONFIG: 'DELETE_PROPERTY_TYPE_CONFIG',
  GET_MORATORIUM: 'GET_MORATORIUM',
  DELETE_MORATORIUM: 'DELETE_MORATORIUM',
  ADD_MORATORIUM: 'ADD_MORATORIUM',
  GET_COUNTRY: 'GET_COUNTRY',
  GET_STATE: 'GET_STATE',
  GET_PRICE_HISTRORY: 'GET_PRICE_HISTRORY',
  MODIFY_ASSET_HISTORY: 'MODIFY_ASSET_HISTORY',

  // Orders
  GET_ORDERS: 'GET_ORDERS',
  CANCEL_ORDER_BY_ID: 'CANCEL_ORDER_BY_ID',
  GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
  CREATE_EXCHANGE_RATE: 'CREATE_EXCHANGE_RATE',
  GET_FEE_CONFIG: 'GET_FEE_CONFIG',
  CREATE_FEE_CONFIG: 'CREATE_FEE_CONFIG',
  GET_PAYMENT_PROCESSOR: 'GET_PAYMENT_PROCESSOR',

  // Payout
  GET_ALL_PAYOUTS: 'GET_ALL_PAYOUTS',
  GET_PAYOUT_DETAILS_BY_ID: 'GET_PAYOUT_DETAILS_BY_ID',
  APPROVE_PAYOUT: 'APPROVE_PAYOUT',

  // WebHooks
  GET_WEBHOOKS: 'GET_WEBHOOKS',
  RE_TRIGGER_WEBHOOK: 'RE_TRIGGER_WEBHOOK',
};

export default actionTypes;
