/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import { Paper, Typography, Container, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../components/userDetails/header';
import { getSingleUser } from '../../logic/actions';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import UserInformation from '../../components/userDetails/userInformation';
import AccountInformation from '../../components/userDetails/accountInformation';
import TransactionInformation from '../../components/userDetails/transactionHistory';
import AssetInformation from '../../components/userDetails/assets';

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { getSingleUserLoading, getSingleUserError } = useSelector((state) => state.dashboardReducer);

  useEffect(() => {
    // Fetch data from the server and update the 'user' state
    const userId = id;
    dispatch(getSingleUser(userId))
      .then((responseData) => {
        const user = responseData?.value.data.data;
        setData(user);
      })
      .catch(() => {
        // dispatch(errorMessage(error.message));
      });
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>User Details</title>
      </Helmet>

      <Container>
        <Header detailsLoading={getSingleUserLoading} data={data} />
        <Scrollbar>
          <Paper sx={{ textAlign: 'center' }}>
            {id || getSingleUserError ? (
              getSingleUserLoading ? (
                <Typography variant="h6" paragraph padding={5}>
                  <CircularProgress color="inherit" />
                </Typography>
              ) : data ? (
                <Stack sx={{ textAlign: 'start', p: 3 }}>
                  <UserInformation user={data} />
                  <AccountInformation user={data} />
                  <TransactionInformation />
                  <AssetInformation />
                </Stack>
              ) : (
                <Stack sx={{ textAlign: 'center', padding: 5 }}>{'Error loading user details.'}</Stack>
              )
            ) : (
              <Stack sx={{ textAlign: 'center', padding: 5 }}>No ID provided</Stack>
            )}
          </Paper>
        </Scrollbar>
      </Container>
    </>
  );
};

export default UserDetails;
