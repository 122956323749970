import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
// components
import Iconify from '../../../components/iconify';
// utility
import useAuthToken from '../../../hooks/useAuthToken';
import { signin } from '../../../logic/actions';
import { errorMessage } from '../../../logic/actions/notification';

export function LoginForm() {

  const dispatch = useDispatch();
  const {
    loginLoading,
  } = useSelector(state => state.dashboardReducer);

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const { saveToken, } = useAuthToken();

  const submit = () => {
    const requestBody = { username: email, password, pushNotificationId: 'ADMIN-APP' };
    dispatch(signin(requestBody))
      .then((responseData) => {
        const data = responseData?.value.data.data;
        saveToken(data?.jwtToken, data?.jwtTokenExpiry);
        localStorage.setItem('email', data?.email);

        navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        dispatch(errorMessage(error.data?.responseMessage || error.message));
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(event) => setEmail(event.target.value)} />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        loading={loginLoading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={submit}
      >
        Login
      </LoadingButton>
    </>
  );
}
