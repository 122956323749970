/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Grid from '@mui/material/Grid';


const CompanyInformation = ({ asset }) => (
    <ThemeProvider
        theme={{
            spacing: (factor) => `${8 * factor}px`,
            palette: {
                grey: {
                    300: "#ccc",
                },
            },
        }}
    >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Company Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {`${asset?.user?.firstname || ""} ${asset?.user?.lastname || ""}`.trim() || asset?.user?.companyName || "Not Available"}

                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Address
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {asset?.companyAddresses || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Phone
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {asset?.user.phone || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Country
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {asset?.country || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Email
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {asset?.user.email || "N/A"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                    Date Created
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {new Date(asset?.user.dateCreated).toLocaleString() || "N/A"}
                </Typography>
            </Grid>
        </Grid>
    </ThemeProvider>
)

CompanyInformation.propTypes = {
    asset: PropTypes.shape({

    }).isRequired,
};

export default CompanyInformation;
