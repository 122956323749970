import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function CustomModal({
  isOpen,
  content,
  title,
  onClose,
  hideCloseBtn,
  closeText,
  hideCreateBtn,
  createText,
  creating,
  disableCreate,
  onCreate,
  contentText,
  createBtnColor,
  height,
}) {
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  React.useEffect(() => {
    setLoading(creating);
  }, [creating]);

  React.useEffect(() => {
    setIsDisabled(disableCreate);
  }, [disableCreate]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      sx={{
        height: { height },
        margin: 'auto',
        width: '100%',
        maxWidth: createBtnColor === 'error' ? '500px' : '600px',
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="modal-modal-title" variant="h6" component="h2">
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingY: '0' }}>
        <DialogContentText sx={{ mt: 1 }} textAlign={'start'} padding="0">
          {contentText}
        </DialogContentText>
        {content && <div style={{ marginTop: '10px', position: 'relative', width: '100%' }}> {content}</div>}
      </DialogContent>
      {hideCreateBtn && hideCloseBtn ? null : (
        <DialogActions
          dialogactions="true"
          sx={{ my: 2, mx: 2 }}
          direction="row"
          justifycontent="space-between"
          alignitems="center"
          spacing={2}
        >
          {!hideCloseBtn && (
            <Button
              size="large"
              onClick={onClose}
              fullWidth
              sx={{
                textTransform: 'capitalize',
                padding: 1.5,
                border: '1px solid #EAF0F4',
                borderRadius: 2,
                fontWeight: '400',
              }}
            >
              {closeText}
            </Button>
          )}
          {!hideCreateBtn && (
            <LoadingButton
              loading={loading}
              size="large"
              color={createBtnColor ? 'error' : 'primary'}
              fullWidth
              variant="contained"
              sx={{
                textTransform: 'capitalize',
                padding: 3,
                border: '1px solid #EAF0F4',
                borderRadius: 2,
                fontWeight: '400',
                width: '100%',
              }}
              onClick={() => {
                onCreate();
              }}
              disabled={isDisabled}
            >
              {createText}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  content: PropTypes.element,
  onCreate: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  disableCreate: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  hideCreateBtn: PropTypes.bool,
  createText: PropTypes.string.isRequired,
  closeText: PropTypes.string.isRequired,
  createBtnColor: PropTypes.string,
  height: PropTypes.string,
  // fullWidth: PropTypes.bool,
};

export default CustomModal;
