/* eslint-disable react/prop-types */
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Link, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <Stack direction="column" spacing={2} alignItems="start" marginBottom={2}>
      <Link
        color="primary"
        href="/user"
        sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'start', textDecoration: 'none' }}
      >
        <Iconify icon="ep:arrow-left-bold" />
        Go Back
      </Link>
      <Stack
        width="100%"
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography variant="h4">User Information</Typography>
        <Button
          style={{
            borderRadius: 10,
            color: 'white',
            backgroundColor: 'green',
            fontWeight: '400',
          }}
          size="large"
          color="inherit"
          variant="body1"
          onClick={() => navigate(`/onboarding-request/${id}`)}
        >
          View verification details
        </Button>
      </Stack>
    </Stack>
  );
};

export default UserDetails;