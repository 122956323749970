/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardActions, Divider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Scrollbar from '../scrollbar/Scrollbar';
import { getPriceHistory } from '../../logic/actions/admin';
import TablePulse from '../tablePulse';

// eslint-disable-next-line arrow-body-style
const AssetPriceHistory = ({ id }) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { priceHistoryLoading } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    // Fetch data from the server and update the 'user' state
    dispatch(getPriceHistory(id))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [id, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Scrollbar sx={{ flexGrow: 1 }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Asset ID</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Current Price</TableCell>
                <TableCell>Created by</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceHistoryLoading ? (
                <TablePulse arrayLength={10} rows={5} />
              ) : rows && rows.length > 0 ? (
                rowsPerPage > 0 ? (
                  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((price) => (
                    <TableRow hover key={price.id}>
                      <TableCell>{price.assetId}</TableCell>
                      <TableCell>{price.price}</TableCell>
                      <TableCell>{price.isCurrentPrice === true ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{price.createdBy}</TableCell>
                      <TableCell>{new Date(price.dateCreated).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell /> <TableCell />
                    <TableCell colSpan={4}>No price history yet</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow hover>
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={4}>No price history yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {totalResults ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={totalResults}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                'aria-label': 'rows per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </CardActions>
    </>
  );
};

export default AssetPriceHistory;
