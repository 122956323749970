import { createAction } from "redux-promise-middleware-actions";

export const notificationType = {
    ERROR_NOTIFICATION: "ERROR_NOTIFICATION",
    SUCCESS_NOTIFICATION: "SUCCESS_NOTIFICATION",
    WARNING_NOTIFICATION: "WARNING_NOTIFICATION",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
};

export const successMessage = createAction(notificationType.SUCCESS_NOTIFICATION, (message) => message);
export const errorMessage = createAction(notificationType.ERROR_NOTIFICATION, (message) => message);
export const warningMessage = createAction(notificationType.WARNING_NOTIFICATION, (message) => message);
export const closeMessage = createAction(notificationType.CLOSE_NOTIFICATION);
