// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Roles Management',
    path: '/roles',
    icon: icon('ic_disabled'),
    children: [
      {
        title: 'Roles',
        path: '/roles',
        icon: <Iconify icon="material-symbols:bookmark-manager" sx={{ m: 0 }} />,
      },
      {
        title: 'Permissions',
        path: '/permissions',
        icon: icon('ic_lock'),
      },
      {
        title: 'User Role',
        path: '/user-role',
        icon: <Iconify icon="carbon:user-role" sx={{ m: 0 }} />,
      },
    ],
  },
  {
    title: 'Admin',
    path: '/admin',
    icon: <Iconify icon="material-symbols:admin-panel-settings" sx={{ m: 0 }} />,
  },

  {
    title: 'Assets Management',
    path: '/asset',
    icon: <Iconify icon="icon-park-twotone:category-management" sx={{ m: 0 }} />,
    children: [
      {
        title: 'Assets',
        path: '/asset',
        icon: <Iconify icon="ri:secure-payment-fill" sx={{ m: 0 }} />,
      },
      {
        title: 'Asset Configuration',
        path: '/asset-configuration',
        icon: <Iconify icon="icon-park-outline:setting-config" sx={{ m: 0 }} />,
      },
      // {
      //   title: 'Payout',
      //   path: '/payout',
      //   icon: <Iconify icon="game-icons:pay-money" sx={{ m: 0 }} />,
      // },
      {
        title: 'Orders',
        path: '/orders',
        icon: <Iconify icon="material-symbols:draft-orders-outline" sx={{ m: 0 }} />,
      },
      {
        title: 'Exchange Rate',
        path: '/exchange-rate',
        icon: <Iconify icon="mdi:exchange" sx={{ m: 0 }} />,
      },
      {
        title: 'Fee Config',
        path: '/fee-config',
        icon: <Iconify icon="flat-color-icons:data-configuration" sx={{ m: 0 }} />,
      },
      {
        title: 'Payment Processor Config',
        path: '/payment-processor-config',
        icon: <Iconify icon="mdi:recurring-payment" sx={{ m: 0 }} />,
      },
      // {
      //   title: 'Asset Appreciation',
      //   path: '/asset-appreciation',
      //   icon: <Iconify icon="fluent:web-asset-24-regular" sx={{ m: 0 }} />,
      // },
    ],
  },
  {
    title: 'Web Hooks',
    path: '/web-hooks',
    icon: <Iconify icon="carbon:cics-transaction-server-zos" sx={{ m: 0 }} />,
  },
  {
    title: 'Settlements',
    path: '/settlements',
    icon: <Iconify icon="fluent:web-asset-24-regular" sx={{ m: 0 }} />,
  },
];

export default navConfig;
