import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Card,
  Container,
  Popover,
  MenuItem,
} from '@mui/material';
import {
  getAdminRoles,
  getAdminUsers,
  addUserToRole,
  removeUserFromRole,
  getUsersFromRoles,
  getUserId,
  getSearchvalue,
  setValue,
} from '../../../logic/actions/admin';
import Iconify from '../../../components/iconify';
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import CustomModal from '../../../components/modal';
import { errorMessage, successMessage } from '../../../logic/actions/notification';
import FilterUsers from '../Roles/filterUsers';
import FormSelect from './formSelect';
import TablePulse from '../../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: '' },
];

const initialFormValues = {
  roleName: '',
  roleId: '',
  userId: '',
};

const initialRemoveValues = {
  roleName: '',
  roleId: '',
  userId: '',
};

const Index = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [formValues, setFormValues] = useState({ payload: { ...initialFormValues } });
  const [removeValues, setRemoveValues] = useState({
    payload: {
      ...initialRemoveValues,
    },
  });
  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState('');
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setRemoveUserModalOpen] = useState(false);

  const {
    searchValue,
    userIdPayload,
    getAdminRolesPayload,
    getAdminUsersLoading,
    getAdminUsersPayload,
    getAdminRolesLoading,
    addUserToRoleLoading,
    removeUserFromRoleLoading,
    getUserFromRoleLoading,
    getUserFromRolePayload,
  } = useSelector((state) => state.adminReducer);

  const handleViewUsers = () => {
    navigate(`/user-role/${selectedId}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const getUsersFromRoleId = () => {
    const payload = {
      roleId: selectedId,
      pendingRequest: true,
    };
    dispatch(getUsersFromRoles(page, rowsPerPage, payload));
  };

  useEffect(() => {
    if (selectedId) {
      getUsersFromRoleId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const addUser = useCallback(() => {
    let errorStatus = false;
    const isValidated = userIdPayload && searchValue;
    if (!userIdPayload) {
      dispatch(errorMessage('please select user'));
    }
    if (!isValidated) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    const payload = {
      userId: userIdPayload,
      roleId: selectedId,
    };
    if (!errorStatus) {
      dispatch(addUserToRole(payload))
        .then(() => {
          dispatch(successMessage('User is added to role successfully'));
          setAddUserModalOpen(false);
          dispatch(setValue(''));
          dispatch(getSearchvalue(''));
          dispatch(getUserId(''));
          getUsersFromRoleId();
        })
        .catch((error) => {
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchValue, selectedId, userIdPayload]);

  const removeUserFromRoleId = () => {
    const payload = {
      roleId: removeValues?.payload?.roleId,
      userId: removeValues?.payload?.userId,
    };
    let errorStatus = false;
    if (!payload.userId || payload.userId.length === 0) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(removeUserFromRole(payload))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('User removed successfully'));
            setRemoveUserModalOpen(false);
            getUsersFromRoleId();
          }
        })
        .catch((error) => {
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getAdminUsers(srhTxt));
    }, 500),
    []
  );

  useEffect(() => {
    debounceText(searchValue);
  }, [searchValue, debounceText]);

  const handleFormChange = (e, newValue) => {
    if (e) {
      const { name, value } = e.target;
      setFormValues((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
      if (newValue) {
        setFormValues((prev) => ({ ...prev, payload: { ...prev.payload, userId: newValue?.id } }));
        setRemoveValues((prev) => ({ ...prev, payload: { ...prev.payload, userId: newValue?.id } }));
      }
    }
  };

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item.id,
        name: item.name,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter((item) =>
      (item.name?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    dispatch(getAdminRoles(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data?.roles);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleOpenMenu = (event, id) => {
    setSelectedId(id);
    setOpen(event.currentTarget);
    setFormValues({
      payload: {
        ...initialFormValues,
        roleId: id,
      },
      submitted: false,
    });
    setRemoveValues({
      payload: {
        ...initialFormValues,
        roleId: id,
      },
    });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Musha | User Role </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users in Roles
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search user...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                {getAdminRolesLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={3} />
                  </TableBody>
                ) : filteredRows.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell align="left">{row.dateCreated}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row.id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3, width: '100%' }} fullWidth>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {getAdminRolesPayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getAdminRolesPayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: '220px',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAddUserModalOpen(true);
            setOpen(false);
          }}
        >
          <Iconify icon={'mingcute:add-fill'} sx={{ mr: 2 }} />
          Add User to role
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRemoveUserModalOpen(true);
            setOpen(false);
          }}
        >
          <Iconify icon={'line-md:remove'} sx={{ mr: 2 }} />
          Remove user from role
        </MenuItem>
        <MenuItem
          onClick={() => {
            getUsersFromRoleId();
          }}
        >
          <Iconify icon={'grommet-icons:view'} sx={{ mr: 2 }} />
          <Typography
            onClick={handleViewUsers}
            sx={{ fontSize: '13px', fontWeight: 'medium', color: 'blue', cursor: 'pointer' }}
          >
            Views users in role
          </Typography>
        </MenuItem>
      </Popover>

      {/* add user to role */}
      <CustomModal
        isOpen={isAddUserModalOpen}
        title="Add User to Role"
        content={
          <FilterUsers
            payload={getAdminUsersPayload?.data}
            submitStatus={submitStatus}
            loadingUsers={getAdminUsersLoading}
            formValues={formValues?.payload}
          />
        }
        contentText={'Add'}
        onClose={() => {
          dispatch(setValue(''));
          dispatch(getSearchvalue(''));
          dispatch(getUserId(''));
          setAddUserModalOpen(false);
        }}
        creating={addUserToRoleLoading}
        disableCreate={addUserToRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Add'}
        onCreate={() => {
          addUser();
        }}
        createBtnColor=""
      />
      {/* Remove user from Role */}
      <CustomModal
        isOpen={isRemoveUserModalOpen}
        title="Remove User from Role"
        content={
          <FormSelect
            payload={removeValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            data={getUserFromRolePayload?.data || []}
            isloading={getUserFromRoleLoading}
            clearOnModalClose={isRemoveUserModalOpen}
          />
        }
        contentText={'Are you sure you want to Remove this User?'}
        onClose={() => {
          setRemoveUserModalOpen(false);
        }}
        creating={removeUserFromRoleLoading}
        disableCreate={removeUserFromRoleLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removeUserFromRoleId();
        }}
        createBtnColor="error"
      />
    </>
  );
};
export default Index;