import { createAsyncAction } from 'redux-promise-middleware-actions';
import { Get, Post, Delete } from '../../apiServices/apiHandler.service';
import actionTypes from './types';

const API_URL = `${process.env.REACT_APP_URL}`;

export const getAssets = createAsyncAction(
  actionTypes.GET_ASSETS,
  async (pageNumber = 1, pageSize = 10, PendingRequest = false) => {
    const apiUrl = `${API_URL}Asset`;

    const response = await Get(
      `${apiUrl}?PageNumber=${pageNumber + 1}&PageSize=${pageSize}&PendingRequest=${PendingRequest}`
    );
    return response.data;
  }
);

export const getSearchAssets = createAsyncAction(actionTypes.SEARCH_ASSETS, async (data) => {
  const API_URL = `${process.env.REACT_APP_URL}Asset`;

  const apiUrl = `${API_URL}/search-assets`;

  const response = await Post(`${apiUrl}`, data);
  return response.data;
});

export const getAssetDetails = createAsyncAction(actionTypes.GET_ASSET_DETAILS, async (assetId) => {
  const apiUrl = `${API_URL}Asset/Admin/Asset`;
  const response = await Get(`${apiUrl}/${assetId}`);
  return response.data;
});

export const toggleAssetStatus = createAsyncAction(actionTypes.TOGGLE_ASSET_STATUS, async (payload) => {
  const apiUrl = `${API_URL}Asset/approval`;

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getPayouts = createAsyncAction(actionTypes.GET_ALL_PAYOUTS, async (pageNumber, pageSize) => {
  const apiUrl = `${API_URL}Admin`;

  const response = await Get(
    `${apiUrl}/GetAllPayouts?PageNumber=${pageNumber}&PageSize=${pageSize}&PendingRequest=${true}`
  );
  return response.data;
});

export const initiatePayout = createAsyncAction(actionTypes.INITIATE_PAYOUT, async (assetId) => {
  const apiUrl = `${API_URL}Asset`;

  const response = await Get(`${apiUrl}/initiate-payout?assetId=${assetId}`);
  return response.data;
});

export const addTabIndex = (payload) => ({
  type: actionTypes.TAB_CURRENT_INDEX,
  payload,
});

export const getPropertyTypes = createAsyncAction(actionTypes.GET_PROPERTY_TYPES, async () => {
  const apiUrl = `${API_URL}Lookup/property-types`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const addNewPropertyType = createAsyncAction(actionTypes.ADD_PROPERTY_TYPES, async (data) => {
  const apiUrl = `${API_URL}admin/AddPropertyType`;

  const payload = {
    name: data,
  };

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const deletePropertyType = createAsyncAction(actionTypes.DELETE_PROPERTY_TYPE, async (id) => {
  const apiUrl = `${API_URL}admin/DeletePropertyType`;

  const response = await Delete(`${apiUrl}/${id}`);
  return response.data;
});

export const getPropertyTypeConfig = createAsyncAction(actionTypes.GET_PROPERTY_TYPES_CONFIG, async () => {
  const apiUrl = `${API_URL}admin/GetPropertyConfigurations`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const deletePropertyTypeConfig = createAsyncAction(actionTypes.DELETE_PROPERTY_TYPE_CONFIG, async (id) => {
  const apiUrl = `${API_URL}admin/DeletePropertyTypeConfiguration`;

  const response = await Delete(`${apiUrl}/${id}`);
  return response.data;
});

export const addNewPropertyTypeConfig = createAsyncAction(actionTypes.ADD_PROPERTY_TYPE_CONFIG, async (data) => {
  const apiUrl = `${API_URL}Admin/AddPropertyConfiguration`;

  const payload = {
    name: data.name,
    propertyTypeId: data.propertyTypeId,
    isPropertyConversionAllowed: data.isPropertyConversionAllowed, // Boolean
    minimumPriceUnit: data.minimumPriceUnit,
    maximumPriceUnit: data.maximumPriceUnit,
    maximumRoi: data.maximumRoi,
    maximumUnit: data.maximumUnit,
    minimumUnit: data.minimumUnit,
    roiMutiple: data.roiMutiple,
    unitsCounter: data.unitsCounter,
    minimumAllowablePropertyConvertibleUnit: data.minimumAllowablePropertyConvertibleUnit,
    countryId: data.countryId,
    stateId: data?.stateId,
  };

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const attachMoratoriumToPropertyType = createAsyncAction(
  actionTypes.ATTACH_MORATORIUM_TO_PROPERTY_TYPES,
  async (payload) => {
    const apiUrl = `${API_URL}Admin/AddMoratoriumToProperty`;

    const response = await Post(`${apiUrl}`, payload);
    return response.data;
  }
);

export const getMoratorium = createAsyncAction(actionTypes.GET_MORATORIUM, async () => {
  const apiUrl = `${API_URL}admin/GetMoratorium`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const getPropertyTypeMoratorium = createAsyncAction(actionTypes.GET_PROPERTY_TYPE_MORATORIUM, async (id) => {
  const apiUrl = `${API_URL}Admin/GetMoratoriumPropertyTypes`;
  const response = await Get(`${apiUrl}/${id}`);
  return response.data;
});

export const deleteMoratoriumFromPropertyType = createAsyncAction(
  actionTypes.DELETE_PROPERTY_TYPE_MORATORIUM,
  async (id) => {
    const apiUrl = `${API_URL}admin/DeleteMoratoriumProperty`;

    const response = await Delete(`${apiUrl}/${id}`);
    return response.data;
  }
);

export const deleteMoratorium = createAsyncAction(actionTypes.DELETE_MORATORIUM, async (id) => {
  const apiUrl = `${API_URL}admin/DeleteMoratorium`;

  const response = await Delete(`${apiUrl}/${id}`);
  return response.data;
});

export const addNewMoratorium = createAsyncAction(actionTypes.ADD_MORATORIUM, async (data) => {
  const apiUrl = `${API_URL}admin/AddMoratorium`;

  const payload = {
    name: data,
  };

  const response = await Post(`${apiUrl}`, payload);
  return response.data;
});

export const getCountry = createAsyncAction(actionTypes.GET_COUNTRY, async () => {
  const apiUrl = `${API_URL}Lookup/countries`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

export const getState = createAsyncAction(actionTypes.GET_STATE, async (countryId) => {
  const apiUrl = `${API_URL}Lookup/states/${countryId}`;
  const response = await Get(`${apiUrl}`);
  return response.data;
});

