import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const VerificationInformation = ({ user }) => (
  <ThemeProvider
    theme={{
      spacing: (factor) => `${8 * factor}px`,
      palette: {
        grey: {
          300: '#ccc',
        },
      },
    }}
  >
    <Typography variant="h4" my={2} gutterBottom>
      User Verification Information
    </Typography>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {user?.documents &&
        user?.documents.length > 0 &&
        user?.documents.map((item, index) => (
          <Fragment key={index}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" gutterBottom>
                {item?.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Link to={item?.path} target="_blank">
                View
              </Link>
            </Grid>
          </Fragment>
        ))}

      <Grid item md={3} xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Date Submitted
        </Typography>
        <Typography variant="body1" gutterBottom>
          {new Date(user?.dateCreated).toLocaleString() || 'N/A'}
        </Typography>
      </Grid>
      <Grid item md={3} xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Status
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.verificationStatus ? user?.verificationStatus.replace(/([A-Z])/g, ' $1').trim() : 'N/A'}
        </Typography>
      </Grid>
      <Grid item md={3} xs={6}>
        <Typography variant="subtitle1" gutterBottom>
          Comment
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user?.comment || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  </ThemeProvider>
);

VerificationInformation.propTypes = {
  user: PropTypes.shape({
    accountStatus: PropTypes.string,
    accountType: PropTypes.string,
    address: PropTypes.string,
    companyName: PropTypes.string,
    country: PropTypes.string,
    comment: PropTypes.string,
    verificationStatus: PropTypes.string,
    dateCreated: PropTypes.string,
    id: PropTypes.number,
    is2faEnabled: PropTypes.bool,
    isEmailConfirmed: PropTypes.bool,
    isPhoneNumberConfirmed: PropTypes.bool,
    defaultCurrency: PropTypes.string,
    documents: PropTypes.array,
  }).isRequired,
};

export default VerificationInformation;
