import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import dashboardReducer from './reducers';
import Notification from './reducers/notification';
import adminReducer from './reducers/admin';
import assetReducer from './reducers/asset';

const loggerMiddleware = createLogger();
const middleware = [thunk, loggerMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(loggerMiddleware);
}

export default configureStore({
  reducer: { dashboardReducer, Notification, adminReducer, assetReducer },
  middleware: [thunk, loggerMiddleware, promiseMiddleware],
});
