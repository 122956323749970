import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { createFeeConfig } from '../../logic/actions/admin';

const FeeConfigsForm = ({ isLoading, setAddModal, modalState, paymentProcessorId }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const { ...rest } = data;
    const convertedFormData = {
      ...rest,
      paymentProcessorId,
    };
    dispatch(createFeeConfig(convertedFormData));
    if (data) {
      dispatch(createFeeConfig(convertedFormData))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Configuration set successfully'));
            setAddModal(false);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
            setAddModal(false);
          } else {
            const errorResponse = error?.data?.responseMessage;
            const isErrors = error?.data;
            const errorMessages = isErrors?.errors ? Object.values(isErrors.errors).flat() : [];
            const errorMessageString = errorMessages?.join(', ');
            const fullErrorMessage = isErrors?.errors ? errorMessageString : errorResponse;
            dispatch(errorMessage(fullErrorMessage));
          }
        });
    }
  };

  useEffect(() => {
    if (!modalState) {
      reset();
    }
  }, [modalState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
          gap: 1.5,
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'} mb={1}>
          <TextField
            {...register('minAmount', { required: true })}
            fullWidth
            label="Enter Minimum Amount"
            variant="outlined"
            type="number"
            error={!!errors.minAmount}
            helperText={errors.minAmount && 'Minimum Amount is required'}
          />
          <TextField
            {...register('maxAmount', { required: true })}
            fullWidth
            label="Enter Maximum Amount"
            variant="outlined"
            type="number"
            error={!!errors.maxAmount}
            helperText={errors.maxAmount && 'Maximum Amount is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'} mb={1}>
          <TextField
            {...register('costBearer', { required: true })}
            fullWidth
            label="Enter Cost Bearer"
            variant="outlined"
            type="text"
            error={!!errors.costBearer}
            helperText={errors.costBearer && 'Cost Bearer is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} width={'100%'}>
          <TextField
            {...register('customerShare', { required: true })}
            fullWidth
            label="Enter Customer Share"
            variant="outlined"
            type="number"
            error={!!errors.customerShare}
            helperText={errors.customerShare && 'Customer share is required'}
          />
          <TextField
            {...register('businessShare', { required: true })}
            fullWidth
            label="Enter Business Share"
            variant="outlined"
            type="number"
            error={!!errors.businessShare}
            helperText={errors.businessShare && 'Business Share is required'}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} width={'100%'} mt={2}>
          <Button
            onClick={() => setAddModal(false)}
            variant="outlined"
            size="large"
            sx={{ width: '100%', color: '#000', bg: '#000', border: 'black 1px solid', '&:hover': { border: 'none' } }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            sx={{ width: '100%' }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default FeeConfigsForm;

FeeConfigsForm.propTypes = {
  isLoading: PropTypes.bool,
  setAddModal: PropTypes.func,
  token: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  paymentProcessorId: PropTypes.string.isRequired,
};
