import * as React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export default function FormSelect({ isloading, data, payload, onChange, submitStatus, clearOnModalClose }) {
  const options = data || [];
  const [value, setValue] = React.useState(''); // Track the selected values

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  React.useEffect(() => {
    if (clearOnModalClose === false) {
      setValue(''); // Clear the selected values
      onChange(null, []); // Call the onChange with an empty array
    }
  }, [clearOnModalClose, onChange]);

  return (
    <>
      <Autocomplete
        labelid={'users'}
        id={'users'}
        name={'userId'}
        label={'users'}
        options={options}
        value={value} // Set the value prop to the selected values
        autoHighlight
        fullwidth="true"
        loading={isloading}
        onChange={handleOnChange}
        clearOnEscape
        onClose={(event, reason) => {
          if (reason === 'reset') {
            setValue(''); // Clear the selected values
            onChange(event, null);
          }
        }}
        isOptionEqualToValue={(option, value) => option.email === value.email}
        getOptionLabel={(option) => {
          if (option.length === 0) {
            return ''; // Return an empty string if the option is an empty array
          }
          return option.firstname && option.lastname ? `${option.firstname} ${option.lastname}` : option.companyName;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ p: 2 }}>
            {option.email}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={submitStatus && payload?.userId.length === 0}
            label="Remove User"
            fullwidth="true"
            inputProps={{
              ...params.inputProps,
              endadornment: (
                <>
                  {isloading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

FormSelect.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  submitStatus: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  isloading: PropTypes.bool,
  clearOnModalClose: PropTypes.bool,
};
