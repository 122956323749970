import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @mui
import { Grid, Container, Typography, Stack } from '@mui/material';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
// components
// import Iconify from '../components/iconify';
import { getAdminDashboardInfo } from '../logic/actions/admin';
import './dashboard.css';
import useAuthToken from '../hooks/useAuthToken';
// sections
import {
  // AppNewsUpdate,
  // AppOrderTimeline,
  // AppCurrentVisits,
  // AppWebsiteVisits,
  StatCard,
} from '../sections/@dashboard/app';
// import { errorMessage } from '../logic/actions/notification';

export default function DashboardAppPage() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useAuthToken();
  const theme = useTheme();
  const [startDate, setStartDate] = useState(
    null
    //   () => {
    //   const currentDate = new Date();
    //   const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    //   return startDate;
    // }
  );
  const [endDate, setEndDate] = useState(null);

  const { getAdminDashboardInfoPayload, getAdminDashboardInfoLoading } = useSelector((state) => state.dashboardReducer);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const payload = {
      dateFrom: startDate,
      dateTo: endDate,
    };
    dispatch(getAdminDashboardInfo(payload, token))
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        // dispatch(errorMessage(err.message));
      });
  }, [dispatch, endDate, startDate, token]);

  return (
    <>
      <Helmet>
        <title> Musha | Dashboard </title>
      </Helmet>
      {/* {getAdminDashboardInfoLoading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5 }}>
            <Typography variant="h4">Hi, Welcome back</Typography>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              showDisabledMonthNavigation
              maxDate={new Date()}
              placeholderText="Select a date range"
              isClearable
              withPortal
              disabled={false}
              className="datePicker"
            />
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="New Assets"
                  total={getAdminDashboardInfoPayload?.data.newAssetCount}
                  icon={'clarity:new-solid'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Pending Assets Approval"
                  total={getAdminDashboardInfoPayload?.data.pendingAssetRequestCount}
                  color="info"
                  icon={'ic:outline-pending-actions'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="New Users"
                  total={getAdminDashboardInfoPayload?.data.newUsersCount}
                  color="warning"
                  icon={'fa-solid:users'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Pending Users Request"
                  total={getAdminDashboardInfoPayload?.data.pendingUserRequestCount}
                  color="error"
                  icon={'mdi:account-pending-outline'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Total Asset"
                  total={getAdminDashboardInfoPayload?.data.totalAssetCount}
                  color="success"
                  icon={'carbon:summary-kpi-mirror'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Total Users"
                  total={getAdminDashboardInfoPayload?.data.totalUsers}
                  color="secondary"
                  icon={'gis:map-users'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Transaction Count"
                  total={getAdminDashboardInfoPayload?.data.transactionCount}
                  color="primary"
                  icon={'icon-park-solid:transaction'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {getAdminDashboardInfoLoading ? (
                <Skeleton variant="rectangular" animation="wave" width={'100%'} height={170} sx={{ borderRadius: 2 }} />
              ) : (
                <StatCard
                  title="Transaction Volume"
                  total={getAdminDashboardInfoPayload?.data.transactionVolume}
                  color="warning"
                  icon={'uil:transaction'}
                />
              )}
            </Grid>

            {/* <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Website Visits"
                subheader="(+43%) than last year"
                chartLabels={[
                  '01/01/2003',
                  '02/01/2003',
                  '03/01/2003',
                  '04/01/2003',
                  '05/01/2003',
                  '06/01/2003',
                  '07/01/2003',
                  '08/01/2003',
                  '09/01/2003',
                  '10/01/2003',
                  '11/01/2003',
                ]}
                chartData={[
                  {
                    name: 'Team A',
                    type: 'column',
                    fill: 'solid',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                  {
                    name: 'Team B',
                    type: 'area',
                    fill: 'gradient',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  },
                  {
                    name: 'Team C',
                    type: 'line',
                    fill: 'solid',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  },
                ]}
              />
            </Grid> */}
            {/* 
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Current Visits"
                chartData={[
                  { label: 'America', value: 4344 },
                  { label: 'Asia', value: 5435 },
                  { label: 'Europe', value: 1443 },
                  { label: 'Africa', value: 4443 },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}
              />
            </Grid> */}
            {/* 
            <Grid item xs={12} md={6} lg={8}>
              <AppNewsUpdate
                title="News Update"
                list={[...Array(5)].map((_, index) => ({
                  id: faker.datatype.uuid(),
                  title: faker.name.jobTitle(),
                  description: faker.name.jobTitle(),
                  image: `/assets/images/covers/cover_${index + 1}.jpg`,
                  postedAt: faker.date.recent(),
                }))}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppOrderTimeline
                title="Order Timeline"
                list={[...Array(5)].map((_, index) => ({
                  id: faker.datatype.uuid(),
                  title: [
                    '1983, orders, $4220',
                    '12 Invoices have been paid',
                    'Order #37745 from September',
                    'New order placed #XF-2356',
                    'New order placed #XF-2346',
                  ][index],
                  type: `order${index + 1}`,
                  time: faker.date.past(),
                }))}
              />
            </Grid> */}
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
}
