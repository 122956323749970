import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Table,
  Stack,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
// import { errorMessage, successMessage } from '../../logic/actions/notification';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import CustomModal from '../../components/modal';
import TablePulse from '../../components/tablePulse';
import { getWebHooks, retriggerHook } from '../../logic/actions/admin';
import { errorMessage, successMessage } from '../../logic/actions/notification';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'uniqueReference', label: 'Unique Reference', alignRight: false },
  { id: 'eventType', label: 'Event Type', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'dateCreatedProcessed', label: 'Date Processed', alignRight: false },
  { id: 'retryCount', label: 'Retry Count', alignRight: false },
  { id: 'status', label: 'Status' },
  {
    id: '',
    label: '',
  },
];

export default function Index() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState();
  const [filteredRows, setFilteredRows] = useState('');
  const [openAddModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  // const [transactionID, setTransactionId] = useState();

  const [rowLoadingStates, setRowLoadingStates] = useState({});
  // Function to update the loading state for a specific row
  const setRowLoadingState = (id, loading) => {
    setRowLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: loading,
    }));
  };

  const { getWebHooksLoading, getWebHooksPayload } = useSelector((state) => state.adminReducer);

  const formatDate = (date) => {
    const originalDate = new Date(date);
    return originalDate.toLocaleDateString('en-GB');
  };

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        uniqueReference: item?.uniqueReference,
        eventType: item?.eventType,
        dateCreated: formatDate(item?.dateCreated),
        dateCreatedProcessed: formatDate(item?.dateCreatedProcessed),
        status: item?.status,
        retryCount: item?.retryCount,
        isProcessed: item?.isProcessed,
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter(
      (item) =>
        (item.uniqueReference?.toLowerCase() || '').includes(filterName.toLowerCase()) ||
        (item.email?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  const fetchWebHooks = useCallback(() => {
    dispatch(getWebHooks(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 403) {
          dispatch(errorMessage('You do not have permission to perform this action.'));
        } else {
          dispatch(errorMessage(error?.response.data.responseMessage));
        }
      });
  }, [dispatch, page, rowsPerPage]);

  const reTriggerTransaction = (id) => {
    setRowLoadingState(id, true);
    dispatch(retriggerHook(id))
      .then(() => {
        setRowLoadingState(id, false);
        dispatch(successMessage('Transaction successfully re-triggered'));
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 403) {
          dispatch(errorMessage('You do not have permission to perform this action.'));
          setRowLoadingState(id, false);
        } else {
          dispatch(errorMessage(error?.response.data.responseMessage));
          setRowLoadingState(id, false);
        }
      });
  };

  useEffect(() => {
    fetchWebHooks();
  }, [fetchWebHooks]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Musha | Web hooks </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Web Hooks</Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search transactions...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {getWebHooksLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={7} />
                  </TableBody>
                ) : filteredRows?.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell align="left">{row.uniqueReference}</TableCell>
                          <TableCell align="left">{row.eventType}</TableCell>
                          <TableCell align="left">{row.dateCreated}</TableCell>
                          <TableCell align="left">{row.dateCreatedProcessed}</TableCell>
                          <TableCell align="left">{row.retryCount}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">
                            {(row.status === 'Invalid' || row.status === 'Unconfirmed') && (
                              <LoadingButton
                                loading={rowLoadingStates[row.id]}
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                  reTriggerTransaction(row.id);
                                }}
                              >
                                Retry
                              </LoadingButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {getWebHooksPayload?.totalCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getWebHooksPayload?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Rows:'}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <CustomModal
        isOpen={openAddModal}
        title="Register Admin"
        content={<></>}
        contentText={''}
        onClose={() => {
          setAddModal(false);
        }}
        creating={false}
        disableCreate={false}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={'Register Admin'}
        onCreate={() => {}}
        createBtnColor=""
      />
    </>
  );
}
