/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import TableContainer from '@mui/material/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetOrderDetails } from '../../logic/actions/admin';
import Scrollbar from '../scrollbar/Scrollbar';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AssetOrderHistory = ({ asset }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderId, setOrderId] = useState();
  const dispatch = useDispatch();
  const { stateLoading, statePayload } = useSelector((state) => state.adminReducer);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAssetOrderDetails(orderId))
      .then(() => {})
      .catch((error) => console.error(error));
  }, [dispatch, orderId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (id) => {
    setOpen(true);
    setOrderId(id);
  };

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Asset ID</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell sortDirection="desc">Value</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {asset.orderHistories.length > 0 ? (
                rowsPerPage > 0 ? (
                  asset.orderHistories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                    <TableRow hover key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{order.paymentDate || 'N/A'}</TableCell>
                      <TableCell>{order.amount}</TableCell>
                      <TableCell>
                        {order.dateCreated ? new Date(order.dateCreated).toLocaleDateString() : 'N/A'}
                      </TableCell>
                      <TableCell>{order.userId}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleOpenModal(order.id)} color="primary" size="small" variant="text">
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell colSpan={7}>No orders yet</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow hover>
                  <TableCell colSpan={7}>No orders yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>{' '}
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {asset.orderHistories && asset.orderHistories.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={asset.orderHistories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    'aria-label': 'rows per page',
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </CardActions>
        </TableContainer>
      </Scrollbar>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Order ID: {orderId}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {stateLoading ? (
          <Typography textAlign={'center'} variant="h6" paragraph padding={5}>
            <CircularProgress color="inherit" />
          </Typography>
        ) : statePayload?.data && statePayload?.data.length > 0 ? (
          <List>
            {statePayload?.data?.map((transaction) => (
              <React.Fragment key={transaction.id}>
                <ListSubheader
                  sx={{ fontSize: '18px', width: '100%', bgcolor: '#F5F5F5', color: 'black', marginTop: '20px' }}
                >{`Purpose: ${transaction.purpose}`}</ListSubheader>
                <ListItem button>
                  <ListItemText
                    primary={`Order ID: ${transaction.paymentReference}`}
                    secondary={`Amount: ${transaction.amount}`}
                  />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    primary={`Debit Ledger: ${transaction.debitLedger}`}
                    secondary={`Credit Ledger: ${transaction.creditLedger}`}
                  />
                </ListItem>

                <Divider />
                <ListItem button>
                  <ListItemText
                    primary={`Bank Account: ${transaction.bankAccount || 'N/A'}`}
                    secondary={`Bank Account Name: ${transaction.bankAccountName || 'N/A'}`}
                  />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText primary={`Date Created: ${new Date(transaction.dateCreated).toLocaleString()}`} />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="h5" component="div" sx={{ padding: '20px', textAlign: 'center' }}>
            No transaction found
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default AssetOrderHistory;
