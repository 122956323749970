import React, { useEffect } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTransactionHistory } from '../../logic/actions';
import { errorMessage } from '../../logic/actions/notification';
import TablePulse from '../tablePulse';
import Scrollbar from '../scrollbar/Scrollbar';

const TransactionInformation = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getUserTransactionHistoryLoading, getUserTransactionHistoryPayload } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    // Fetch data from the server and update the 'user' state
    const userId = id;
    dispatch(getUserTransactionHistory(userId))
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        dispatch(errorMessage(error.message));
      });
  }, [dispatch, id]);
  return (
    <>
      <Typography variant="h4" my={2} gutterBottom>
        Transaction History
      </Typography>
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box>
          <Table sx={{ width: '100%', whiteSpace: 'nowrap' }}>
            <TableHead>
              <TableRow>
                <TableCell>Asset ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Payment Channel</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {getUserTransactionHistoryLoading ? (
                <TablePulse arrayLength={5} rows={7} />
              ) : getUserTransactionHistoryPayload?.data && getUserTransactionHistoryPayload?.data.length > 0 ? (
                getUserTransactionHistoryPayload?.data.map((asset) => (
                  <TableRow hover key={asset.id}>
                    <TableCell>{asset?.assetId || 'N/A'}</TableCell>
                    <TableCell>{asset?.assetTitle || 'N/A'}</TableCell>
                    <TableCell>{asset?.units || 'N/A'}</TableCell>
                    <TableCell>₦{asset?.amount || '0'}</TableCell>
                    <TableCell>{new Date(asset?.dateCreated).toLocaleString() || 'N/A'}</TableCell>
                    <TableCell>{asset?.paymentProcessor || 'N/A'}</TableCell>
                    <TableCell>
                      <Typography
                        onClick={() => router(`/asset/${asset?.assetId}`)}
                        sx={{ fontSize: '13px', fontWeight: 'medium', color: 'blue', cursor: 'pointer' }}
                      >
                        View
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>No History Yet</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </>
  );
};

export default TransactionInformation;
