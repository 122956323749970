import axios from 'axios';
import { BehaviorSubject } from 'rxjs';

import { config } from './config.service';

export const subscriber = new BehaviorSubject(0);

export const GetFile = async (url) => {
  try {
    return await axios.get(`${url}`, {
      ...config(),
      responseType: 'arraybuffer',
    });
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};
export const Get = async (url) => {
  try {
    return await axios.get(`${url}`, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Post = async (url, data) => {
  try {
    return await axios.post(`${url}`, data, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Patch = async (url, data) => {
  try {
    return await axios.patch(`${url}`, data, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Put = async (url, data) => {
  try {
    return await axios.put(`${url}`, data, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Delete = async (url) => {
  try {
    return await axios.delete(`${url}`, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};
