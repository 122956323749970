import React, { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import FormControl from '@mui/material/FormControl';
import { UserListHead } from '../../sections/@dashboard/user';
import { getAssets, getSearchAssets } from '../../logic/actions/asset';
import TablePulse from '../../components/tablePulse';
import Iconify from '../../components/iconify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    borderRadius: '0px 6px 6px 0px',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'unitPrice', label: 'Unit Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const AllAsset = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const arrayOfObjects = [
    {
      value: 'availableUnits',
      label: 'Available Units',
    },
    {
      value: 'state',
      label: 'State',
    },
    {
      value: 'unitPrice',
      label: 'Unit Price',
    },
    {
      value: 'title',
      label: 'Title',
    },
    {
      value: 'lga',
      label: 'LGA',
    },
    {
      value: 'fullAddress',
      label: 'Full Address',
    },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set the formatted value
    setValue(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceText = useCallback(
    debounce(async (payload) => {
      dispatch(getSearchAssets(payload))
        .then((responseData) => {
          const data = responseData?.value.data;
          setRows(data);
          setTotalResults(responseData?.value?.totalCount || 0);
        })
        .catch((error) => console.error(error));
    }, 1000),
    []
  );

  useEffect(() => {
    const payload = {
      [value || 'title']: filterName,
      category: 6,
    };
    debounceText(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceText, filterName]);

  const { assetsLoading, searchAssetsLoading } = useSelector((state) => state.assetReducer);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        title: item?.title,
        id: item?.id,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
        unitPrice: item?.unitPrice,
        units: item?.units,
        state: item?.state,
        status: item?.status,
      })),
    [rows]
  );

  const memoizedFetchAssets = useCallback(async () => {
    try {
      const responseData = await dispatch(getAssets(page, rowsPerPage));
      const data = responseData?.value.data;
      setRows(data);
      setTotalResults(responseData?.value?.totalCount || 0);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    memoizedFetchAssets();
  }, [memoizedFetchAssets]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <div className="all-asset">
      <Stack direction="row" alignItems="center" justifyContent="start" gap="2" my={3} ml={2}>
        <FormControl sx={{ width: 230 }}>
          <Select
            displayEmpty
            style={{
              borderRadius: '6px 0px 0px 6px',
            }}
            value={value}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Select an option</span>;
              }

              // Find the corresponding label for the selected value
              const selectedLabel = arrayOfObjects.find((item) => item.value === selected)?.label;

              return selectedLabel || <span>Select an option</span>;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Search asset' }}
          >
            {arrayOfObjects.map((name, index) => (
              <MenuItem key={index} value={name.value}>
                {name.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledSearch
          value={filterName}
          onChange={handleFilterByName}
          placeholder={'Search assets...'}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </Stack>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'medium'}>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={headCells}
            rowCount={format()?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          {assetsLoading || searchAssetsLoading ? (
            <TableBody>
              <TablePulse arrayLength={10} rows={5} />
            </TableBody>
          ) : format()?.length > 0 ? (
            <TableBody>
              {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell align="left">{row.dateCreated}</TableCell>
                    <TableCell align="left">{row.unitPrice}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" alignItems={'center'}>
                        <Typography
                          onClick={() => navigate(`/asset/${row.id}`)}
                          sx={{ fontSize: '13px', fontWeight: 'medium', color: 'blue', cursor: 'pointer' }}
                        >
                          View more
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3, width: '100%' }} fullWidth>
                  <div>No record found in logs</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {totalResults ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 170]}
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default AllAsset;
