/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  CardActions,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Scrollbar from '../scrollbar/Scrollbar';

const AssetHistory = ({ asset }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  console.log(asset?.assetHistories);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Asset ID</TableCell>
                <TableCell>Current Owner</TableCell>
                <TableCell sortDirection="desc">User ID</TableCell>
                <TableCell>Date Bought</TableCell>
                <TableCell>History Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {asset.assetHistories.length > 0 ? (
                rowsPerPage > 0 ? (
                  asset.assetHistories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((history) => (
                    <TableRow hover key={history.id}>
                      <TableCell>{history.assetId}</TableCell>
                      <TableCell>{history.isCurrentOwner === true ? 'Yes' : 'No' || 'N/A'}</TableCell>
                      <TableCell>{history.userId}</TableCell>
                      <TableCell>{new Date(history.datePurchased).toDateString()}</TableCell>
                      <TableCell>{history.historyType || 'N/A'}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell />
                    <TableCell />
                    <TableCell>No history yet</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow hover>
                  <TableCell />
                  <TableCell />
                  <TableCell>No history yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {asset?.assetHistories && asset.assetHistories.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={asset.assetHistories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                'aria-label': 'rows per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </CardActions>
    </>
  );
};

export default AssetHistory;
