/* eslint-disable react/prop-types */
import {
  IconButton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  MenuItem,
  Popover,
  Box,
  CircularProgress,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
// import { debounce } from 'lodash';
import CustomModal from '../../components/modal';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
import {
  addNewPropertyType,
  attachMoratoriumToPropertyType,
  deleteMoratoriumFromPropertyType,
  deletePropertyType,
  getMoratorium,
  getPropertyTypeMoratorium,
  getPropertyTypes,
} from '../../logic/actions/asset';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { UserListHead } from '../../sections/@dashboard/user';
import AddNewPropertyTypeForm from './addPropertyType';
import Iconify from '../../components/iconify';
import AddPropertyConfigurationForm from './addPropertyConfigForm';
import AttachMoratorium from './attachMoratorium';

const headCells = [{ id: 'name', label: 'Name', alignRight: false }, { id: '' }];

const initialFormValues = {
  typeName: '',
};

const initialMoratoriumFormValues = {
  propertyTypeId: '',
  moratoriumIds: [],
};

const PropertyType = ({ openmodal, setNewModal }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [totalResults, setTotalResults] = useState(0);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [submitStatus, setSubmitStatus] = useState(false);
  const [formValues, setFormValues] = useState({ payload: { ...initialFormValues } });
  const [addConfigModalOpen, setAddConfigModalOpen] = useState(false);
  const [addMoratoriumOpen, setAddMoratoriumModalOpen] = useState(false);
  const [removeMoratoriumOpen, setRemoveMoratoriumModalOpen] = useState(false);
  const [moratoriumFormValues, setMoratoriumFormValues] = useState({ payload: { ...initialMoratoriumFormValues } });
  const [deletingItemId, setDeletingItemId] = useState(null);
  const handleMoratoriumFormChange = (e, newValue) => {
    if (e) {
      const { name, value } = e.target;
      setMoratoriumFormValues((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
      if (newValue) {
        setMoratoriumFormValues((prev) => ({
          ...prev,
          payload: {
            moratoriumIds: newValue.map((item) => item.id),
            propertyTypeId: selectedId,
          },
        }));
      }
    }
  };

  const addMoratoriumToPropertyType = () => {
    const payload = {
      propertyTypeId: moratoriumFormValues?.payload?.propertyTypeId,
      moratoriumIds: moratoriumFormValues?.payload?.moratoriumIds,
    };

    let errorStatus = false;
    if (!payload.moratoriumIds || payload.moratoriumIds.length === 0) {
      errorStatus = true;
      setSubmitStatus(true);
    }

    if (!errorStatus) {
      dispatch(attachMoratoriumToPropertyType(payload))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Success'));
            setAddMoratoriumModalOpen(false);
            setMoratoriumFormValues({ payload: { ...initialFormValues } });
            getPropertyMoratoriumList();
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(errorMessage(error?.data.responseMessage));
        });
    }
  };

  const getMoratoriumList = useCallback(() => {
    dispatch(getMoratorium())
      .then(() => {})
      .catch((error) => console.error(error));
  }, [dispatch]);

  useEffect(() => {
    getMoratoriumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPropertyMoratoriumList = useCallback(() => {
    dispatch(getPropertyTypeMoratorium(selectedId))
      .then(() => {})
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      getPropertyMoratoriumList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const {
    propertyTypesPayload,
    propertyTypesLoading,
    deletePropertyTypeLoading,
    addPropertyTypeLoading,
    addPropertyTypeConfigLoading,
    moratoriumPayload,
    moratoriumLoading,
    attachMoratoriumLoading,
    getPropertyMoratoriumLoading,
    getPropertyMoratoriumPayload,
    deleteMoratoriumFromPropertyTypeLoading,
  } = useSelector((state) => state.assetReducer);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        name: item?.name,
      })),
    [rows]
  );

  const getPropertyTypesList = useCallback(() => {
    dispatch(getPropertyTypes())
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [dispatch]);

  useEffect(() => {
    getPropertyTypesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFormChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
    }
  };

  const createNewPropertyType = () => {
    const name = formValues?.payload?.typeName;
    let errorStatus = false;
    if (!name) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(addNewPropertyType(name))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Property Type created successfully'));
            setNewModal(false);
            getPropertyTypesList();
            setFormValues({ payload: { ...initialFormValues }, submitted: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
            setNewModal(false);
          } else {
            dispatch(errorMessage(error?.response.data.responseMessage));
          }
        });
    }
  };

  const removePropertyType = () => {
    dispatch(deletePropertyType(selectedId))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Property Type removed successfully'));
          setRemoveModalOpen(false);
          getPropertyTypesList();
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response.data.responseMessage));
      });
  };

  const handleOpen = () => {
    setRemoveModalOpen(true);
  };

  const [open, setOpen] = useState(null);
  const handleOpenMenu = (event, id) => {
    setSelectedId(id);
    setOpen(event.currentTarget);
  };

  const handleDelete = (id) => {
    setDeletingItemId(id);
    dispatch(deleteMoratoriumFromPropertyType(id))
      .then((responseData) => {
        if (responseData?.value.responseMessage === 'Successful') {
          dispatch(successMessage('Moratorium removed successfully'));
          getPropertyMoratoriumList();
          setDeletingItemId('');
        }
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response.data.responseMessage));
        setDeletingItemId('');
      });
  };

  // Assuming moratoriumPayload and getPropertyMoratoriumPayload are defined and have the 'data' property as arrays
  const moratoriumPayloadData = moratoriumPayload?.data || [];
  const getPropertyMoratoriumPayloadData = getPropertyMoratoriumPayload?.data || [];

  // Extract IDs from getPropertyMoratoriumPayloadData
  const propertyMoratoriumIds = getPropertyMoratoriumPayloadData.map((item) => item.id);

  // Filter moratoriumPayloadData to exclude items with IDs present in propertyMoratoriumIds
  const filteredMoratoriums = moratoriumPayloadData.filter((item) => !propertyMoratoriumIds.includes(item.id));

  return (
    <>
      <Helmet>
        <title> Musha | Asset Config</title>
      </Helmet>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table aria-labelledby="tableTitle" size={'medium'}>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={headCells}
                rowCount={format()?.length}
                numSelected={selected?.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {propertyTypesLoading ? (
                <TableBody>
                  <TablePulse arrayLength={10} rows={2} />
                </TableBody>
              ) : propertyTypesPayload?.data.length > 0 ? (
                <TableBody>
                  {format().map((row, index) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                        <TableCell>{row.name}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row.id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <div>No record found in logs</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {propertyTypesPayload?.totalCount ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Scrollbar>
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1.5,
            width: '200px',
            borderRadius: 2,
            zIndex: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 1.5,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAddConfigModalOpen(true);
            setOpen(null);
          }}
        >
          Add Configuration
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAddMoratoriumModalOpen(true);
            setOpen(null);
          }}
        >
          Add Moratorium
        </MenuItem>

        <MenuItem
          onClick={() => {
            setRemoveMoratoriumModalOpen(true);
            setOpen(null);
          }}
        >
          Remove Moratorium
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpen(null);
            handleOpen();
          }}
          sx={{ fontSize: '13px', fontWeight: 'medium', color: 'red', cursor: 'pointer' }}
        >
          Remove
        </MenuItem>
      </Popover>

      {/* Remove */}
      <CustomModal
        isOpen={isRemoveModalOpen}
        title="Remove Property Type"
        content={<></>}
        contentText={'Are you sure you want to Remove this Property type?'}
        onClose={() => {
          setRemoveModalOpen(false);
        }}
        creating={deletePropertyTypeLoading}
        disableCreate={deletePropertyTypeLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Remove'}
        onCreate={() => {
          removePropertyType();
        }}
        createBtnColor="error"
      />

      <CustomModal
        isOpen={addConfigModalOpen}
        title="Add Property Configuration"
        content={
          <AddPropertyConfigurationForm
            isSubmitting={addPropertyTypeConfigLoading}
            setAddModal={setAddConfigModalOpen}
            modalState={addConfigModalOpen}
            closeModal={() => setAddConfigModalOpen(false)}
            page={page}
            rowsPerPage={rowsPerPage}
            propertyTypeId={Number(selectedId)}
          />
        }
        contentText={''}
        onClose={() => {
          setAddConfigModalOpen(false);
        }}
        creating={addPropertyTypeConfigLoading}
        disableCreate={addPropertyTypeConfigLoading}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={'Register Admin'}
        onCreate={() => {}}
        createBtnColor=""
      />

      <CustomModal
        isOpen={openmodal}
        title="Add New Property Type"
        contentText=""
        content={
          <AddNewPropertyTypeForm
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            isClose={openmodal}
          />
        }
        onClose={() => {
          setNewModal(false);
          setFormValues({ payload: { ...initialFormValues }, submitted: false });
        }}
        creating={addPropertyTypeLoading}
        disableCreate={addPropertyTypeLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Create'}
        onCreate={() => {
          createNewPropertyType();
        }}
      />

      {/* Moratorium */}

      <CustomModal
        isOpen={addMoratoriumOpen}
        title="Add Moratorium"
        content={
          <AttachMoratorium
            payload={moratoriumFormValues?.payload}
            onChange={handleMoratoriumFormChange}
            submitStatus={submitStatus}
            moratoriums={filteredMoratoriums || []}
            isMoratoriumLoading={moratoriumLoading}
            clearOnModalClose={addMoratoriumOpen}
          />
        }
        contentText={''}
        onClose={() => {
          setAddMoratoriumModalOpen(false);
        }}
        creating={attachMoratoriumLoading}
        disableCreate={attachMoratoriumLoading}
        closeText={'Close'}
        createText={'Save'}
        onCreate={addMoratoriumToPropertyType}
        createBtnColor=""
      />

      <CustomModal
        isOpen={removeMoratoriumOpen}
        title="Remove Moratorium"
        content={
          <Box variant="body1" sx={{ textAlign: 'center', marginBottom: 4 }}>
            {getPropertyMoratoriumLoading ? <CircularProgress /> : null}
            <Stack direction="row" gap={1} flexWrap={'wrap'}>
              {getPropertyMoratoriumPayload?.data?.map((item) => (
                <Chip
                  key={item.id}
                  size="medium"
                  label={item?.moratoriumName}
                  onDelete={() => handleDelete(item?.id)}
                  // color={deleteMoratoriumFromPropertyTypeLoading ? 'error' : 'default'}
                  disabled={deleteMoratoriumFromPropertyTypeLoading}
                  deleteIcon={
                    deleteMoratoriumFromPropertyTypeLoading && deletingItemId === item.id ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 128 128"
                        id="spinner"
                        className="spin-animation"
                      >
                        <path
                          stroke="#000"
                          strokeLinecap="round"
                          strokeWidth="7"
                          d="M16 64H37M112 64H91M30.0592 97.9411L44.9084 83.0918M97.9414 30.0588L83.0922 44.9081M30.0592 30.0589L44.9084 44.9082M97.9414 97.9412L83.0922 83.0919M64 16L64 37M64 112L64 91"
                        />
                      </svg>
                    ) : null
                  }
                />
              ))}
            </Stack>
            {getPropertyMoratoriumPayload?.data && getPropertyMoratoriumPayload?.data.length === 0 && (
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {'No moratorium added to this property type'}
              </Typography>
            )}
          </Box>
        }
        contentText={''}
        onClose={() => {
          setRemoveMoratoriumModalOpen(false);
        }}
        creating={attachMoratoriumLoading}
        disableCreate={attachMoratoriumLoading}
        closeText={'Close'}
        hideCloseBtn
        hideCreateBtn
        createText={'Remove'}
        onCreate={addMoratoriumToPropertyType}
        createBtnColor="error"
      />
    </>
  );
};

export default PropertyType;