import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch } from 'react-redux';
import { addTabIndex } from '../../logic/actions/asset';

const ReusableTabComponent = ({ tabs }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(tabs[0].value);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(addTabIndex(value))
    }, [dispatch, value])
    

    return (
      <Box sx={{ width: '100%', typography: '' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="">
              {tabs?.map((tab, index) => (
                <Tab key={index} label={tab?.label} value={tab?.value} />
              ))}
            </TabList>
          </Box>
          {tabs?.map((tab, index) => (
            <TabPanel key={index} value={tab?.value ?? ''} sx={{ margin: 0, padding: 0 }}>
              {tab?.content}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    );
};

ReusableTabComponent.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default ReusableTabComponent;
