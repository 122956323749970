import { createAsyncAction } from 'redux-promise-middleware-actions';
import { Get, Put, Post, Delete } from '../../apiServices/apiHandler.service';
import actionTypes from './types';

const API_URL = `${process.env.REACT_APP_URL}User`;

export const signin = createAsyncAction(actionTypes.LOGIN, async (data) => {
  const res = await Post(`${API_URL}/login`, data, {
    withCredentials: false,
    timeout: 20000,
  });
  return res;
});

export const getUser = createAsyncAction(actionTypes.GET_USERS, async (pageNumber = 1, pageSize = 10) => {
  const response = await Get(`${API_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  return response;
});

export const getSingleUser = createAsyncAction(actionTypes.GET_SINGLE_USER, async (userId) => {
  const apiUrl = `${API_URL}/${userId}`; // Your API URL

  const response = await Get(apiUrl);
  return response;
});

export const banUser = createAsyncAction(actionTypes.BAN_USER, async (userId) => {
  const apiUrl = `${API_URL}/ban-user/${userId}`; // Your API URL

  const response = await Put(apiUrl);
  return response;
});

export const approveRejectUser = createAsyncAction(actionTypes.APPROVE_REJECT_USER, async (data) => {
  const apiUrl = `${API_URL}/verification-approval`;

  const response = await Post(`${apiUrl}`, data);
  return response.data;
});

export const getUserAsset = createAsyncAction(actionTypes.GET_USER_ASSETS, async (userId, category) => {
  const apiUrl = `${process.env.REACT_APP_URL}Admin/GetUserAsset/get-user-asset`;

  const response = await Get(`${apiUrl}/${userId}?Category=${category}`);
  return response.data;
});

export const getUserTransactionHistory = createAsyncAction(actionTypes.GET_USER_TRANSACTION_HISTORY, async (userId) => {
  const apiUrl = `${process.env.REACT_APP_URL}Admin/GetUserOrders`;

  const response = await Get(`${apiUrl}?userId=${userId}`);
  return response.data;
});

export const getUserVerificationDetails = createAsyncAction(
  actionTypes.GET_USER_VERIFICATION_DETAILS,
  async (userId) => {
    const apiUrl = `${API_URL}/user-verification-details`;

    const response = await Get(`${apiUrl}/${userId}`);
    return response.data;
  }
);

export const activateUser = createAsyncAction(actionTypes.ACTIVATE_USER, async (userId) => {
  const apiUrl = `${API_URL}/activate-user/${userId}`; // Your API URL

  const response = await Put(apiUrl);
  return response;
});

export const deleteUser = createAsyncAction(actionTypes.DELETE_USER, async (userId) => {
  const apiUrl = `${API_URL}/delete/${userId}`; // Your API URL

  const response = await Delete(apiUrl);
  return response;
});

export const getOnboardingRequests = createAsyncAction(
  actionTypes.GET_ONBOARDING_REQUESTS,
  async (pageNumber = 1, pageSize = 10) => {
    const apiUrl = `${API_URL}/verification-requests`;

    const response = await Get(`${apiUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}&PendingRequest=true`);
    return response;
  }
);

export const getVerificationDetails = createAsyncAction(actionTypes.GET_VERIFICATION_DETAILS, async (userId) => {
  const apiUrl = `${API_URL}/user-verification-details/${userId}`; // Your API URL

  const response = await Get(apiUrl);
  return response;
});

export const getDownloadVerifyDocument = createAsyncAction(
  actionTypes.GET_DOWNLOAD_VERIFY_DOCUMENT,
  async (userId) => {
    const apiUrl = `${API_URL}/download-verification-document/${userId}`; // Your API URL

    const response = await Get(apiUrl);
    return response;
  }
);
