import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Link, Table, TableRow, TableBody, TableCell, TableContainer, TablePagination } from '@mui/material';
import { getUser } from '../../logic/actions';
import { getAdminUsers } from '../../logic/actions/admin';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import CustomModal from '../../components/modal';
import TablePulse from '../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'accountType', label: 'Account Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function AllUsers() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [totalResults, setTotalResults] = useState(0);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteUser = () => {
    alert('deleted');
    setOpenDeleteModal(false);
  };

  const editUser = () => {
    alert('edited');
    setOpenEditModal(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      if (srhTxt) {
        dispatch(getAdminUsers(srhTxt))
          .then((responseData) => {
            const data = responseData?.value.data;
            setRows(data);
            setTotalResults(data?.length || 0);
          })
          .catch((error) => console.error(error));
      } else {
        dispatch(getUser(page, rowsPerPage))
          .then((responseData) => {
            const data = responseData?.value.data;
            setRows(data?.data);
            setTotalResults(data?.totalCount || 0);
          })
          .catch((error) => console.error(error));
      }
    }, 500),
    [dispatch, page, rowsPerPage]
  );

  useEffect(() => {
    debounceText(filterName);
  }, [debounceText, filterName]);

  const { getUsersLoading } = useSelector((state) => state.dashboardReducer);
  const { getAdminUsersLoading } = useSelector((state) => state.adminReducer);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        name: `${item.firstname || ''} ${item.lastname || ''}`.trim() || item.companyName,
        id: item?.id,
        email: item?.email,
        verified: item?.accountStatus === 'Active' ? 'Yes' : 'No',
        phoneNumber: item?.phone,
        accountType: item?.accountType,
        status: item?.accountStatus.replace(/([A-Z])/g, ' $1').trim(),
      })),
    [rows]
  );

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    dispatch(getUser(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data?.data);
        setTotalResults(data?.totalCount || 0);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Musha | Users </title>
      </Helmet>

      <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

      <TableContainer sx={{ minWidth: 800 }}>
        <Table aria-labelledby="tableTitle" size={'medium'} style={{ whiteSpace: 'nowrap' }}>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={headCells}
            rowCount={format()?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          {getUsersLoading || getAdminUsersLoading ? (
            <TableBody>
              <TablePulse arrayLength={rowsPerPage} rows={7} />
            </TableBody>
          ) : format().length > 0 ? (
            <TableBody>
              {stableSort(format(), getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow hover key={index} selected={isItemSelected}>
                    <TableCell>{row.name}</TableCell>

                    <TableCell align="left">{row.email}</TableCell>

                    <TableCell align="left">{row.phoneNumber}</TableCell>

                    <TableCell align="left">{row.verified}</TableCell>

                    <TableCell align="left">{row.accountType}</TableCell>

                    <TableCell align="left">{row.status}</TableCell>

                    <TableCell align="right">
                      <Link
                        href={`/user/${row.id}`}
                        padding={1}
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          color: 'blue',
                          justifyContent: 'start',
                          textDecoration: 'none',
                        }}
                      >
                        View
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3, width: '100%' }} fullWidth>
                  <div>No record found in logs</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {totalResults ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 170]}
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

      <CustomModal
        isOpen={openEditModal}
        title="Edit User"
        contentText="Add Forms to edit user"
        content={<></>}
        onClose={closeModal}
        creating={false}
        disableCreate={false}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Submit'}
        onCreate={() => {
          editUser();
        }}
      />

      <CustomModal
        onCreate={() => {
          deleteUser();
        }}
        contentText={`Are you sure you want to delete this User?`}
        content={<></>}
        creating={false}
        disableCreate={false}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Delete'}
        isOpen={openDeleteModal}
        title="Delete User"
        onClose={closeDeleteModal}
        createBtnColor={'error'}
      />
    </>
  );
}
