/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { modifyPrice } from '../../logic/actions/admin';

const EditForm = ({ asset, modalState, setModalState, id, fetchAssetDetails }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!modalState) {
      reset();
    }
  }, [modalState, reset]);

  const { modifyPriceLoading } = useSelector((state) => state.adminReducer);

  const onSubmit = (data) => {
    const convertedData = {
      price: Number(data.price),
      assetId: id,
    };
    if (asset?.unitPrice === Number(data.price)) {
      setError('price', { type: 'custom', message: "Old price can't be the same as the new price" });
      return;
    }
    if (data) {
      dispatch(modifyPrice(convertedData))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Asset price change successfully'));
            setModalState(false);
            fetchAssetDetails();
          }
        })
        .catch((error) => {
          const errorResponse = error?.data?.responseMessage;
          const isErrors = error?.data;
          const errorMessages = isErrors?.errors ? Object.values(isErrors.errors).flat() : [];
          const errorMessageString = errorMessages?.join(', ');
          const fullErrorMessage = isErrors?.errors ? errorMessageString : errorResponse;
          dispatch(errorMessage(fullErrorMessage));
        });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: 3 }}
      >
        <TextField fullWidth variant="standard" label="Old Unit Price" disabled defaultValue={asset?.unitPrice ?? 0} />
        <TextField
          {...register('price', { required: true })}
          fullWidth
          type="number"
          label="Enter New Unit Price"
          variant="outlined"
          error={!!errors.price}
          helperText={errors?.price?.message}
        />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        width={'100%'}
        marginTop={3}
      >
        <Button
          onClick={() => setModalState(false)}
          variant="outlined"
          size="large"
          sx={{
            textTransform: 'capitalize',
            padding: 1.5,
            border: '1px solid #EAF0F4',
            borderRadius: 2,
            fontWeight: '400',
            width: '100%',
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={modifyPriceLoading}
          disabled={modifyPriceLoading}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          sx={{
            textTransform: 'capitalize',
            padding: 1.5,
            border: '1px solid #EAF0F4',
            borderRadius: 2,
            fontWeight: '400',
            width: '100%',
          }}
        >
          Submit
        </LoadingButton>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};

export default EditForm;
