import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

const AddNewRole = ({ onChange, submitStatus, payload, isClose }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        onChange(e);
    };

    useEffect(() => {
        if (isClose) {
            setInputValue('');
        }
    }, [isClose]);

    return (
        <Box>
            <TextField
                onChange={handleInputChange}
                id="roleName"
                name="roleName"
                fullWidth
                label="Enter Role Name"
                variant="outlined"
                value={inputValue} // Bind the input value to the state
                error={submitStatus && !payload?.roleName}
                helperText={submitStatus && !payload?.roleName ? 'Role Name is required' : ''}
            />
        </Box>
    );
};

export default AddNewRole;

AddNewRole.propTypes = {
    onChange: PropTypes.func.isRequired,
    submitStatus: PropTypes.bool.isRequired,
    payload: PropTypes.object.isRequired,
    isClose: PropTypes.bool.isRequired,
};
