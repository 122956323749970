/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export default function TextMobileStepper({ images }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  // Preload images
  React.useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.documentLink;
    });
  }, [images]);

  return (
    <Box sx={{ maxWidth: 600, flexGrow: 1, mb: 3 }}>
      <img
        style={{
          height: 400,
          display: 'block',
          overflow: 'hidden',
          width: '100%',
          borderRadius: '10px',
          backgroundColor: '#E0E3E4',
        }}
        src={images[activeStep]?.documentLink}
        alt={images[activeStep]?.label}
      />
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
}
