import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, Link } from '@mui/material';
import { getOnboardingRequests } from '../../logic/actions';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import TablePulse from '../../components/tablePulse';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  // { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function OnBoardingRequest() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState('');

  const { getOnboardingRequestsLoading, getOnboardingRequestsPayload } = useSelector((state) => state.dashboardReducer);

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        name: `${item.firstname || ''} ${item.lastname || ''}`.trim() || item.companyName,
        id: item?.userId,
        email: item?.email,
        verified: item?.accountStatus !== 'AwaitingVerificationApproval' ? 'Yes' : 'No',
        phoneNumber: item?.phone,
        status: item?.verificationStatus.replace(/([A-Z])/g, ' $1').trim(),
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter(
      (item) =>
        (item.name?.toLowerCase() || '').includes(filterName.toLowerCase()) ||
        (item.email?.toLowerCase() || '').includes(filterName.toLowerCase())
    );

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  useEffect(() => {
    // Fetch data from the server and update the 'rows' state
    dispatch(getOnboardingRequests(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data?.data);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> User | Musha </title>
      </Helmet>

      <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

      <TableContainer sx={{ minWidth: 800 }}>
        <Table aria-labelledby="tableTitle" size={'medium'} style={{ whiteSpace: 'nowrap' }}>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={headCells}
            rowCount={filteredRows?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />

          {getOnboardingRequestsLoading ? (
            <TableBody>
              <TablePulse arrayLength={rowsPerPage} rows={5} />
            </TableBody>
          ) : filteredRows.length > 0 ? (
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                    <TableCell>{row.name}</TableCell>

                    <TableCell align="left">{row.email}</TableCell>

                    <TableCell align="left">{row.phoneNumber}</TableCell>

                    <TableCell align="left">{row.status}</TableCell>

                    <TableCell align="right">
                      <Link
                        href={`/onboarding-request/${row.id}`}
                        padding={1}
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          color: 'blue',
                          justifyContent: 'start',
                          textDecoration: 'none',
                        }}
                      >
                        View more
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <div>No record found in logs</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {getOnboardingRequestsPayload?.data.totalCount ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={getOnboardingRequestsPayload?.data.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}
