import {
    Button,
    Card,
    Container,
    Stack,
    Typography
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
// components
import { useState } from 'react';
import ReusableTabComponent from '../../components/Tabs';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import PropertyTypeConfig from './propertyConfiguration';
import PropertyType from './propertyType';
import Moratorium from './moratorium';

export default function Index() {
  const { tabIndexPayload } = useSelector((state) => state.assetReducer);
  const [isNewPropertyTypeModalOpen, setNewPropertyTypeModalOpen] = useState(false);
  const [isNewPropertyTypeConfigModalOpen, setNewPropertyTypeConfigModalOpen] = useState(false);
  const [isNewMoratoriumModalOpen, setNewMoratoriumModalOpen] = useState(false);

  const tabs = [
    {
      label: 'Property Type',
      value: '1',
      content: (
        <PropertyType
          openmodal={isNewPropertyTypeModalOpen}
          setNewModal={(value) => setNewPropertyTypeModalOpen(value)}
        />
      ),
    },
    {
      label: 'Property Type Config',
      value: '2',
      content: (
        <PropertyTypeConfig
          openmodal={isNewPropertyTypeConfigModalOpen}
          setNewModal={(value) => setNewPropertyTypeConfigModalOpen(value)}
        />
      ),
    },
    {
      label: 'Moratorium',
      value: '3',
      content: (
        <Moratorium openmodal={isNewMoratoriumModalOpen} setNewModal={(value) => setNewMoratoriumModalOpen(value)} />
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title> Assets Config | Musha </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Asset Configuration</Typography>
          {tabIndexPayload === '1' ? (
            <Button
              style={{
                borderRadius: 10,
                // color: "white", backgroundColor: "blue",
                // opacity: data?.availableUnits > 0 ? 0.5 : 1
              }}
              onClick={() => setNewPropertyTypeModalOpen(true)}
              size="large"
              variant="contained"
            >
              Add Type
            </Button>
          ) : tabIndexPayload === '2' ? null : (
            <Button
              style={{
                borderRadius: 10,
                // color: "white", backgroundColor: "blue",
                // opacity: data?.availableUnits > 0 ? 0.5 : 1
              }}
              size="large"
              variant="contained"
              onClick={() => setNewMoratoriumModalOpen(true)}
            >
              Add Moratorium
            </Button>
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <ReusableTabComponent tabs={tabs} />
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
