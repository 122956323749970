import { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Table,
  TableRow,
  Button,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Card,
  Container,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { createExchangeRate, getExchangeRate } from '../../logic/actions/admin';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TablePulse from '../../components/tablePulse';
import CustomModal from '../../components/modal';
import ExchangeRateForm from './exchangeRateForm';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'currency', label: 'Currency', alignRight: false },
  { id: 'rate', label: 'Rate', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
];

const initialFormValues = {
  currency: '',
  rate: '',
};

const ExchangeRate = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [formValues, setFormValues] = useState({ payload: { ...initialFormValues } });
  const { getExchangeRatePayload, getExchangeRateLoading, createExchangeRateLoading } = useSelector(
    (state) => state.adminReducer
  );

  const format = useCallback(
    () =>
      rows?.map((item) => ({
        id: item?.id,
        currency: item?.currency,
        rate: item?.rate,
        dateCreated: new Date(item.dateCreated).toLocaleString(), // Convert the date to a normal format
      })),
    [rows]
  );

  useEffect(() => {
    const filteredData = format()?.filter((item) => {
      const itemValues = Object.values(item);
      const hasMatchingColumn = itemValues.some((columnValue) =>
        columnValue?.toString().toLowerCase().includes(filterName.toLowerCase())
      );
      return hasMatchingColumn;
    });

    setFilteredRows(filteredData);
  }, [rows, filterName, format]);

  const fetchExchangeRate = useCallback(() => {
    dispatch(getExchangeRate(page, rowsPerPage))
      .then((responseData) => {
        const data = responseData?.value.data;
        setRows(data);
        console.log();
        setTotalResults(responseData?.value?.totalCount || 0);
      })
      .catch((error) => console.error(error));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    fetchExchangeRate();
  }, [fetchExchangeRate, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = format().map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.includes(name);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const createNewExchangeRate = () => {
    const data = formValues?.payload?.currency || formValues?.payload.rate;
    let errorStatus = false;
    if (!data) {
      errorStatus = true;
      setSubmitStatus(true);
    }
    if (!errorStatus) {
      dispatch(createExchangeRate(formValues?.payload))
        .then((responseData) => {
          if (responseData?.value.responseMessage === 'Successful') {
            dispatch(successMessage('Exchange Rate created successfully'));
            setModalOpen(false);
            fetchExchangeRate();
            setFormValues({ payload: { ...initialFormValues }, submitted: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            dispatch(errorMessage('You do not have permission to perform this action.'));
            setModalOpen(false);
          } else {
            dispatch(errorMessage(error?.response.data.responseMessage));
          }
        });
    }
  };

  const handleFormChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
    }
  };

  return (
    <>
      <Helmet>
        <title> Musha | Exchange Rate</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Exchange Rate
          </Typography>
          <Button
            style={{
              borderRadius: 10,
            }}
            onClick={() => setModalOpen(true)}
            size="large"
            variant="contained"
          >
            New
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder={'Search rate...'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-labelledby="tableTitle" size={'medium'}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={headCells}
                  rowCount={filteredRows?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <></>
                {getExchangeRateLoading ? (
                  <TableBody>
                    <TablePulse arrayLength={10} rows={3} />
                  </TableBody>
                ) : filteredRows.length > 0 ? (
                  <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected}>
                          <TableCell>{row.currency ?? 'N/A'}</TableCell>

                          <TableCell align="left">{row.rate}</TableCell>

                          <TableCell align="left">{row.dateCreated}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div>No record found in logs</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {getExchangeRatePayload?.totalCount ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalResults}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Scrollbar>
        </Card>
      </Container>

      <CustomModal
        isOpen={modalOpen}
        title="Add Exchange Rate"
        contentText={''}
        onClose={() => {
          setModalOpen(false);
        }}
        creating={createExchangeRateLoading}
        disableCreate={createExchangeRateLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        content={
          <ExchangeRateForm
            payload={formValues?.payload}
            onChange={handleFormChange}
            submitStatus={submitStatus}
            isClose={modalOpen}
          />
        }
        createText={'Create'}
        onCreate={() => {
          createNewExchangeRate();
        }}
      />
    </>
  );
};

export default ExchangeRate;
