import actionTypes from '../actions/types';
import { FAILURE, REQUEST, SUCCESS } from './action-type.util';

const INTIAL_STATE = {};

const AssetsReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_ASSETS):
      return {
        ...state,
        assetsLoading: true,
        assetsPayload: null,
        assetsError: null,
      };
    case SUCCESS(actionTypes.GET_ASSETS):
      return {
        ...state,
        assetsLoading: false,
        assetsPayload: actions.payload,
        assetsError: null,
      };
    case FAILURE(actionTypes.GET_ASSETS):
      return {
        ...state,
        assetsLoading: false,
        assetsPayload: null,
        assetsError: actions.payload,
      };
    case REQUEST(actionTypes.GET_ASSET_DETAILS):
      return {
        ...state,
        assetDetailsLoading: true,
        assetDetailsPayload: null,
        assetDetailsError: null,
      };
    case SUCCESS(actionTypes.GET_ASSET_DETAILS):
      return {
        ...state,
        assetDetailsLoading: false,
        assetDetailsPayload: actions.payload,
        assetDetailsError: null,
      };
    case FAILURE(actionTypes.GET_ASSET_DETAILS):
      return {
        ...state,
        assetDetailsLoading: false,
        assetDetailsPayload: null,
        assetDetailsError: actions.payload,
      };
    case REQUEST(actionTypes.TOGGLE_ASSET_STATUS):
      return {
        ...state,
        assetStatusLoading: true,
        assetStatusPayload: null,
        assetStatusError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_ASSET_STATUS):
      return {
        ...state,
        assetStatusLoading: false,
        assetStatusPayload: actions.payload,
        assetStatusError: null,
      };
    case FAILURE(actionTypes.TOGGLE_ASSET_STATUS):
      return {
        ...state,
        assetStatusLoading: false,
        assetStatusPayload: null,
        assetStatusError: actions.payload,
      };
    case REQUEST(actionTypes.SEARCH_ASSETS):
      return {
        ...state,
        searchAssetsLoading: true,
        searchAssetsPayload: null,
        searchAssetsError: null,
      };
    case SUCCESS(actionTypes.SEARCH_ASSETS):
      return {
        ...state,
        searchAssetsLoading: false,
        searchAssetsPayload: actions.payload,
        searchAssetsError: null,
      };
    case FAILURE(actionTypes.SEARCH_ASSETS):
      return {
        ...state,
        searchAssetsLoading: false,
        searchAssetsPayload: null,
        searchAssetsError: actions.payload,
      };
    case REQUEST(actionTypes.GET_ALL_PAYOUTS):
      return {
        ...state,
        allPayoutsLoading: true,
        allPayoutsPayload: null,
        allPayoutsError: null,
      };
    case SUCCESS(actionTypes.GET_ALL_PAYOUTS):
      return {
        ...state,
        allPayoutsLoading: false,
        allPayoutsPayload: actions.payload,
        allPayoutsError: null,
      };
    case FAILURE(actionTypes.GET_ALL_PAYOUTS):
      return {
        ...state,
        allPayoutsLoading: false,
        allPayoutsPayload: null,
        allPayoutsError: actions.payload,
      };
    case REQUEST(actionTypes.GET_PAYOUT_DETAILS_BY_ID):
      return {
        ...state,
        payoutDetailsLoading: true,
        payoutDetailsPayload: null,
        payoutDetailsError: null,
      };
    case SUCCESS(actionTypes.GET_PAYOUT_DETAILS_BY_ID):
      return {
        ...state,
        payoutDetailsLoading: false,
        payoutDetailsPayload: actions.payload,
        payoutDetailsError: null,
      };
    case FAILURE(actionTypes.GET_PAYOUT_DETAILS_BY_ID):
      return {
        ...state,
        payoutDetailsLoading: false,
        payoutDetailsPayload: null,
        payoutDetailsError: actions.payload,
      };
    case REQUEST(actionTypes.INITIATE_PAYOUT):
      return {
        ...state,
        initiatePayoutLoading: true,
        initiatePayoutPayload: null,
        initiatePayoutError: null,
      };
    case SUCCESS(actionTypes.INITIATE_PAYOUT):
      return {
        ...state,
        initiatePayoutLoading: false,
        initiatePayoutPayload: actions.payload,
        initiatePayoutError: null,
      };
    case FAILURE(actionTypes.INITIATE_PAYOUT):
      return {
        ...state,
        initiatePayoutLoading: false,
        initiatePayoutPayload: null,
        initiatePayoutError: actions.payload,
      };
    case actionTypes.TAB_CURRENT_INDEX:
      return {
        ...state,
        tabIndexPayload: actions.payload,
      };
    case REQUEST(actionTypes.GET_PROPERTY_TYPES):
      return {
        ...state,
        propertyTypesLoading: true,
        propertyTypesPayload: null,
        propertyTypesError: null,
      };
    case SUCCESS(actionTypes.GET_PROPERTY_TYPES):
      return {
        ...state,
        propertyTypesLoading: false,
        propertyTypesPayload: actions.payload,
        propertyTypesError: null,
      };
    case FAILURE(actionTypes.GET_PROPERTY_TYPES):
      return {
        ...state,
        propertyTypesLoading: false,
        propertyTypesPayload: null,
        propertyTypesError: actions.payload,
      };
    case REQUEST(actionTypes.DELETE_PROPERTY_TYPE):
      return {
        ...state,
        deletePropertyTypeLoading: true,
        deletePropertyTypePayload: null,
        deletePropertyTypeError: null,
      };
    case SUCCESS(actionTypes.DELETE_PROPERTY_TYPE):
      return {
        ...state,
        deletePropertyTypeLoading: false,
        deletePropertyTypePayload: actions.payload,
        deletePropertyTypeError: null,
      };
    case FAILURE(actionTypes.DELETE_PROPERTY_TYPE):
      return {
        ...state,
        deletePropertyTypeLoading: false,
        deletePropertyTypePayload: null,
        deletePropertyTypeError: actions.payload,
      };
    case REQUEST(actionTypes.ADD_PROPERTY_TYPES):
      return {
        ...state,
        addPropertyTypeLoading: true,
        addPropertyTypePayload: null,
        addPropertyTypeError: null,
      };
    case SUCCESS(actionTypes.ADD_PROPERTY_TYPES):
      return {
        ...state,
        addPropertyTypeLoading: false,
        addPropertyTypePayload: actions.payload,
        addPropertyTypeError: null,
      };
    case FAILURE(actionTypes.ADD_PROPERTY_TYPES):
      return {
        ...state,
        addPropertyTypeLoading: false,
        addPropertyTypePayload: null,
        addPropertyTypeError: actions.payload,
      };

    case REQUEST(actionTypes.GET_PROPERTY_TYPES_CONFIG):
      return {
        ...state,
        propertyTypesConfigLoading: true,
        propertyTypesConfigPayload: null,
        propertyTypesConfigError: null,
      };
    case SUCCESS(actionTypes.DELETE_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        deletePropertyTypeConfigLoading: false,
        deletePropertyTypeConfigPayload: actions.payload,
        deletePropertyTypeConfigError: null,
      };
    case FAILURE(actionTypes.DELETE_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        deletePropertyTypeConfigLoading: false,
        deletePropertyTypeConfigPayload: null,
        deletePropertyTypeConfigError: actions.payload,
      };
    case REQUEST(actionTypes.DELETE_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        deletePropertyTypeConfigLoading: true,
        deletePropertyTypeConfigPayload: null,
        deletePropertyTypeConfigError: null,
      };
    case REQUEST(actionTypes.ADD_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        addPropertyTypeConfigLoading: true,
        addPropertyTypeConfigPayload: null,
        addPropertyTypeConfigError: null,
      };
    case SUCCESS(actionTypes.ADD_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        addPropertyTypeConfigLoading: false,
        addPropertyTypeConfigPayload: actions.payload,
        addPropertyTypeConfigError: null,
      };
    case FAILURE(actionTypes.ADD_PROPERTY_TYPE_CONFIG):
      return {
        ...state,
        addPropertyTypeConfigLoading: false,
        addPropertyTypeConfigPayload: null,
        addPropertyTypeConfigError: actions.payload,
      };
    case SUCCESS(actionTypes.GET_PROPERTY_TYPES_CONFIG):
      return {
        ...state,
        propertyTypesConfigLoading: false,
        propertyTypesConfigPayload: actions.payload,
        propertyTypesConfigError: null,
      };

    case FAILURE(actionTypes.GET_PROPERTY_TYPES_CONFIG):
      return {
        ...state,
        propertyTypesConfigLoading: false,
        propertyTypesConfigPayload: null,
        propertyTypesConfigError: actions.payload,
      };
    case REQUEST(actionTypes.GET_MORATORIUM):
      return {
        ...state,
        moratoriumLoading: true,
        moratoriumPayload: null,
        moratoriumError: null,
      };
    case SUCCESS(actionTypes.GET_MORATORIUM):
      return {
        ...state,
        moratoriumLoading: false,
        moratoriumPayload: actions.payload,
        moratoriumError: null,
      };
    case FAILURE(actionTypes.GET_MORATORIUM):
      return {
        ...state,
        moratoriumLoading: false,
        moratoriumPayload: null,
        moratoriumError: actions.payload,
      };
    case REQUEST(actionTypes.DELETE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumLoading: true,
        deleteMoratoriumPayload: null,
        deleteMoratoriumError: null,
      };
    case SUCCESS(actionTypes.DELETE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumLoading: false,
        deleteMoratoriumPayload: actions.payload,
        deleteMoratoriumError: null,
      };
    case FAILURE(actionTypes.DELETE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumLoading: false,
        deleteMoratoriumPayload: null,
        deleteMoratoriumError: actions.payload,
      };
    case REQUEST(actionTypes.ADD_MORATORIUM):
      return {
        ...state,
        addMoratoriumLoading: true,
        addMoratoriumPayload: null,
        addMoratoriumError: null,
      };
    case SUCCESS(actionTypes.ADD_MORATORIUM):
      return {
        ...state,
        addMoratoriumLoading: false,
        addMoratoriumPayload: actions.payload,
        addMoratoriumError: null,
      };
    case FAILURE(actionTypes.ADD_MORATORIUM):
      return {
        ...state,
        addMoratoriumLoading: false,
        addMoratoriumPayload: null,
        addMoratoriumError: actions.payload,
      };
    case REQUEST(actionTypes.GET_COUNTRY):
      return {
        ...state,
        countryLoading: true,
        countryPayload: null,
        countryError: null,
      };
    case SUCCESS(actionTypes.GET_COUNTRY):
      return {
        ...state,
        countryLoading: false,
        countryPayload: actions.payload,
        countryError: null,
      };
    case FAILURE(actionTypes.GET_COUNTRY):
      return {
        ...state,
        countryLoading: false,
        countryPayload: null,
        countryError: actions.payload,
      };
    case REQUEST(actionTypes.GET_STATE):
      return {
        ...state,
        stateLoading: true,
        statePayload: null,
        stateError: null,
      };
    case SUCCESS(actionTypes.GET_STATE):
      return {
        ...state,
        stateLoading: false,
        statePayload: actions.payload,
        stateError: null,
      };
    case FAILURE(actionTypes.GET_STATE):
      return {
        ...state,
        stateLoading: false,
        statePayload: null,
        stateError: actions.payload,
      };
    case REQUEST(actionTypes.ATTACH_MORATORIUM_TO_PROPERTY_TYPES):
      return {
        ...state,
        attachMoratoriumLoading: true,
        attachMoratoriumPayload: null,
        attachMoratoriumError: null,
      };
    case SUCCESS(actionTypes.ATTACH_MORATORIUM_TO_PROPERTY_TYPES):
      return {
        ...state,
        attachMoratoriumLoading: false,
        attachMoratoriumPayload: actions.payload,
        attachMoratoriumError: null,
      };
    case FAILURE(actionTypes.ATTACH_MORATORIUM_TO_PROPERTY_TYPES):
      return {
        ...state,
        attachMoratoriumLoading: false,
        attachMoratoriumPayload: null,
        attachMoratoriumError: actions.payload,
      };
    case REQUEST(actionTypes.GET_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        getPropertyMoratoriumLoading: true,
        getPropertyMoratoriumPayload: null,
        getPropertyMoratoriumError: null,
      };
    case SUCCESS(actionTypes.GET_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        getPropertyMoratoriumLoading: false,
        getPropertyMoratoriumPayload: actions.payload,
        getPropertyMoratoriumError: null,
      };
    case FAILURE(actionTypes.GET_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        getPropertyMoratoriumLoading: false,
        getPropertyMoratoriumPayload: null,
        getPropertyMoratoriumError: actions.payload,
      };
    case REQUEST(actionTypes.DELETE_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumFromPropertyTypeLoading: true,
        deleteMoratoriumFromPropertyTypePayload: null,
        deleteMoratoriumFromPropertyTypeError: null,
      };
    case SUCCESS(actionTypes.DELETE_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumFromPropertyTypeLoading: false,
        deleteMoratoriumFromPropertyTypePayload: actions.payload,
        deleteMoratoriumFromPropertyTypeError: null,
      };
    case FAILURE(actionTypes.DELETE_PROPERTY_TYPE_MORATORIUM):
      return {
        ...state,
        deleteMoratoriumFromPropertyTypeLoading: false,
        deleteMoratoriumFromPropertyTypePayload: null,
        deleteMoratoriumFromPropertyTypeError: actions.payload,
      };
    default:
      return state;
  }
};

export default AssetsReducer;
