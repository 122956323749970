import { Autocomplete, Checkbox, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const AttachMoratorium = ({ isMoratoriumLoading, moratoriums, payload, onChange, submitStatus, clearOnModalClose }) => {
  const options = moratoriums || [];
  const [value, setValue] = useState([]); // Track the selected values

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  useEffect(() => {
    if (clearOnModalClose === false) {
      setValue([]); // Clear the selected values
      onChange(null, []); // Call the onChange with an empty array
    }
  }, [clearOnModalClose, onChange]);

  return (
    <>
      <Autocomplete
        multiple
        limitTags={3}
        id="moratoriumIds"
        name="moratoriumIds"
        label="Moratoriums"
        options={options}
        value={value} // Set the value prop to the selected values
        autoHighlight
        fullWidth
        loading={isMoratoriumLoading}
        onChange={handleOnChange}
        clearOnEscape
        onClose={(event, reason) => {
          if (reason === 'reset') {
            setValue([]); // Clear the selected values
            onChange(event, null);
          }
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={submitStatus && payload?.moratoriumIds.length === 0}
            label="Moratoriums"
            fullWidth
            inputProps={{
              ...params.inputProps,
              endadornment: (
                <>
                  {isMoratoriumLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default AttachMoratorium;

AttachMoratorium.propTypes = {
  moratoriums: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  submitStatus: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  isMoratoriumLoading: PropTypes.bool,
  clearOnModalClose: PropTypes.bool,
};
