import { Helmet } from 'react-helmet-async';
import { Typography, Container, Stack, Button, Chip, TextField, ListItemAvatar, Avatar } from '@mui/material';
import { FolderIcon } from 'lucide-react';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import AssetInformation from '../../components/assetDetails/assetInformation';
import CompanyInformation from '../../components/assetDetails/companyInformation';
import AssetHistory from '../../components/assetDetails/history';
import AssetOrderHistory from '../../components/assetDetails/orderHistory';
import { getAssetDetails, toggleAssetStatus, initiatePayout } from '../../logic/actions/asset';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import Iconify from '../../components/iconify';
import ReusableTabComponent from '../../components/Tabs';
import { errorMessage, successMessage } from '../../logic/actions/notification';
import CustomModal from '../../components/modal';
import AssetPriceHistory from '../../components/assetDetails/priceHistory';
import CustomImageList from './documentDisplay';

const AssetDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const [isOpen, setOpen] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [getId] = useState();
  const [isApprove, setIsApprove] = useState(false);
  useEffect(() => {
    if (!id || id === '0') {
      navigate('/asset');
    }
  }, [id, navigate]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
  });

  const { initiatePayoutLoading, assetDetailsLoading, assetStatusLoading, assetDetailsPayload, assetDetailsError } =
    useSelector((state) => state.assetReducer);

  const data = assetDetailsPayload?.data;

  const docs = data && data?.assetDocuments.filter((doc) => !doc?.isImage);
  const images = data && data?.assetDocuments.filter((doc) => doc?.isImage);
  const assetId = id;
  console.log(docs);
  const fetchAssetDetails = useCallback(() => {
    dispatch(getAssetDetails(assetId));
  }, [dispatch, assetId]);

  useEffect(() => {
    fetchAssetDetails();
  }, [assetId, dispatch, fetchAssetDetails]);

  const tabs = [
    { label: 'Asset History', value: '1', content: <AssetHistory asset={data} /> },
    { label: 'Asset Order History', value: '2', content: <AssetOrderHistory asset={data} /> },
    {
      label: 'Price History',
      value: '3',
      content: <AssetPriceHistory id={id} fetchAssetDetails={fetchAssetDetails} />,
    },
    1,
  ];

  const handleInitiateSubmit = () => {
    dispatch(initiatePayout(getId))
      .then(() => {
        dispatch(successMessage('success'));
        setOpen(false);
      })
      .catch((error) => {
        dispatch(errorMessage(error?.response?.data?.responseMessage || 'Something went wrong'));
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PendingApproval':
        return { color: 'white', backgroundColor: '#FAAD14' }; // Set the color and background for "Pending" status
      case 'Active':
        return { color: 'white', backgroundColor: 'green' }; // Set the color and background for "Approved" status
      case 'Declined':
        return { color: 'white', backgroundColor: '#F5222D' }; // Set the color and background for "Rejected" status
      default:
        return { color: 'black', backgroundColor: 'transparent' }; // Set a default color and background if the status is not recognized
    }
  };

  const handleConfirm = (formData) => {
    const payload = {
      assetId: id,
      isApproved: isApprove,
      ...(isApprove === false && { comment: formData.comment }),
    };

    dispatch(toggleAssetStatus(payload))
      .then(() => {
        dispatch(successMessage('Success'));
        fetchAssetDetails();
        setConfirmModal(false);
        reset();
      })
      .catch((error) => {
        dispatch(errorMessage(error?.data?.responseMessage || 'Something went wrong'));
      });
  };

  const handleOpenModal = (isApprove) => {
    setIsApprove(isApprove);
    setConfirmModal(true);
  };

  return (
    <div>
      <Helmet>
        <title>Asset Details</title>
      </Helmet>
      <Container>
        {' '}
        <Stack direction="column" width="100%" spacing={2} alignItems="start" mb={3}>
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            paddingY={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              onClick={handleGoBack}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'start',
                textDecoration: 'none',
                color:'blue'
              }}
            >
              <Iconify icon="ep:arrow-left-bold" />
              Go Back
            </Typography>
            {data && data?.status.toLowerCase() === 'pendingapproval' && (
              <Stack spacing={2} direction="row">
                {assetDetailsLoading || assetDetailsError ? (
                  <Skeleton animation="wave" variant="rectangular" width={100} height={40} sx={{ borderRadius: 2 }} />
                ) : (
                  <Button
                    size="large"
                    variant="text"
                    style={{
                      borderRadius: 10,
                      color: 'white',
                      fontWeight: '400',
                      backgroundColor: 'red',
                    }}
                    onClick={() => handleOpenModal(false)}
                  >
                    Reject
                  </Button>
                )}
                {assetDetailsLoading || assetDetailsError ? (
                  <Skeleton animation="wave" variant="rectangular" width={100} height={40} sx={{ borderRadius: 2 }} />
                ) : (
                  <Button
                    style={{
                      borderRadius: 10,
                      color: 'white',
                      backgroundColor: 'green',
                      fontWeight: '400',
                    }}
                    size="large"
                    color="inherit"
                    variant="body1"
                    onClick={() => handleOpenModal(true)}
                  >
                    Approve
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems={'center'} spacing={2}>
            <Typography variant="h4">Asset Company Information</Typography>

            <Chip label={data?.status} sx={{ ...getStatusColor(data?.status) }} />

            {/* {assetDetailsLoading || assetDetailsError ? (
              <Skeleton animation="wave" variant="rectangular" width={100} height={40} sx={{ borderRadius: 2 }} />
            ) : (
              <Button
                disabled={data?.availableUnits > 0}
                style={{
                  borderRadius: 10,
                  color: 'black',
                  backgroundColor: '#E8E8E8',
                  opacity: data?.availableUnits > 0 ? 0.5 : 1,
                  fontWeight: '400',
                }}
                onClick={() => handleOpenModal(data.id)}
                size="large"
                color="inherit"
                variant="body1"
              >
                Initiate payout
              </Button>
            )} */}
          </Stack>
        </Stack>
        <Scrollbar>
          {id || assetDetailsError ? (
            assetDetailsLoading ? (
              <Typography textAlign={'center'} variant="h6" paragraph padding={5}>
                <CircularProgress color="inherit" />
              </Typography>
            ) : (
              data && (
                <Stack sx={{ textAlign: 'start' }}>
                  <CustomImageList images={images} />
                  <CompanyInformation asset={data} />
                  <AssetInformation asset={data} fetchAssetDetails={fetchAssetDetails} />
                  <Stack direction="column" justifyContent="start" alignItems={'start'} spacing={1} my={3}>
                    <Typography variant="h4" gutterBottom>
                      Asset Documents
                    </Typography>
                    <Stack direction="row" width="100%" spacing={2}>
                      {docs?.map((item, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems={'center'}
                          sx={{ padding: 2, width: 360, border: '1px solid #EAF0F4', borderRadius: 2 }}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <FolderIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <Typography>Document {index + 1}</Typography>
                          <a
                            href={item?.documentLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            View
                          </a>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                  <ReusableTabComponent tabs={tabs} />
                </Stack>
              )
            )
          ) : (
            <Stack sx={{ textAlign: 'center', padding: 5 }}>No ID provided</Stack>
          )}

          {assetDetailsError && (
            <Stack sx={{ textAlign: 'center', padding: 5, bgcolor: 'white', borderRadius: 1 }}>
              {'Error loading asset details.'}
            </Stack>
          )}
        </Scrollbar>
      </Container>
      <CustomModal
        isOpen={isConfirmModal}
        title={isApprove ? 'Approve Asset' : 'Reject Asset'}
        content={
          <>
            <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
              Are you sure you want to {isApprove ? 'approve' : 'reject'} this asset?
            </Typography>

            <form onSubmit={handleSubmit(handleConfirm)}>
              {!isApprove && (
                <Controller
                  name="comment"
                  rules={{ required: !isApprove }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Comment"
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.comment}
                      helperText={errors.comment ? errors.comment.message : ''}
                    />
                  )}
                />
              )}
              <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                <Button
                  onClick={() => {
                    setConfirmModal(false);
                    reset();
                  }}
                  size="large"
                  fullWidth
                  disabled={assetStatusLoading}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'black',
                    fontWeight: '400',
                    padding: 2,
                    border: '1px solid #EAF0F4',
                    borderRadius: 2,
                  }}
                >
                  Close
                </Button>
                <LoadingButton
                  loading={assetStatusLoading}
                  variant="contained"
                  size="large"
                  type="submit"
                  color={!isApprove ? 'error' : 'primary'}
                  fullWidth
                  disabled={assetStatusLoading}
                  sx={{ textTransform: 'capitalize', fontWeight: '400', padding: 2, borderRadius: 2 }}
                >
                  {isApprove ? 'Approve' : 'Reject'}
                </LoadingButton>
              </Stack>
            </form>
          </>
        }
        onClose={() => {
          setConfirmModal(false);
          reset();
        }}
        creating={false}
        onCreate={() => {}}
        disableCreate={false}
        hideCloseBtn
        hideCreateBtn
        closeText={'Cancel'}
        createText={''}
        onCreate={() => {}}
        createBtnColor="error"
      />

      <CustomModal
        title="Approve PayOut"
        content={
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to Initiate Payout
          </Typography>
        }
        isOpen={isOpen}
        contentText={''}
        onClose={() => {
          setOpen(false);
        }}
        creating={initiatePayoutLoading}
        disableCreate={initiatePayoutLoading}
        hideCloseBtn={false}
        hideCreateBtn={false}
        closeText={'Close'}
        createText={'Approve'}
        onCreate={() => {
          handleInitiateSubmit();
        }}
      />
    </div>
  );
};

export default AssetDetails;
