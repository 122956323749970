import actionTypes from '../actions/types';
import { REQUEST, FAILURE, SUCCESS } from './action-type.util';

const INTIAL_STATE = {};

const adminReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_ADMIN_ROLES):
      return {
        ...state,
        getAdminRolesError: null,
        getAdminRolesLoading: true,
        getAdminRolesPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_ROLES):
      return {
        ...state,
        getAdminRolesError: null,
        getAdminRolesLoading: false,
        getAdminRolesPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_ROLES):
      return {
        ...state,
        getAdminRolesError: actions.payload,
        getAdminRolesLoading: false,
        getAdminRolesPayload: null,
      };
    case REQUEST(actionTypes.ADD_NEW_ROLE):
      return {
        ...state,
        addNewRoleError: null,
        addNewRoleLoading: true,
        addNewRolePayload: null,
      };
    case SUCCESS(actionTypes.ADD_NEW_ROLE):
      return {
        ...state,

        addNewRoleError: null,
        addNewRoleLoading: false,
        addNewRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_NEW_ROLE):
      return {
        ...state,
        addNewRoleError: actions.payload,
        addNewRoleLoading: false,
        addNewRolePayload: null,
      };
    case REQUEST(actionTypes.DELETE_ROLE):
      return {
        ...state,
        deleteRoleError: null,
        deleteRoleLoading: true,
        deleteRolePayload: null,
      };
    case SUCCESS(actionTypes.DELETE_ROLE):
      return {
        ...state,
        deleteRoleError: null,
        deleteRoleLoading: false,
        deleteRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.DELETE_ROLE):
      return {
        ...state,
        deleteRoleError: actions.payload,
        deleteRoleLoading: false,
        deleteRolePayload: null,
      };
    case REQUEST(actionTypes.GET_ADMIN_PERMISSIONS):
      return {
        ...state,
        getAdminPermissionsError: null,
        getAdminPermissionsLoading: true,
        getAdminPermissionsPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_PERMISSIONS):
      return {
        ...state,
        getAdminPermissionsError: null,
        getAdminPermissionsLoading: false,
        getAdminPermissionsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_PERMISSIONS):
      return {
        ...state,
        getAdminPermissionsError: actions.payload,
        getAdminPermissionsLoading: false,
        getAdminPermissionsPayload: null,
      };
    case REQUEST(actionTypes.ADD_PERMISSION_TO_ROLE):
      return {
        ...state,
        addPermissionToRoleError: null,
        addPermissionToRoleLoading: true,
        addPermissionToRolePayload: null,
      };
    case SUCCESS(actionTypes.ADD_PERMISSION_TO_ROLE):
      return {
        ...state,
        addPermissionToRoleError: null,
        addPermissionToRoleLoading: false,
        addPermissionToRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_PERMISSION_TO_ROLE):
      return {
        ...state,
        addPermissionToRoleError: actions.payload,
        addPermissionToRoleLoading: false,
        addPermissionToRolePayload: null,
      };
    case REQUEST(actionTypes.REMOVE_PERMISSION_FROM_ROLE):
      return {
        ...state,
        removePermissionFromRoleError: null,
        removePermissionFromRoleLoading: true,
        removePermissionFromRolePayload: null,
      };
    case SUCCESS(actionTypes.REMOVE_PERMISSION_FROM_ROLE):
      return {
        ...state,
        removePermissionFromRoleError: null,
        removePermissionFromRoleLoading: false,
        removePermissionFromRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.REMOVE_PERMISSION_FROM_ROLE):
      return {
        ...state,
        removePermissionFromRoleError: actions.payload,
        removePermissionFromRoleLoading: false,
        removePermissionFromRolePayload: null,
      };
    case REQUEST(actionTypes.GET_ROLE_PERMISSIONS):
      return {
        ...state,
        getRolePermissionsError: null,
        getRolePermissionsLoading: true,
        getRolePermissionsPayload: null,
      };
    case SUCCESS(actionTypes.GET_ROLE_PERMISSIONS):
      return {
        ...state,
        getRolePermissionsError: null,
        getRolePermissionsLoading: false,
        getRolePermissionsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ROLE_PERMISSIONS):
      return {
        ...state,
        getRolePermissionsError: actions.payload,
        getRolePermissionsLoading: false,
        getRolePermissionsPayload: null,
      };
    case REQUEST(actionTypes.GET_ADMIN_USERS):
      return {
        ...state,
        getAdminUsersError: null,
        getAdminUsersLoading: true,
        getAdminUsersPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_USERS):
      return {
        ...state,
        getAdminUsersError: null,
        getAdminUsersLoading: false,
        getAdminUsersPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_USERS):
      return {
        ...state,
        getAdminUsersError: actions.payload,
        getAdminUsersLoading: false,
        getAdminUsersPayload: null,
      };
    case REQUEST(actionTypes.GET_USER_ROLES):
      return {
        ...state,
        getUserRolesError: null,
        getUserRolesLoading: true,
        getUserRolesPayload: null,
      };
    case SUCCESS(actionTypes.GET_USER_ROLES):
      return {
        ...state,
        getUserRolesError: null,
        getUserRolesLoading: false,
        getUserRolesPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USER_ROLES):
      return {
        ...state,
        getUserRolesError: actions.payload,
        getUserRolesLoading: false,
        getUserRolesPayload: null,
      };
    case REQUEST(actionTypes.GET_ROLE_PERMISSIONS_BY_ID):
      return {
        ...state,
        getRolePermissionsByIdError: null,
        getRolePermissionsByIdLoading: true,
        getRolePermissionsByIdPayload: null,
      };
    case SUCCESS(actionTypes.GET_ROLE_PERMISSIONS_BY_ID):
      return {
        ...state,
        getRolePermissionsByIdError: null,
        getRolePermissionsByIdLoading: false,
        getRolePermissionsByIdPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ROLE_PERMISSIONS_BY_ID):
      return {
        ...state,
        getRolePermissionsByIdError: actions.payload,
        getRolePermissionsByIdLoading: false,
        getRolePermissionsByIdPayload: null,
      };
    case actionTypes.GET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: actions.payload,
      };
    case actionTypes.GET_USER_ID:
      return {
        ...state,
        userIdPayload: actions.payload,
      };
    case actionTypes.SET_VALUE:
      return {
        ...state,
        value: actions.payload,
      };
    case REQUEST(actionTypes.ADD_USER_TO_ROLE):
      return {
        ...state,
        addUserToRoleError: null,
        addUserToRoleLoading: true,
        addUserToRolePayload: null,
      };
    case SUCCESS(actionTypes.ADD_USER_TO_ROLE):
      return {
        ...state,
        addUserToRoleError: null,
        addUserToRoleLoading: false,
        addUserToRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_USER_TO_ROLE):
      return {
        ...state,
        addUserToRoleError: actions.payload,
        addUserToRoleLoading: false,
        addUserToRolePayload: null,
      };
    case REQUEST(actionTypes.REMOVE_USER_FROM_ROLE):
      return {
        ...state,
        removeUserFromRoleError: null,
        removeUserFromRoleLoading: true,
        removeUserFromRolePayload: null,
      };
    case SUCCESS(actionTypes.REMOVE_USER_FROM_ROLE):
      return {
        ...state,
        removeUserFromRoleError: null,
        removeUserFromRoleLoading: false,
        removeUserFromRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.REMOVE_USER_FROM_ROLE):
      return {
        ...state,
        removeUserFromRoleError: actions.payload,
        removeUserFromRoleLoading: false,
        removeUserFromRolePayload: null,
      };
    case REQUEST(actionTypes.GET_USERS_FROM_ROLE):
      return {
        ...state,
        getUserFromRoleError: null,
        getUserFromRoleLoading: true,
        getUserFromRolePayload: null,
      };
    case SUCCESS(actionTypes.GET_USERS_FROM_ROLE):
      return {
        ...state,
        getUserFromRoleError: null,
        getUserFromRoleLoading: false,
        getUserFromRolePayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_USERS_FROM_ROLE):
      return {
        ...state,
        getUserFromRoleError: actions.payload,
        getUserFromRoleLoading: false,
        getUserFromRolePayload: null,
      };
    case REQUEST(actionTypes.REGISTER_ADMIN):
      return {
        ...state,
        registerAdminError: null,
        registerAdminLoading: true,
        registerAdminPayload: null,
      };
    case SUCCESS(actionTypes.REGISTER_ADMIN):
      return {
        ...state,
        registerAdminError: null,
        registerAdminLoading: false,
        registerAdminPayload: actions.payload,
      };
    case FAILURE(actionTypes.REGISTER_ADMIN):
      return {
        ...state,
        registerAdminError: actions.payload,
        registerAdminLoading: false,
        registerAdminPayload: null,
      };
    case actionTypes.REGISTER_ADMIN_PAYLOAD:
      return {
        ...state,
        registerAdminFormData: actions.payload,
      };
    case REQUEST(actionTypes.GET_ADMINS):
      return {
        ...state,
        getAdminsError: null,
        getAdminsLoading: true,
        getAdminsPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMINS):
      return {
        ...state,
        getAdminsError: null,
        getAdminsLoading: false,
        getAdminsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMINS):
      return {
        ...state,
        getAdminsError: actions.payload,
        getAdminsLoading: false,
        getAdminsPayload: null,
      };
    case REQUEST(actionTypes.GET_ADMIN_CURRENCY):
      return {
        ...state,
        getAdminCurrencyError: null,
        getAdminCurrencyLoading: true,
        getAdminCurrencyPayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_CURRENCY):
      return {
        ...state,
        getAdminCurrencyError: null,
        getAdminCurrencyLoading: false,
        getAdminCurrencyPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_CURRENCY):
      return {
        ...state,
        getAdminCurrencyError: actionTypes.payload,
        getAdminCurrencyLoading: false,
        getAdminCurrencyPayload: null,
      };
    case REQUEST(actionTypes.GET_ADMIN_TIMEZONE):
      return {
        ...state,
        getAdminTimeZoneError: null,
        getAdminTimeZoneLoading: true,
        getAdminTimeZonePayload: null,
      };
    case SUCCESS(actionTypes.GET_ADMIN_TIMEZONE):
      return {
        ...state,
        getAdminTimeZoneError: null,
        getAdminTimeZoneLoading: false,
        getAdminTimeZonePayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_ADMIN_TIMEZONE):
      return {
        ...state,
        getAdminTimeZoneError: actionTypes.payload,
        getAdminTimeZoneLoading: false,
        getAdminTimeZonePayload: null,
      };
    case REQUEST(actionTypes.GET_ORDERS):
      return {
        ...state,
        getOrdersError: null,
        getOrdersPayload: null,
        getOrdersLoading: true,
      };
    case SUCCESS(actionTypes.GET_ORDERS):
      return {
        ...state,
        getOrdersError: null,
        getOrdersPayload: actions.payload,
        getOrdersLoading: false,
      };
    case FAILURE(actionTypes.GET_ORDERS):
      return {
        ...state,
        getOrdersError: actions.payload,
        getOrdersPayload: null,
        getOrdersLoading: false,
      };
    case REQUEST(actionTypes.CANCEL_ORDER_BY_ID):
      return { ...state, cancelOrderError: null, cancelOrderPayload: null, cancelOrderLoading: true };
    case SUCCESS(actionTypes.CANCEL_ORDER_BY_ID):
      return { ...state, cancelOrderError: null, cancelOrderPayload: actions.payload, cancelOrderLoading: false };
    case FAILURE(actionTypes.CANCEL_ORDER_BY_ID):
      return { ...state, cancelOrderError: actions.payload, cancelOrderPayload: null, cancelOrderLoading: false };
    case REQUEST(actionTypes.GET_EXCHANGE_RATE):
      return {
        ...state,
        getExchangeRateError: null,
        getExchangeRatePayload: null,
        getExchangeRateLoading: true,
      };
    case SUCCESS(actionTypes.GET_EXCHANGE_RATE):
      return {
        ...state,
        getExchangeRateError: null,
        getExchangeRatePayload: actions.payload,
        getExchangeRateLoading: false,
      };
    case FAILURE(actionTypes.GET_EXCHANGE_RATE):
      return {
        ...state,
        getExchangeRateError: actions.payload,
        getExchangeRatePayload: null,
        getExchangeRateLoading: false,
      };
    case REQUEST(actionTypes.CREATE_EXCHANGE_RATE):
      return {
        ...state,
        createExchangeRateError: null,
        createExchangeRatePayload: null,
        createExchangeRateLoading: true,
      };
    case SUCCESS(actionTypes.CREATE_EXCHANGE_RATE):
      return {
        ...state,
        createExchangeRateError: null,
        createExchangeRatePayload: actions.payload,
        createExchangeRateLoading: false,
      };
    case FAILURE(actionTypes.CREATE_EXCHANGE_RATE):
      return {
        ...state,
        createExchangeRateError: actions.payload,
        createExchangeRatePayload: null,
        createExchangeRateLoading: false,
      };
    case REQUEST(actionTypes.GET_FEE_CONFIG):
      return {
        ...state,
        getFeeConfigError: null,
        getFeeConfigPayload: null,
        getFeeConfigLoading: true,
      };
    case SUCCESS(actionTypes.GET_FEE_CONFIG):
      return {
        ...state,
        getFeeConfigError: null,
        getFeeConfigPayload: actions.payload,
        getFeeConfigLoading: false,
      };
    case FAILURE(actionTypes.GET_FEE_CONFIG):
      return {
        ...state,
        getFeeConfigError: actions.payload,
        getFeeConfigPayload: null,
        getFeeConfigLoading: false,
      };
    case REQUEST(actionTypes.CREATE_FEE_CONFIG):
      return {
        ...state,
        createFeeConfigError: null,
        createFeeConfigPayload: null,
        createFeeConfigLoading: true,
      };
    case SUCCESS(actionTypes.CREATE_FEE_CONFIG):
      return {
        ...state,
        createFeeConfigError: null,
        createFeeConfigPayload: actions.payload,
        createFeeConfigLoading: false,
      };
    case FAILURE(actionTypes.CREATE_FEE_CONFIG):
      return {
        ...state,
        createFeeConfigError: actions.payload,
        createFeeConfigPayload: null,
        createFeeConfigLoading: false,
      };
    case REQUEST(actionTypes.GET_PAYMENT_PROCESSOR):
      return {
        ...state,
        getPaymentProcessorError: null,
        getPaymentProcessorPayload: null,
        getPaymentProcessorLoading: true,
      };
    case SUCCESS(actionTypes.GET_PAYMENT_PROCESSOR):
      return {
        ...state,
        getPaymentProcessorError: null,
        getPaymentProcessorPayload: actions.payload,
        getPaymentProcessorLoading: false,
      };
    case FAILURE(actionTypes.GET_PAYMENT_PROCESSOR):
      return {
        ...state,
        getPaymentProcessorError: actions.payload,
        getPaymentProcessorPayload: null,
        getPaymentProcessorLoading: false,
      };
    case REQUEST(actionTypes.GET_WEBHOOKS):
      return {
        ...state,
        getWebHooksError: null,
        getWebHooksPayload: null,
        getWebHooksLoading: true,
      };
    case SUCCESS(actionTypes.GET_WEBHOOKS):
      return {
        ...state,
        getWebHooksError: null,
        getWebHooksPayload: actions.payload,
        getWebHooksLoading: false,
      };
    case FAILURE(actionTypes.GET_WEBHOOKS):
      return {
        ...state,
        getWebHooksError: actions.payload,
        getWebHooksPayload: null,
        getWebHooksLoading: false,
      };
    case REQUEST(actionTypes.RE_TRIGGER_WEBHOOK):
      return {
        ...state,
        retriggerWebHooksError: null,
        retriggerWebHooksPayload: null,
        retriggerWebHooksLoading: true,
      };
    case SUCCESS(actionTypes.RE_TRIGGER_WEBHOOK):
      return {
        ...state,
        retriggerWebHooksError: null,
        retriggerWebHooksPayload: actions.payload,
        retriggerWebHooksLoading: false,
      };
    case FAILURE(actionTypes.RE_TRIGGER_WEBHOOK):
      return {
        ...state,
        retriggerWebHooksError: actions.payload,
        retriggerWebHooksPayload: null,
        retriggerWebHooksLoading: false,
      };
    case REQUEST(actionTypes.GET_PRICE_HISTRORY):
      return {
        ...state,
        priceHistoryLoading: true,
        priceHistoryPayload: null,
        priceHistoryError: null,
      };
    case SUCCESS(actionTypes.GET_PRICE_HISTRORY):
      return {
        ...state,
        priceHistoryLoading: false,
        priceHistoryPayload: actions.payload,
        priceHistoryError: null,
      };
    case FAILURE(actionTypes.GET_PRICE_HISTRORY):
      return {
        ...state,
        priceHistoryLoading: false,
        priceHistoryPayload: null,
        priceHistoryError: actions.payload,
      };
    case REQUEST(actionTypes.MODIFY_ASSET_HISTORY):
      return {
        ...state,
        modifyPriceLoading: true,
        modifyPricePayload: null,
        modifyPriceError: null,
      };
    case SUCCESS(actionTypes.MODIFY_ASSET_HISTORY):
      return {
        ...state,
        modifyPriceLoading: false,
        modifyPricePayload: actions.payload,
        modifyPriceError: null,
      };
    case FAILURE(actionTypes.MODIFY_ASSET_HISTORY):
      return {
        ...state,
        modifyPriceLoading: false,
        modifyPricePayload: null,
        modifyPriceError: actions.payload,
      };
    case REQUEST(actionTypes.GET_ASSET_ORDER_DETAILS):
      return {
        ...state,
        stateLoading: true,
        statePayload: null,
        stateError: null,
      };
    case SUCCESS(actionTypes.GET_ASSET_ORDER_DETAILS):
      return {
        ...state,
        stateLoading: false,
        statePayload: actions.payload,
        stateError: null,
      };
    case FAILURE(actionTypes.GET_ASSET_ORDER_DETAILS):
      return {
        ...state,
        stateLoading: false,
        statePayload: null,
        stateError: actions.payload,
      };
    case REQUEST(actionTypes.GET_SETTLEMENTS):
      return {
        ...state,
        stateLoading: true,
        statePayload: null,
        stateError: null,
      };
    case SUCCESS(actionTypes.GET_SETTLEMENTS):
      return {
        ...state,
        stateLoading: false,
        statePayload: actions.payload,
        stateError: null,
      };
    case FAILURE(actionTypes.GET_SETTLEMENTS):
      return {
        ...state,
        stateLoading: false,
        statePayload: null,
        stateError: actions.payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
