import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Collapse } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify';

NavSection.propTypes = {
  data: PropTypes.array,
};

function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 2 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, icon, children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  if (children && children.length > 0) {
    return (
      <React.Fragment key={title}>
        <StyledNavItem onClick={handleClick}>
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? (
            <Iconify icon="eva:arrow-ios-downward-fill" sx={{ mr: 3 }} />
          ) : (
            <Iconify icon="eva:arrow-ios-upward-fill" sx={{ mr: 3 }} />
          )}
        </StyledNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ mt: 2 }}>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            {children.map((child) => (
              <NavItem key={child.title} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }

  const { path, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}

export default NavSection;
