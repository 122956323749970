import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ExchangeRateForm = ({ onChange, submitStatus, payload, isClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (isClose) {
      setInputValue('');
      setInput('');
    }
  }, [isClose]);
  return (
    <Box>
      <TextField
        onChange={handleInputChange}
        id="currency"
        name="currency"
        fullWidth
        label="Enter Currency"
        variant="outlined"
        value={inputValue} // Bind the input value to the state
        error={submitStatus && !payload?.currency}
        helperText={submitStatus && !payload?.currency ? 'Currency is required' : ''}
      />
      <br />
      <br />
      <TextField
        onChange={(e) => {
          setInput(e.target.value);
          onChange(e);
        }}
        id="rate"
        type="number"
        name="rate"
        fullWidth
        label="Enter Rate"
        variant="outlined"
        value={input} // Bind the input value to the state
        error={submitStatus && !payload?.rate}
        helperText={submitStatus && !payload?.rate ? 'Rate is required' : ''}
      />
    </Box>
  );
};

export default ExchangeRateForm;

ExchangeRateForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitStatus: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  isClose: PropTypes.bool.isRequired,
};
