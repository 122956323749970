import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from '../scrollbar/Scrollbar';
import { getUserAsset } from '../../logic/actions';
import { errorMessage } from '../../logic/actions/notification';
import TablePulse from '../tablePulse';
import ReusableTabComponent from '../Tabs';

const AssetInformation = () => {
  const { id } = useParams();

  const tabs = [
    { label: 'Up For Sale', value: '0', content: <AssetTableContent category="0" userId={id} /> },
    { label: 'Sold', value: '1', content: <AssetTableContent category="1" userId={id} /> },
    {
      label: 'Purchased',
      value: '2',
      content: <AssetTableContent category={'2'} userId={id} />,
    },
    1,
  ];

  return (
    <>
      <Typography variant="h4" my={2} gutterBottom>
        Assets
      </Typography>

      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box>
          <ReusableTabComponent tabs={tabs} />
        </Box>
      </Scrollbar>
    </>
  );
};

const AssetTableContent = ({ userId, category }) => {
  const dispatch = useDispatch();
  const { getUserAssetsLoading, getUserAssetsPayload } = useSelector((state) => state.dashboardReducer);

  useEffect(() => {
    dispatch(getUserAsset(userId, category))
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        dispatch(errorMessage(error.message));
      });
  }, [dispatch, userId, category]);

  if (getUserAssetsLoading) {
    return (
      <Table sx={{ width: '100%', whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell>Asset ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Units</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Payment Channel</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TablePulse arrayLength={5} rows={7} />
        </TableBody>
      </Table>
    );
  }

  if (getUserAssetsPayload?.data && getUserAssetsPayload?.data.length > 0) {
    return (
      <Table sx={{ width: '100%', whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell>Asset ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Units</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Payment Channel</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {getUserAssetsPayload?.data.map((asset) => (
            <TableRow hover key={asset.id}>
              <TableCell>{asset?.assetId || 'N/A'}</TableCell>
              <TableCell>{asset?.title || 'N/A'}</TableCell>
              <TableCell>{asset?.unit || 'N/A'}</TableCell>
              <TableCell>{asset?.assetValue || 'N/A'}</TableCell>
              <TableCell>{asset?.dateBought || 'N/A'}</TableCell>
              <TableCell>{asset?.action}</TableCell>
              <TableCell>view</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table sx={{ width: '100%', whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell>Asset ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Payment Channel</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>No Asset Yet</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableBody>
    </Table>
  );
};

AssetTableContent.propTypes = {
  userId: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default AssetInformation;
